"use client"

import { MdSend } from "@react-icons/all-files/md/MdSend";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import { IoMdCloseCircle } from "@react-icons/all-files/io/IoMdCloseCircle"
import { Fragment, useState } from "react";
import { FaMagic } from '@react-icons/all-files/fa/FaMagic';
import { motion, AnimatePresence } from "framer-motion"
import { useTranslate } from "@/client/hooks"
import { devConsoleLog } from "@/client/utils"
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline"


const EnhanceButton = ({ handleEnhance, show }) => {
    const translate = useTranslate()
    
    const [showPromptInput, setShowPromptInput] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [userPrompt, setUserPrompt] = useState("")

    const handleToggle = () => {
        setShowPromptInput(!showPromptInput)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!userPrompt) return setError("You have to fill prompt first!")

        setLoading(true)
        await handleEnhance(userPrompt).then(() => {
            handleToggle()
        }).catch(err => {
            devConsoleLog(`Failed to enhance: ${err.message}`)
        })
        setLoading(false)
    }

    return (
        <Fragment>
            <AnimatePresence>
                {
                    show &&
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="blue-icon-btn p-2"
                        onClick={handleToggle}
                        type="button"
                    >
                        <FaMagic size={18} />
                    </motion.button>
                }
            </AnimatePresence>

            <AnimatePresence>
                {
                    showPromptInput &&
                    <motion.div
                        className={`${error ? '-bottom-[120px]' : '-bottom-[100px]'} absolute bg-gray-50 shadow-lg rounded-lg border border-gray-300 z-50 left-0 w-full`}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="p-4 flex flex-col relative">
                            <label htmlFor="userPrompt">{translate("Enter your prompt here")} <span className="text-red-500 text-sm">*</span></label>

                            <button onClick={handleToggle} type="button">
                                <IoMdCloseCircle className="text-red-500 text-[18px] absolute top-2 right-2" />
                            </button>

                            <div className="flex items-center">
                                <input
                                    value={userPrompt} id="userPrompt"
                                    type="text" placeholder={translate("How you like enhance this content?")}
                                    className="input-bar"
                                    onChange={e => {
                                        const value = e.target.value

                                        if (Boolean(value)) setError("");

                                        setUserPrompt(value)
                                    }}
                                />

                                <button className="blue-icon-btn p-2 text-[20px]" disabled={loading} onClick={handleSubmit}>
                                    {loading ? <AiOutlineLoading3Quarters className="animate-spin" /> : <MdSend />}
                                </button>
                            </div>
                            {
                                error &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(error)}
                                </small>
                            }
                        </div>
                    </motion.div>

                }
            </AnimatePresence>
        </Fragment>
    )
}

export default EnhanceButton