"use client"
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { useSelector } from 'react-redux';
import CustomBootstrap from '@/styles/CustomBootstrap.module.css';
import CustomModal from './CustomModal';
import { useTranslate } from '@/client/hooks';
import Swal from 'sweetalert2';
import { getUser } from '@/client/store/reducers/userReducer';
import { BACKEND_ROOT } from '@/constants';
import axios from 'axios';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { useParams } from 'next/navigation';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import CalenderSingleDayComponent from './CalenderSingleDayComponent';
import CalenderSingleWeekComponent from './CalenderSingleWeekComponent';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { devConsoleLog } from "@/client/utils";
import { withStore } from "@/client/store/components"



// use constants as much as possible, reduces typos

const modalUsage_add_event = 'modalUsage_add_event';
const modalUsage_edit_event = 'modalUsage_edit_event';

// // to draw events
// const eventWidth = 300;
// const hour_row_height = 100;

// for endTime
const endTime_default = "00:00";
// function getHourFromTimeString(timeString) {
//     const [hours, minutes] = timeString.split(':');
//     return parseInt(hours, 10);
// }


// for start date
const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
};
const formatDateTimeLocal = (date) => {
    const now = new Date(date);
    // now.setHours(now.getHours() + 3);
    return now.toISOString().slice(0, 16);
};

const formatDate = (date) => {
    return date.toISOString().slice(0, 10);
};

// this style is used multiple times
const end_repeat_radio_style = {
    gap: "15px",
};

// End_Repeat
const end_repeat_radio_name = "end_repeat_radio_name";
const End_Repeat_Never = "End_Repeat_Never";
const End_Repeat_COUNT = "End_Repeat_COUNT";
const End_Repeat_Until = "End_Repeat_Until";
const endRepeatRadio_default = End_Repeat_Never;

// Monthly_Repeat
const monthly_repeat_radio_name = "monthly_repeat_radio_name";
const monthly_Repeat_on = "monthly_Repeat_on";
const monthly_Repeat_byday = "monthly_Repeat_byday";
const monthlyRepeatRadio_default = monthly_Repeat_on;

// yearly_Repeat
const yearly_repeat_radio_name = "yearly_repeat_radio_name";
const yearly_Repeat_every = "yearly_Repeat_every";
const yearly_Repeat_byday = "yearly_Repeat_byday";
const yearlyRepeatRadio_default = yearly_Repeat_every;

// BYDAY

// BYDAY_order
const BYDAY_order_default = "+1";
const BYDAY_orderOptions = [
    { value: "+1", label: "First" },
    { value: "+2", label: "Second" },
    { value: "+3", label: "Third" },
    { value: "+4", label: "Four" },
    { value: "-1", label: "Last" },
];

// BYDAY_name
const BYDAY_name_default = "SA";
const BYDAY_nameOptions = [
    { value: "SA", label: "Saturday" },
    { value: "SU", label: "Sunday" },
    { value: "MO", label: "Monday" },
    { value: "TU", label: "Tuesday" },
    { value: "WE", label: "Wednesday" },
    { value: "TH", label: "Thursday" },
    { value: "FR", label: "Friday" },
];

// // not used in lwe --------------------------------------------
// const eventType_default = 4;
// const eventTypeOptions = [
//     { value: 1, label: "Test" },
//     { value: 2, label: "Session" },
//     { value: 3, label: "Meeting" },
//     { value: 4, label: "Default" },
// ];


// FREQ -> repetition
const DAILY = "DAILY";
const WEEKLY = "WEEKLY";
const MONTHLY = "MONTHLY";
const YEARLY = "YEARLY";
const FREQ_options = [
    DAILY,
    WEEKLY,
    MONTHLY,
    YEARLY,
];
const FREQ_default = DAILY;

// responsive
const wideTableMinWidth = 1600;

// for style
export const border_color = "#6c757d";
const colored_dot_radius = 7;

const monthNamesLong = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const day_names = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri",];


export const delta_day_names = (x, y) => {
    const indexX = day_names.indexOf(x);
    const indexY = day_names.indexOf(y);
    if ((indexX === -1) || (indexY === -1)) {
        return -1; //  not in the array
    }
    return indexY - indexX;
}


// what are we viewing right now ?
const currentlyViewingType_Day = 'Day';
const currentlyViewingType_Week = 'Week';
const currentlyViewingType_Month = 'Month';
const currentlyViewingType_Year = 'Year';
const currentlyViewingTypeEnums = [currentlyViewingType_Day, currentlyViewingType_Week, currentlyViewingType_Month, currentlyViewingType_Year];
const currentlyViewingType_Default = currentlyViewingType_Day;


// to use these filter funtions in a loop
const month_header_compare_1 = (i) => (3 > i);
const month_header_compare_2 = (i) => (3 == i);
const month_header_compare_3 = (i) => (3 < i);

const month_header_arr = [
    {
        fn: month_header_compare_1,
        col_main: 'col-5',
        col_nested: 'col-4',
    },
    {
        fn: month_header_compare_2,
        col_main: 'col-2',
        col_nested: 'col-12',
    },
    {
        fn: month_header_compare_3,
        col_main: 'col-5',
        col_nested: 'col-4',
    },
];

const week_header_compare_2 = (i) => (3 <= i);
const week_header_arr = [
    {
        hour_col: true,
        fn: month_header_compare_1,
        col_main: 'col-6',
        col_nested: 'col-3',
    },
    {
        hour_col: false,
        fn: week_header_compare_2,
        col_main: 'col-6',
        col_nested: 'col-3',
    },
];

// month -> header + 6 weeks
const weeks_arr = Array.from({ length: 7 }, (_, index) => index - 1);

// // day ->  24h
// const hours_arr = Array.from({ length: 24 }, (_, index) => index);

// week -> header + 24h
const single_week_arr = Array.from({ length: 25 }, (_, index) => index - 1);

// comparisons
function isSameYear(x, y) {
    return x.getFullYear() === y.getFullYear();
}

// // Function to check if the given date is in the current year
// function isCurrentYear(x) {
//     let y = new Date();
//     return isSameYear(x, y);
// }


function isSameMonth(x, y, same_date_flag = false) {
    if (same_date_flag && (!isSameYear(x, y))) {
        return false;
    }
    return x.getMonth() === y.getMonth();
}

// Function to check if the given date is in the current month
function isCurrentMonth(date) {
    let y = new Date();
    return isSameMonth(date, y, true);
    // return (isCurrentYear(date) && (isSameMonth(date, y)))
}

function isSameDay(x, y, same_date_flag = false) {
    if (same_date_flag && (!isSameMonth(x, y, same_date_flag))) {
        return false;
    }
    return x.getDate() === y.getDate();
}

// Function to check if the given date is today
function isCurrentDay(date) {
    let y = new Date();
    return isSameDay(date, y, true);
    // return (isCurrentMonth(date) && (isSameDay(date, y)))
}


// function isSameHours(x, y) {
//     return x.getHours() === y.getHours();
// }

// // Function to check if the given date is in the current hour
// function isCurrentHour(date) {
//     let y = new Date();
//     return (isCurrentDay(date) && (isSameHours(date, y)))
// }

function isCurrentHour_2(hour) {
    let now = new Date();
    const now_hour = now.getHours();
    return `${hour}` === `${now_hour}`;
}


// function isCurrentHour_3(date, hour) {
//     return (isCurrentDay(date) && isCurrentHour_2(hour))
// }


// // Function to check if the given date is in the current minute
// function isCurrentMinute(date) {
//     if (!isCurrentHour(date)) return false;
//     let now = new Date();
//     return date.getMinutes() === now.getMinutes();
// }


const changeMonth = (x, old) => {
    const z = x - 0;
    // no reason to check range , the date obj does it for us
    // if out of range, it changes the year too
    // check if number
    if ((!isNaN((z)))) {
        const y = new Date(old);
        let day = y.getDate();
        y.setMonth(z);
        // Check if the day has changed due to overflow
        if (y.getDate() != day) {
            // Set the date to the last day of the previous month
            y.setDate(0);
        }
        return y;
    }
    return null;
}


const changeDay = (x, old) => {
    const z = x - 0;
    // no reason to check range , the date obj does it for us
    // if out of range, it changes month , year too
    // check if number
    if ((!isNaN((z)))) {
        const y = new Date(old);
        y.setDate(z);
        return y;
    }
    return null;
}



const get_ui_clock_2_digits = (y) => {
    const x = (y - 0) ?? 0;
    if (x < 10) {
        return `0${x}`;
    }
    return `${x}`;
}


export const get_ui_Hour = (hour, isShowing24hoursPerDay, minute = null) => {
    let am_pm = '';
    if (!isShowing24hoursPerDay) {
        // 12 here is noon
        if (12 > hour) {
            am_pm = "AM";
        } else {
            hour %= 12;
            am_pm = "PM";
        }
        // to see 12 than 00
        if (0 === (hour - 0)) {
            hour = 12;
        }
    }
    return `${get_ui_clock_2_digits(hour)}${(null != minute) ? (" : " + get_ui_clock_2_digits(minute)) : ""}${isShowing24hoursPerDay ? "" : (" " + am_pm)}`;
}



export const day_filter = (calenderEvent, this_day, courseId) => {

    // devConsoleLog("started a filtering itr");
    // devConsoleLog("calenderEvent");
    // devConsoleLog(calenderEvent);

    if (courseId) {
        if (courseId != calenderEvent?.courseId) {
            return false;
        }
    }
    // devConsoleLog("after courseId");
    // get startDate to check if it is in range
    const isoString = calenderEvent?.startDate ?? false;
    if (!isoString) {
        return false;
    }
    // devConsoleLog("isoString");
    // devConsoleLog(isoString);
    this_day.setHours(23, 59, 59, 999);
    const startDate_dateObject = new Date(isoString);
    // devConsoleLog("startDate_dateObject");
    // devConsoleLog(startDate_dateObject);
    if (startDate_dateObject > this_day) {
        return false;
    }
    // devConsoleLog("after startDate_dateObject");
    // now check if it happens this day
    const rRule_encoded = calenderEvent?.rRule ?? false;
    // devConsoleLog("rRule_encoded");
    // devConsoleLog(rRule_encoded);
    this_day.setHours(0, 0, 0, 0);
    // devConsoleLog("this_day");
    // devConsoleLog(this_day);
    if (rRule_encoded) {
        // max 9 months from startdate of the event ( endDate )
        const endDate = changeMonth(9, startDate_dateObject);
        // devConsoleLog("endDate");
        // devConsoleLog(endDate);
        if (endDate < this_day) {
            return false;
        }
        // devConsoleLog("after endDate");
        const rule = rrulestr(rRule_encoded, { dtstart: new Date(startDate_dateObject) });

        // Get all occurrences of the event up to this day
        this_day.setHours(23, 59, 59, 999);
        const occurrences = rule.between(new Date(startDate_dateObject), this_day, true);
        // devConsoleLog("occurrences");
        // devConsoleLog(occurrences);

        // Check if this_day is one of the occurrences
        this_day.setHours(0, 0, 0, 0);
        const occurrences_flag = occurrences.some(date => {
            // // Set the time to 23:59:59.999 to represent the end of this day
            // date.setHours(23, 59, 59, 999);
            return isSameDay(date, this_day, true);
            // return date.getTime() === this_day.getTime();
        });
        // devConsoleLog("occurrences_flag");
        // devConsoleLog(occurrences_flag);
        if (occurrences_flag) {
            return true;
        }
    } else {
        return isSameDay(startDate_dateObject, this_day, true);
    }
    return false;
    // // Set the time to 23:59:59.999 to represent the end of this day
    // startDate_dateObject.setHours(23, 59, 59, 999);
    // return startDate_dateObject.getTime() === this_day.getTime();

}

export const month_filter = (calenderEvent, this_day, courseId) => {

    // devConsoleLog("started a filtering itr");
    // devConsoleLog("calenderEvent");
    // devConsoleLog(calenderEvent);

    if (courseId) {
        if (courseId != calenderEvent?.courseId) {
            return false;
        }
    }
    // devConsoleLog("after courseId");
    // get startDate to check if it is in range
    const isoString = calenderEvent?.startDate ?? false;
    if (!isoString) {
        return false;
    }
    // devConsoleLog("isoString");
    // devConsoleLog(isoString);
    const lastDay = new Date(this_day.getFullYear(), this_day.getMonth() + 1, 0);

    lastDay.setHours(23, 59, 59, 999);
    const startDate_dateObject = new Date(isoString);
    // devConsoleLog("startDate_dateObject");
    // devConsoleLog(startDate_dateObject);
    if (startDate_dateObject > lastDay) {
        return false;
    }
    // devConsoleLog("after startDate_dateObject");
    // now check if it happens this day
    const rRule_encoded = calenderEvent?.rRule ?? false;
    // devConsoleLog("rRule_encoded");
    // devConsoleLog(rRule_encoded);
    const firstDay = new Date(this_day.getFullYear(), this_day.getMonth(), 1);
    firstDay.setHours(0, 0, 0, 0);
    // devConsoleLog("this_day");
    // devConsoleLog(this_day);
    if (rRule_encoded) {
        // max 9 months from startdate of the event ( endDate )
        const endDate = changeMonth(9, startDate_dateObject);
        // devConsoleLog("endDate");
        // devConsoleLog(endDate);
        if (endDate < firstDay) {
            return false;
        }
        // devConsoleLog("after endDate");
        const rule = rrulestr(rRule_encoded, { dtstart: new Date(startDate_dateObject) });

        // Get all occurrences of the event up to this day
        // this_day.setHours(23, 59, 59, 999);
        const occurrences = rule.between(new Date(startDate_dateObject), lastDay, true);
        // devConsoleLog("occurrences");
        // devConsoleLog(occurrences);

        // Check if this_day is one of the occurrences
        this_day.setHours(0, 0, 0, 0);
        const occurrences_flag = occurrences.some(date => {
            // // Set the time to 23:59:59.999 to represent the end of this day
            // date.setHours(23, 59, 59, 999);
            return isSameMonth(date, this_day, true);
            // return date.getTime() === this_day.getTime();
        });
        // devConsoleLog("occurrences_flag");
        // devConsoleLog(occurrences_flag);
        if (occurrences_flag) {
            return true;
        }
    } else {
        return isSameMonth(startDate_dateObject, this_day, true);
    }
    return false;
    // // Set the time to 23:59:59.999 to represent the end of this day
    // startDate_dateObject.setHours(23, 59, 59, 999);
    // return startDate_dateObject.getTime() === this_day.getTime();

}
// ==================================================================================== main component ========================================================================================


const CalenderComponent = (props) => {
    const { lang } = useParams()
    const user = useSelector(getUser);
    // for translation
    const translate = useTranslate();
    const capitalizeText = (str) => {
        if (!(str?.trim()?.length ?? 0)) {
            return "";
        }
        const text = translate(str);
        if (!(text?.trim()?.length ?? 0)) {
            return "";
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
    const swal_loading = () => {
        Swal.fire({
            // html: '<div style="color:blue;"> Loading....... </div>',
            html: `<div class="${CustomBootstrap["overflow-hidden"] ?? ""} ${CustomBootstrap["justify-content-center"] ?? ""} ${CustomBootstrap["flex-column"] ?? ""} ${CustomBootstrap["align-items-center"] ?? ""} ${CustomBootstrap["d-flex"] ?? ""}"
            
        >
            <strong>${capitalizeText("Loading")}...</strong>
            <div class="${CustomBootstrap["spinner-border"] ?? ""} " role="status" aria-hidden="true"></div>
        </div>`,
            showConfirmButton: false,
        });
    }

    // states :-

    // isShowing24hoursPerDay -> [boolean] -> [1 -> 24 , 0-> in ui hours mod 12 , <12 am,else pm ]      in ( currentlyViewingType=days&less )
    const [isShowing24hoursPerDay, setIsShowing24hoursPerDay] = useState(false);
    const change_isShowing24hoursPerDay = (x) => {
        setIsShowing24hoursPerDay(!!((x) ?? false));
    }

    // currentlyViewingType -> from currentlyViewingTypeEnums

    const [currentlyViewingType, setCurrentlyViewingType] = useState(currentlyViewingType_Default);
    const change_currentlyViewingType = (x) => {
        if (((x) ?? false) && (-1 != currentlyViewingTypeEnums?.findIndex((t) => (t === (x))))) {
            setCurrentlyViewingType(x);
        }
    }

    const is_ok_2_View_this_Type = (x) => {
        // if in month -> ok to view week
        if ((currentlyViewingType === currentlyViewingType_Month) && (currentlyViewingType_Week === x)) {
            return true;
        }
        const indexX = currentlyViewingTypeEnums.indexOf(x);
        if (indexX === -1) {
            return false; //  not in the array
        }

        const indexY = currentlyViewingTypeEnums.indexOf(currentlyViewingType);

        return indexX >= indexY;
    }

    // This will get the current date , the initial states
    const currentlyViewingDate_default = new Date();

    // everything is here
    // Minute -> number [0->59] -> handle ui under 10 `0${x}`
    // Hour -> number [0->23] if(hour == 0)-> 12, + handle ui under 10 `0${x}` 
    // day -> number [01->31] check on days_per_months arr and in case feb check if it has 29
    // Month -> number [ 0 -> 11]
    // Year -> number -> no constrains 
    const [currentlyViewingDate, setCurrentlyViewingDate] = useState(currentlyViewingDate_default);

    const change_currentlyViewingDate_Year = (x) => {
        const z = x - 0;
        // check if number
        if (!isNaN((z))) {
            const y = new Date(currentlyViewingDate);
            y.setFullYear(z);
            setCurrentlyViewingDate(y);
        }
    }

    const change_currentlyViewingDate_Month = (x) => {
        const w = changeMonth(x, currentlyViewingDate);
        if (w) {
            setCurrentlyViewingDate(w);
        }
    }

    const change_currentlyViewingDate_Day = (x) => {
        const w = changeDay(x, currentlyViewingDate);
        if (w) {
            setCurrentlyViewingDate(w);
        }
    }

    function isCurrentDayName(dayName) {
        if (!isCurrentMonth(currentlyViewingDate)) return false;

        // Get the current day name
        let today = new Date();
        let currentDayName = today.toLocaleDateString('en-EG', { weekday: 'short' });

        // Check if the current day name matches the given day name
        return currentDayName === dayName;
    }


    const week_days_arr = day_names.map((day_name, day_number) => {
        const is_current_day = isCurrentDayName(day_name);
        const current_day_class = (is_current_day ? "btn-primary" : `btn-secondary`);
        const val = {
            is_current_day,
            current_day_class,
            day_number,
            day_name,
        };
        return val;
    })

    const [calenderEvents, setCalenderEvents] = useState([]);

    // modal 

    const [modalUsage, setModalUsage] = useState(modalUsage_add_event);

    const [selected_id, setSelected_id] = useState(null);
    // event takes 24 hours
    const [allDay, setAllDay] = useState(false);

    // i made a different state for the modal for the sake of seperation of concerns
    // recorded in db as -> formatDateTime(xxxxxx)
    // this is date obj
    const [startDate, setStartDate] = useState(currentlyViewingDate_default);

    // HH:MM
    const [endTime, setEndTime] = useState(endTime_default);

    // // not used in lwe --------------------------------------------
    // // the value not the label
    // // eventTypeName  -> send the label not the value
    // const [eventType, setEventType] = useState(eventType_default);

    const [title, setTitle] = useState("");
    const [notes, setNotes] = useState("");

    // Radios
    const [endRepeatRadio, setEndRepeatRadio] = useState(endRepeatRadio_default);
    const [monthlyRepeatRadio, setMonthlyRepeatRadio] = useState(monthlyRepeatRadio_default);
    const [yearlyRepeatRadio, setYearlyRepeatRadio] = useState(yearlyRepeatRadio_default);

    // [1-31]
    const [BYMONTHDAY, setBYMONTHDAY] = useState(1);
    // [ 1 jan , 12 dec ] ... but its stored here 0-based
    const [BYMONTH, setBYMONTH] = useState(0);

    // store value
    const [BYDAY_order, setBYDAY_order] = useState(BYDAY_order_default);
    const [BYDAY_name, setBYDAY_name] = useState(BYDAY_name_default);

    // for frontend only
    const [isRepeatable, setIsRepeatable] = useState(false);

    // FREQ -> [ DAILY , WEEKLY , MONTHLY , YEARLY ]
    const [FREQ, setFREQ] = useState(FREQ_default);

    // INTERVAL -> Repeat every (FREQ) , not under 1, sky is the limit
    const [INTERVAL, setINTERVAL] = useState(1);

    // COUNT -> on occurrence, not under 1, sky is the limit
    // never radio doesn't have COUNT
    const [COUNT, setCOUNT] = useState(1);

    // until date
    const [UNTIL, setUNTIL] = useState(currentlyViewingDate_default);

    // week day btns
    const [SA, setSA] = useState(false);
    const [SU, setSU] = useState(false);
    const [MO, setMO] = useState(false);
    const [TU, setTU] = useState(false);
    const [WE, setWE] = useState(false);
    const [TH, setTH] = useState(false);
    const [FR, setFR] = useState(false);

    const rRuleEncode = () => {
        let isBYMONTHDAY = false;
        let is_BYDAY_order_BYDAY_name = false;
        const rRuleParts = [
            `INTERVAL=${INTERVAL}`,
            `FREQ=${FREQ}`,
        ];

        switch (FREQ) {
            case WEEKLY:

                // BYDAY=SU,MO,TU,WE,TH,FR,SA;
                const days = [];
                if (SA) {
                    days.push("SA");
                }
                if (SU) {
                    days.push("SU");
                }
                if (MO) {
                    days.push("MO");
                }
                if (TU) {
                    days.push("TU");
                }
                if (WE) {
                    days.push("WE");
                }
                if (TH) {
                    days.push("TH");
                }
                if (FR) {
                    days.push("FR");
                }
                const BYDAY = days.join(',')
                rRuleParts?.push(`BYDAY=${BYDAY}`);

                break;
            case MONTHLY:
                switch (monthlyRepeatRadio) {
                    case monthly_Repeat_on:
                        isBYMONTHDAY = true;
                        break;

                    case monthly_Repeat_byday:
                        is_BYDAY_order_BYDAY_name = true;
                        break;

                    default:
                        break;
                }

                break;

            case YEARLY:
                rRuleParts?.push(`BYMONTH=${BYMONTH + 1}`);
                switch (yearlyRepeatRadio) {
                    case yearly_Repeat_every:
                        isBYMONTHDAY = true;
                        break;

                    case yearly_Repeat_byday:
                        is_BYDAY_order_BYDAY_name = true;
                        break;

                    default:
                        break;
                }

                break;

            default:
                break;
        }
        if (isBYMONTHDAY) {
            rRuleParts?.push(`BYMONTHDAY=${BYMONTHDAY}`);
        }
        if (is_BYDAY_order_BYDAY_name) {
            rRuleParts?.push(`BYDAY=${BYDAY_order}${BYDAY_name}`);
        }
        switch (endRepeatRadio) {
            case End_Repeat_COUNT:
                rRuleParts?.push(`COUNT=${COUNT}`);
                break;

            case End_Repeat_Until:
                const tmp_UNTIL = new Date(UNTIL).toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
                rRuleParts?.push(`UNTIL=${tmp_UNTIL}`);
                break;

            default:
                break;
        }

        return `RRULE:${rRuleParts.join(';')}`;
    }

    const rRuleDecode = (rRule) => {
        const rRuleParts = rRule.replace('RRULE:', '').split(';');
        const rRuleObj = {};
        rRuleObj.EndRepeatRadio = End_Repeat_Never;
        rRuleParts.forEach(part => {
            const [key, value] = part.split('=');
            switch (key) {

                // rrule ex :

                // "RRULE:INTERVAL=1;FREQ=DAILY"
                // "RRULE:INTERVAL=1;FREQ=DAILY;COUNT=30"
                // "RRULE:INTERVAL=1;FREQ=DAILY;UNTIL=20240901T070000Z"

                // "RRULE:INTERVAL=1;FREQ=WEEKLY;BYDAY=SU,MO,TU,WE,TH,FR,SA"
                // "RRULE:INTERVAL=1;FREQ=WEEKLY;BYDAY=SU;COUNT=13"
                // "RRULE:INTERVAL=1;FREQ=WEEKLY;BYDAY=TU;UNTIL=20240901T070000Z"

                // "RRULE:INTERVAL=1;FREQ=MONTHLY;COUNT=12;BYMONTHDAY=31"
                // "RRULE:INTERVAL=1;FREQ=MONTHLY;COUNT=12;BYDAY=+1SU"
                // "RRULE:INTERVAL=1;FREQ=MONTHLY;COUNT=12;BYDAY=+2MO"
                // "RRULE:INTERVAL=1;FREQ=MONTHLY;COUNT=12;BYDAY=+3SU"
                // "RRULE:INTERVAL=1;FREQ=MONTHLY;COUNT=12;BYDAY=+4SU"
                // "RRULE:INTERVAL=1;FREQ=MONTHLY;COUNT=12;BYDAY=-1SA"

                // "RRULE:INTERVAL=1;FREQ=YEARLY;COUNT=5;BYMONTHDAY=1;BYMONTH=1"
                // "RRULE:INTERVAL=1;FREQ=YEARLY;COUNT=5;BYMONTHDAY=31;BYMONTH=12"
                // "RRULE:INTERVAL=1;FREQ=YEARLY;COUNT=5;BYMONTH=12;BYDAY=-1WE"



                case 'INTERVAL':
                    rRuleObj.INTERVAL = parseInt(value, 10);
                    break;
                case 'FREQ':
                    rRuleObj.FREQ = value;
                    break;
                case 'BYMONTH':
                    rRuleObj.BYMONTH = parseInt(value, 10) - 1;
                    break;
                case 'BYMONTHDAY':
                    rRuleObj.MonthlyRepeatRadio = monthly_Repeat_on;
                    rRuleObj.YearlyRepeatRadio = yearly_Repeat_every;
                    rRuleObj.BYMONTHDAY = parseInt(value, 10);
                    break;
                case 'COUNT':
                    rRuleObj.EndRepeatRadio = End_Repeat_COUNT;
                    rRuleObj.COUNT = parseInt(value, 10);
                    break;
                case 'UNTIL':
                    rRuleObj.EndRepeatRadio = End_Repeat_Until;
                    rRuleObj.UNTIL = new Date(value.replace(/Z$/, '').replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6Z'));
                    break;
                case 'BYDAY':
                    rRuleObj.MonthlyRepeatRadio = monthly_Repeat_byday;
                    rRuleObj.YearlyRepeatRadio = yearly_Repeat_byday;

                    const tmp_BYDAY = value.split(',');

                    if (tmp_BYDAY?.length) {
                        if (WEEKLY === rRuleObj.FREQ) {
                            tmp_BYDAY.forEach(day => {
                                switch (day) {
                                    case "SA":
                                        rRuleObj.SA = true;
                                        break;
                                    case "SU":
                                        rRuleObj.SU = true;
                                        break;
                                    case "MO":
                                        rRuleObj.MO = true;
                                        break;
                                    case "TU":
                                        rRuleObj.TU = true;
                                        break;
                                    case "WE":
                                        rRuleObj.WE = true;
                                        break;
                                    case "TH":
                                        rRuleObj.TH = true;
                                        break;
                                    case "FR":
                                        rRuleObj.FR = true;
                                        break;
                                    default:
                                        break;
                                }
                            });
                        } else {
                            const str = tmp_BYDAY[0];
                            rRuleObj.BYDAY_order = str.slice(0, 2);
                            rRuleObj.BYDAY_name = str.slice(2);
                        }

                    }

                    break;
                default:
                    break;
            }
        });
        return rRuleObj;
    };


    // modal

    const [modal, setModal] = useState({ open: false })

    const openModal = (params) => setModal({ open: true, ...params })

    const closeModal = () => {
        setModal({ open: false });
        setModalUsage(modalUsage_add_event);
        setSelected_id(null);
        // reset modal
        setAllDay(false);

        // this is date obj
        setStartDate(currentlyViewingDate_default);

        // HH:MM
        setEndTime(endTime_default);

        setTitle("");
        setNotes("");

        setEndRepeatRadio(endRepeatRadio_default);
        setMonthlyRepeatRadio(monthlyRepeatRadio_default);
        setYearlyRepeatRadio(yearlyRepeatRadio_default);

        // [1-31]
        setBYMONTHDAY(1);

        // [ 1 jan , 12 dec ] ... but its stored here 0-based
        setBYMONTH(0);

        // store value
        setBYDAY_order(BYDAY_order_default);
        setBYDAY_name(BYDAY_name_default);

        setIsRepeatable(false);

        // FREQ -> [ DAILY , WEEKLY , MONTHLY , YEARLY ]
        setFREQ(FREQ_default);

        // INTERVAL -> Repeat every (FREQ) , not under 1, sky is the limit
        setINTERVAL(1);

        // COUNT -> on occurrence, not under 1, sky is the limit
        // never radio doesn't have COUNT
        setCOUNT(1);

        // until date
        setUNTIL(currentlyViewingDate_default);

        // week days btns
        setSA(false);
        setSU(false);
        setMO(false);
        setTU(false);
        setWE(false);
        setTH(false);
        setFR(false);

        // // not used in lwe --------------------------------------------
        // setEventType(eventType_default);
    }



    // how do we need this ? so we can compare it to wideTableMinWidth
    // why ? to make it scrollable in small screen yet responsive on big screen
    const [width, setWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // // devConsoleLog("props?.is_in_course");
        // // devConsoleLog(props?.is_in_course);
        const controller = new AbortController();

        axios?.get(`${BACKEND_ROOT}/calender/events${props?.is_in_course ? ("?courseId=" + props?.courseId ?? "0") : ""}`, {
            signal: controller.signal,
        })?.then((res) => {
            // // devConsoleLog("res");
            // // devConsoleLog(res);
            const res_data = res?.data ?? false;
            // devConsoleLog("res_data");
            // devConsoleLog(res_data);
            setCalenderEvents(res_data);

            Swal.close();
        })?.catch((err) => {
            // devConsoleLog("err");
            // devConsoleLog(err.message || err.code);
            Swal.close();
        })

        return () => {
            controller.abort()
        }
    }, [props?.is_in_course])


    // it's used atleast twice
    const IsShowing24hoursPerDay_toggler_component = () => {
        if (!((currentlyViewingType == currentlyViewingType_Day) || (currentlyViewingType == currentlyViewingType_Week))) {
            return null;
        }
        return (

            <div className={`  ${CustomBootstrap["d-flex"] ?? ""} `}
                style={{
                    gap: "3px",
                }}
            >
                <div
                    style={{
                        color: isShowing24hoursPerDay ? "black" : "green",
                    }}
                >
                    {capitalizeText("12 hours format")}
                </div>
                <div className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["form-switch"] ?? ""}  ${CustomBootstrap["form-check"] ?? ""} `} >
                    <input className={`  ${CustomBootstrap["form-check-input"] ?? ""} `} type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        onChange={(e) => {
                            if (e?.target?.hasOwnProperty("checked")) {
                                change_isShowing24hoursPerDay(e?.target?.checked)
                            }
                        }}
                        checked={isShowing24hoursPerDay}
                    />
                </div>
                <div
                    style={{
                        color: isShowing24hoursPerDay ? "green" : "black",
                    }}
                >
                    {capitalizeText('24 hours format')}
                </div>
            </div>
        )
    }

    const Wrapper_IsShowing24hoursPerDay_toggler_component = () => {
        return (

            <div className={` ${CustomBootstrap["my-3"] ?? ""}   ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["flex-column"] ?? ""}   ${CustomBootstrap["text-center"] ?? ""}      ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}   `} >
                {/* note */}
                <div
                    style={{
                        color: "red",
                    }}
                >
                    {capitalizeText(`*Note: Currently, These buttons control what ${currentlyViewingType} you view`)}
                </div>

                {/* here is the big screen */}
                <div className={` `} >
                    <IsShowing24hoursPerDay_toggler_component />
                </div>

            </div>

        );
    }

    const Change_date_btn = ({ dir }) => {
        // 0-> < 
        // 1-> >
        return (
            <Tooltip
                title={`Go to ${dir ? "next" : "previous"} ${currentlyViewingType}`}
                placement="top"
                arrow
                TransitionComponent={Zoom}
                describeChild
            >
                <button
                    className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["flex-wrap"] ?? ""}   ${CustomBootstrap["btn"] ?? ""}    ${CustomBootstrap["btn-primary"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   ${CustomBootstrap["justify-content-center"] ?? ""} `}
                    style={{
                        fontSize: "1.2rem",
                        padding: "0px 0.7rem 0.2rem"
                    }}
                    onClick={() => {
                        const x = (dir) ? 1 : -1;
                        switch (currentlyViewingType) {
                            case currentlyViewingType_Year:
                                change_currentlyViewingDate_Year(currentlyViewingDate.getFullYear() + x);
                                break;

                            case currentlyViewingType_Month:
                                change_currentlyViewingDate_Month(currentlyViewingDate.getMonth() + x);
                                break;

                            case currentlyViewingType_Week:
                                change_currentlyViewingDate_Day(currentlyViewingDate.getDate() + (7 * x));
                                break;


                            case currentlyViewingType_Day:
                                change_currentlyViewingDate_Day(currentlyViewingDate.getDate() + x);
                                break;

                            default:
                                break;
                        }
                    }}
                >
                    {
                        (dir) ? ">" : "<"
                    }
                </button>
            </Tooltip>
        );
    }


    return (
        <div  >
            <div  >
                {/* every note is in a div */}
                <div
                    style={{
                        color: "red",
                    }}
                >
                    {capitalizeText("*Note: If you are viewing year, click the month to go into it, in month click on the day, etc...")}
                </div>
                <div
                    style={{
                        color: "red",
                    }}
                >
                    {capitalizeText("*Note: If you are viewing week or month on a small screen, please remember to scroll horizontally")}
                </div>
                <div
                    style={{
                        color: "red",
                    }}
                >
                    {capitalizeText("*Note: If you can't select the type you want, click it on the table, recursively if needed")}
                </div>
                {/* <div
                    style={{
                        color: "red",
                    }}
                >
                    {capitalizeText("*Note: When adding a new event, remeber that the end time is not included, which means that if you select 8pm the event lasts till last moment in 7pm")}
                </div> */}
            </div >

            {/* currentlyViewingType */}
            <div>
                <div>
                    {capitalizeText('You are viewing')}
                </div>
                <Select
                    value={{ value: currentlyViewingType, label: currentlyViewingType }}
                    options={currentlyViewingTypeEnums?.filter(x => is_ok_2_View_this_Type(x))?.map((x) => ({ value: x, label: x }))}
                    onChange={(e) => {
                        change_currentlyViewingType(e.value);
                    }}
                    fullWidth
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999999 }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition='fixed'
                />
            </div>

            {/* add event */}
            <div>
                <button
                    className={`  ${CustomBootstrap["btn-primary"] ?? ""}  ${CustomBootstrap["btn"] ?? ""}  ${CustomBootstrap["mt-4"] ?? ""}  `}
                    onClick={() => {
                        openModal({ mode: 'open' });
                        setModalUsage(modalUsage_add_event);
                        setStartDate(new Date(currentlyViewingDate));
                    }}
                >
                    {capitalizeText("add Event")}
                </button>
            </div>
            {/* date controller */}
            <div
                className={`  ${CustomBootstrap["mt-4"] ?? ""}  ${CustomBootstrap["p-4"] ?? ""}  ${CustomBootstrap["d-block"] ?? ""}     `}
                style={{
                    border: `1px solid ${border_color}`,
                    borderRadius: "10px",
                    overflow: "hidden",
                }}
            >
                {/* Wrapper_IsShowing24hoursPerDay_toggler_component -> twice */}
                {/* here is the small screen */}
                <div className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["d-md-none"] ?? ""}   ${CustomBootstrap["text-center"] ?? ""}      ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}   `} >
                    <Wrapper_IsShowing24hoursPerDay_toggler_component />
                </div>
                {/* full controller */}
                <div
                    className={`  ${CustomBootstrap["text-center"] ?? ""}     ${CustomBootstrap["flex-wrap"] ?? ""}     ${CustomBootstrap["align-items-center"] ?? ""}     ${CustomBootstrap["d-flex"] ?? ""}   `}
                    style={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}
                >
                    {/* change the date depending on the type */}
                    <Change_date_btn dir={0} />

                    {/* here is the big screen */}
                    <div className={`  ${CustomBootstrap["d-none"] ?? ""}  ${CustomBootstrap["d-md-flex"] ?? ""} `} >
                        <Wrapper_IsShowing24hoursPerDay_toggler_component />
                    </div>

                    {/* change the date depending on the type */}
                    <Change_date_btn dir={1} />
                </div>

                {/* date select */}
                <div className={`        ${CustomBootstrap["row"] ?? ""}    `}>
                    {

                        [
                            {
                                type: currentlyViewingType_Year,
                                fn: (() => {
                                    return (
                                        <input
                                            type='number'
                                            className={` ${CustomBootstrap["form-control"] ?? ""}`}
                                            onChange={e => {
                                                change_currentlyViewingDate_Year(e?.target?.value);
                                            }}
                                            value={currentlyViewingDate.getFullYear()}
                                        />
                                    )
                                })()
                            },
                            {
                                type: currentlyViewingType_Month,
                                fn: (() => {
                                    return (
                                        <Select
                                            value={{ value: currentlyViewingDate.getMonth(), label: monthNamesLong[currentlyViewingDate.getMonth()] }}
                                            options={monthNamesLong.map((x, index) => ({ value: index, label: x }))}
                                            onChange={(e) => {
                                                change_currentlyViewingDate_Month(e.value);
                                            }}
                                            fullWidth
                                            styles={{
                                                menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                            }}
                                            menuPortalTarget={document.body}
                                            menuPosition='fixed'
                                        />
                                    );
                                })()
                            },
                        ]?.filter(x => is_ok_2_View_this_Type(x.type))?.map(x => {
                            return (
                                <div
                                    key={x.type}
                                    className={`    ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["mt-3"] ?? ""}    `}
                                >
                                    {x.fn}
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            {/* date table */}

            <div
                className={`  ${CustomBootstrap["mt-4"] ?? ""} `}
            >
                {
                    (() => {
                        const courseId = (props?.is_in_course ? (props?.courseId) : (null)) ?? null;

                        switch (currentlyViewingType) {
                            case currentlyViewingType_Day:
                                return (
                                    <div
                                        style={{
                                            overflowX: "auto",
                                        }}
                                    >
                                        <CalenderSingleDayComponent
                                            currentlyViewingDate={currentlyViewingDate}
                                            calenderEvents={calenderEvents}
                                            courseId={courseId}
                                            isShowing24hoursPerDay={isShowing24hoursPerDay}
                                        />
                                    </div>
                                );

                            case currentlyViewingType_Week:
                                return (
                                    <CalenderSingleWeekComponent
                                        currentlyViewingDate={currentlyViewingDate}
                                        calenderEvents={calenderEvents}
                                        courseId={courseId}
                                        isShowing24hoursPerDay={isShowing24hoursPerDay}
                                        week_days_arr={week_days_arr}
                                        currentlyViewingType_Day={currentlyViewingType_Day}
                                        setCurrentlyViewingDate={setCurrentlyViewingDate}
                                        change_currentlyViewingType={change_currentlyViewingType}
                                    />
                                )
                                break;

                            case currentlyViewingType_Month:
                                const start_of_current_month_date = new Date(currentlyViewingDate);
                                start_of_current_month_date?.setDate(1);
                                // // // devConsoleLog("start_of_current_month_date");
                                // // // devConsoleLog(start_of_current_month_date);
                                const start_of_current_month_name = start_of_current_month_date.toLocaleDateString('en-EG', { weekday: 'short' });
                                let did_NOT_reach_start_of_current_month_flag = true;
                                const date_iterator = new Date(start_of_current_month_date);
                                // // // devConsoleLog("date_iterator");
                                // // // devConsoleLog(date_iterator);

                                return (
                                    <div
                                        style={{
                                            overflowX: (width < (wideTableMinWidth + 100)) ? "scroll" : "visible",
                                        }}
                                    >
                                        <div
                                            className={` ${CustomBootstrap["row"] ?? ""}  ${CustomBootstrap["mt-4"] ?? ""}  ${CustomBootstrap["text-center"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""} `}
                                            style={{
                                                minWidth: `${wideTableMinWidth}px`,
                                            }}
                                        >
                                            {
                                                // dry
                                                weeks_arr?.map(week_i => {
                                                    const is_header = (-1 === week_i);
                                                    // because the row is not 1 row, bootstrap grid is 12 col not 7
                                                    return month_header_arr.map((obj, obj_i) => {
                                                        return (
                                                            <div
                                                                key={`${week_i} ${obj_i}`}
                                                                className={`  ${CustomBootstrap["row"] ?? ""}  ${CustomBootstrap[obj.col_main] ?? ""} `}
                                                            >
                                                                {
                                                                    week_days_arr.filter((x, i) => obj.fn(i))?.map((day, i) => {
                                                                        let this_day_class = "btn-outline-secondary";
                                                                        // the name if this box
                                                                        let written_date = "";
                                                                        let tmp_date_iterator = null;

                                                                        if (is_header) {
                                                                            this_day_class = day?.current_day_class;
                                                                            written_date = capitalizeText(day?.day_name);
                                                                        } else {
                                                                            if ((!week_i) && did_NOT_reach_start_of_current_month_flag) {
                                                                                // week_i -> 0 -> first week
                                                                                if (start_of_current_month_name === day?.day_name) {
                                                                                    // reached it
                                                                                    did_NOT_reach_start_of_current_month_flag = false;
                                                                                    // this_day_class = "btn-outline-success";
                                                                                } else if (!(week_i || obj_i || i)) {
                                                                                    // date_iterator?.setDate(start_of_current_month_date?.getDate() + delta_day_names(start_of_current_month_name, day?.day_name) - 1);
                                                                                    date_iterator?.setDate(start_of_current_month_date?.getDate() + delta_day_names(start_of_current_month_name, day?.day_name));
                                                                                }
                                                                            }
                                                                            // // // devConsoleLog("day");
                                                                            // // // devConsoleLog(day);
                                                                            // // // devConsoleLog("date_iterator");
                                                                            // // // devConsoleLog(date_iterator);
                                                                            tmp_date_iterator = new Date(date_iterator);
                                                                            // date_iterator?.setDate(date_iterator?.getDate() + 1);
                                                                            written_date += `${date_iterator?.getDate()}`;
                                                                            if (isCurrentDay(date_iterator)) {
                                                                                this_day_class = "btn-outline-primary";
                                                                            } else {
                                                                                if ((!(week_i || obj_i || i)) || (date_iterator?.getDate() === 1)) {
                                                                                    written_date += ` / ${monthNamesLong[date_iterator?.getMonth()]}`;
                                                                                }

                                                                                // // const not_this_year_flag = !isCurrentYear(date_iterator);
                                                                                // if (did_NOT_reach_start_of_current_month_flag || (!isSameMonth(currentlyViewingDate, date_iterator))) {
                                                                                //     // if (did_NOT_reach_start_of_current_month_flag || (!isCurrentMonth(date_iterator))) {
                                                                                //     written_date += ` / ${date_iterator?.getMonth() + 1}`;
                                                                                //     // if (!not_this_year_flag) {
                                                                                //     //     this_day_class = "btn-outline-danger";
                                                                                //     // }
                                                                                // }


                                                                                // if ((!isSameYear(currentlyViewingDate, date_iterator))) {
                                                                                //     written_date += ` / ${date_iterator?.getFullYear()}`;
                                                                                //     // this_day_class = "btn-outline-warning";
                                                                                // }
                                                                            }
                                                                            date_iterator?.setDate(date_iterator?.getDate() + 1);
                                                                            // // // devConsoleLog("date_iterator");
                                                                            // // // devConsoleLog(date_iterator);

                                                                        }
                                                                        const tmp_style = {
                                                                            borderRadius: "0",
                                                                            whiteSpace: "nowrap",
                                                                        };
                                                                        if (!is_header) {
                                                                            // tmp_style.position = "relative";
                                                                            // tmp_style.height = "500px";
                                                                            tmp_style.gap = "20px";
                                                                        }
                                                                        return (
                                                                            <div
                                                                                className={` ${CustomBootstrap[obj.col_nested] ?? ""}   ${CustomBootstrap[this_day_class] ?? ""}  ${CustomBootstrap["btn"] ?? ""}  ${CustomBootstrap["flex-column"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}  ${CustomBootstrap["justify-content-around"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}  ${is_header ? "" : CustomBootstrap["py-5"] ?? ""}  `}
                                                                                key={i}
                                                                                style={tmp_style}
                                                                                onClick={() => {
                                                                                    if (!is_header) {
                                                                                        // // // devConsoleLog("date_iterator");
                                                                                        // // // devConsoleLog(date_iterator);
                                                                                        // // // devConsoleLog("tmp_date_iterator");
                                                                                        // // // devConsoleLog(tmp_date_iterator ?? null);
                                                                                        setCurrentlyViewingDate(x => new Date(tmp_date_iterator));
                                                                                        change_currentlyViewingType(currentlyViewingType_Day);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {
                                                                                    is_header ?
                                                                                        written_date
                                                                                        :
                                                                                        (
                                                                                            <>

                                                                                                {/* name */}

                                                                                                <div className={`  ${CustomBootstrap["text-center"] ?? ""}   `} >
                                                                                                    {written_date}
                                                                                                </div>

                                                                                                {/* events */}

                                                                                                <div className={` ${CustomBootstrap["align-items-center"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""} `}
                                                                                                    style={{
                                                                                                        // position: "absolute",
                                                                                                        // top: "0px",
                                                                                                        // left: "5px",
                                                                                                        gap: "8px 0",
                                                                                                        width: "100%",
                                                                                                        overflow: "hidden",
                                                                                                        flexDirection: "column",
                                                                                                        // fontSize: "0.85em",
                                                                                                    }}
                                                                                                >

                                                                                                    {
                                                                                                        (() => {
                                                                                                            const this_day = new Date(tmp_date_iterator);
                                                                                                            // Set the time to 23:59:59.999 to represent the end of this day
                                                                                                            const calenderEvents_filtered = calenderEvents?.filter((calenderEvent) => {
                                                                                                                return day_filter(calenderEvent, this_day, courseId);
                                                                                                            }) ?? [];
                                                                                                            // devConsoleLog("calenderEvents_filtered");
                                                                                                            // devConsoleLog(calenderEvents_filtered);

                                                                                                            if (!(calenderEvents_filtered?.length ?? 0)) {
                                                                                                                return null;
                                                                                                                // return (
                                                                                                                //     <div
                                                                                                                //     // className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                                                                                                //     >
                                                                                                                //         {`${capitalizeText("No events in this day")}`}
                                                                                                                //     </div>
                                                                                                                // );
                                                                                                            }

                                                                                                            // conflict ? red : green
                                                                                                            const depend_on_conflict = ((calenderEvents_filtered?.length ?? 0) - 1) ? "danger" : "success";
                                                                                                            const class_depend_on_conflict = `btn-${depend_on_conflict}`;
                                                                                                            const calenderEvents_filtered_allDay = calenderEvents_filtered?.filter((calenderEvent) => (calenderEvent?.allDay ?? false)) ?? [];
                                                                                                            const calenderEvents_filtered_timed = calenderEvents_filtered?.filter((calenderEvent) => !(calenderEvent?.allDay ?? false)) ?? [];
                                                                                                            calenderEvents_filtered_timed.sort((b, a) => new Date(b.startDate) - new Date(a.startDate));
                                                                                                            return [...calenderEvents_filtered_allDay, ...calenderEvents_filtered_timed,]?.map((calenderEvent, calenderEvent_i) => {
                                                                                                                const tmp_title = calenderEvent?.title ?? "";
                                                                                                                const tmp_notes = calenderEvent?.notes ?? "";

                                                                                                                return (
                                                                                                                    <div
                                                                                                                        key={calenderEvent_i}
                                                                                                                        className={`   ${CustomBootstrap["align-items-center"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""} `}
                                                                                                                        style={{
                                                                                                                            // position: "absolute",
                                                                                                                            // top: "0px",
                                                                                                                            // left: "5px",
                                                                                                                            width: "100%",
                                                                                                                            overflow: "hidden",
                                                                                                                            // fontSize: "0.85em",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Tooltip
                                                                                                                            title={tmp_notes}
                                                                                                                            placement="top"
                                                                                                                            arrow
                                                                                                                            TransitionComponent={Zoom}
                                                                                                                            describeChild
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className={`    ${CustomBootstrap["btn"] ?? ""}  ${CustomBootstrap[class_depend_on_conflict] ?? ""}  ${CustomBootstrap["text-center"] ?? ""}  `}
                                                                                                                                style={{
                                                                                                                                    border: "1px solid",
                                                                                                                                    wordWrap: "break-word",
                                                                                                                                    overflow: "hidden",
                                                                                                                                    textOverflow: "clip",
                                                                                                                                    whiteSpace: "normal",
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                {tmp_title}
                                                                                                                            </div>
                                                                                                                        </Tooltip>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            });
                                                                                                        })()
                                                                                                    }


                                                                                                </div>
                                                                                            </>
                                                                                        )

                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    });
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                                break;

                            case currentlyViewingType_Year:
                                return (
                                    <div
                                        className={` ${CustomBootstrap["row"] ?? ""}  ${CustomBootstrap["mt-4"] ?? ""} `}
                                        style={{
                                            border: `1px solid ${border_color}`,
                                            borderRadius: "10px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {
                                            monthNamesLong?.map((month, month_num) => {
                                                devConsoleLog("month");
                                                devConsoleLog(month);

                                                const tmp_date = new Date(`${month_num + 1}/01/${currentlyViewingDate.getFullYear()}`);
                                                // // // devConsoleLog("month");
                                                // // // devConsoleLog(month);
                                                // // // devConsoleLog("tmp_date");
                                                // // // devConsoleLog(tmp_date);

                                                // const current_month_class = isCurrentMonth(changeMonth(month_num, currentlyViewingDate)) ? "btn-primary" : "btn-outline-secondary";
                                                const current_month_class = isCurrentMonth(tmp_date) ? "btn-primary" : "btn-outline-secondary";

                                                return (

                                                    <div
                                                        className={` ${CustomBootstrap[current_month_class] ?? ""}  ${CustomBootstrap["btn"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["py-5"] ?? ""}  ${CustomBootstrap["col-lg-2"] ?? ""}  ${CustomBootstrap["col-md-3"] ?? ""}  ${CustomBootstrap["col-sm-4"] ?? ""}  ${CustomBootstrap["col-6"] ?? ""}  `}
                                                        key={month}
                                                        style={{
                                                            position: "relative",
                                                            border: `1px solid ${border_color}`,
                                                            borderRadius: "0",
                                                        }}
                                                        onClick={() => {
                                                            // change_currentlyViewingDate_Month(month_num);
                                                            setCurrentlyViewingDate(x => new Date(tmp_date));
                                                            change_currentlyViewingType(currentlyViewingType_Month);
                                                        }}
                                                    >

                                                        {/* events */}


                                                        <div className={` ${CustomBootstrap["align-items-center"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""} `}
                                                            style={{
                                                                position: "absolute",
                                                                top: "0px",
                                                                left: "5px",
                                                                gap: "5px",
                                                                fontSize: "0.85em",
                                                            }}
                                                        >
                                                            {
                                                                (() => {
                                                                    const this_day = new Date(tmp_date);
                                                                    // Set the time to 23:59:59.999 to represent the end of this day
                                                                    const calenderEvents_filtered = calenderEvents?.filter((calenderEvent) => {
                                                                        return month_filter(calenderEvent, this_day, courseId);
                                                                    }) ?? [];
                                                                    // devConsoleLog("calenderEvents_filtered");
                                                                    // devConsoleLog(calenderEvents_filtered);

                                                                    const calenderEvents_filtered_length = calenderEvents_filtered?.length ?? 0;
                                                                    if (!calenderEvents_filtered_length) {
                                                                        return (

                                                                            <div
                                                                            // className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                                                            >
                                                                                {`${capitalizeText("No Events")}`}
                                                                            </div>
                                                                        );
                                                                    }
                                                                    // conflict ? red : green
                                                                    // const depend_on_conflict = (calenderEvents_filtered_length - 1) ? "red" : "green";
                                                                    // const depend_on_conflict = "blue";
                                                                    const depend_on_conflict = isCurrentMonth(tmp_date) ? "white" : "blue";

                                                                    return (
                                                                        <div className={` ${CustomBootstrap["align-items-center"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""} `}
                                                                            style={{
                                                                                gap: "2px",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: `${colored_dot_radius}px`,
                                                                                    height: `${colored_dot_radius}px`,
                                                                                    borderRadius: `50%`,
                                                                                    background: depend_on_conflict,
                                                                                }}
                                                                            ></div>
                                                                            <div className={`  ${CustomBootstrap["text-center"] ?? ""}   `} >
                                                                                {calenderEvents_filtered_length}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })()
                                                            }
                                                        </div>
                                                        {/* name */}
                                                        <div className={`  ${CustomBootstrap["text-center"] ?? ""}   `} >
                                                            {capitalizeText(month)}
                                                        </div>
                                                    </div>
                                                );
                                            })

                                        }

                                    </div>
                                );
                                break;

                            default:
                                return null;
                                break;
                        }
                    })()
                }
            </div >

            {/* ============================================ modal ============================================ */}

            {/* conditional custom modal */}
            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-3xl`}>
                <div className="p-8 max-md:px-4"
                    style={{
                        // overflowY: "scroll",
                        // maxHeight: "80vh",
                    }}
                >


                    <div
                        className="p-8 max-md:px-4"
                        style={{
                            overflowY: isRepeatable ? "scroll" : "auto",
                            height: "70vh",
                        }}
                    >

                        {/* /////////////////////////////////////////////////////////////////////////// */}

                        {/* header */}
                        <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-5"] ?? ""}    `}
                        >
                            <div
                                className={`   `}
                                style={{
                                    textAlign: "center",
                                    fontSize: "30px",
                                }}
                            >
                                {capitalizeText((modalUsage_edit_event === modalUsage) ? "Edit Event" : "Add Event")}
                            </div>
                        </div>

                        {/* all inputs in one row , but responsive */}
                        <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >

                            {/* Title */}
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}  ${CustomBootstrap["col-sm-6"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                style={{
                                    textAlign: "center",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                }}
                            >
                                <div
                                    className={`  ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    {capitalizeText("Title")}
                                </div>
                                <div
                                    className={` ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => {
                                            const val = e?.target?.value ?? "";
                                            setTitle(val);
                                        }}
                                        className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                    />
                                </div>
                            </div>
                            {/* All Day */}
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}  ${CustomBootstrap["col-sm-6"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                style={{
                                    textAlign: "center",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                }}
                            >
                                <div
                                    className={`  ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    {capitalizeText("All Day")}
                                </div>
                                <div
                                    className={` ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    <div className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["form-switch"] ?? ""}  ${CustomBootstrap["form-check"] ?? ""} `} >
                                        <input className={`  ${CustomBootstrap["form-check-input"] ?? ""} `} type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                            onChange={(e) => {
                                                if (e?.target?.hasOwnProperty("checked")) {
                                                    setAllDay(e?.target?.checked)
                                                }
                                            }}
                                            checked={allDay}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Start Date */}
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}  ${CustomBootstrap["col-sm-6"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                style={{
                                    textAlign: "center",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                }}
                            >
                                <div
                                    className={`  ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    {capitalizeText("Start Date")}
                                </div>
                                <div
                                    className={` ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    {/* <DatePicker
                                        // onChange={value => setFormData({ ...formData, dateOfBirth: new Date(value).toDateString() })}
                                        onChange={(e) => {
                                            // const val = e?.target?.value ?? "";
                                            // // devConsoleLog(val);
                                            // const date = new Date(val);
                                            // // devConsoleLog(date);
                                            // setStartDate(date);
                                            setStartDate(e);
                                        }}
                                        value={startDate}
                                        showTimeSelect
                                        format="MMMM d, yyyy h:mm aa"
                                        // format="dd/MM/yy"
                                        // locale={"en"}
                                        locale={lang}
                                    /> */}


                                    {/* <DatePicker
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                    /> */}

                                    <DateTimePicker
                                        format="dd/MM/yy h:mm aa"
                                        // format="y-MM-dd HH:mm"
                                        // format={isShowing24hoursPerDay ? "dd/MM/yy HH:mm" : "dd/MM/yy h:mm aa"}
                                        locale={lang}
                                        // disableClock={true}
                                        onChange={(e) => {
                                            // const val = e?.target?.value ?? "";
                                            // // devConsoleLog(val);
                                            // const date = new Date(val);
                                            // // devConsoleLog(date);
                                            // setStartDate(date);
                                            setStartDate(e);
                                        }}
                                        value={startDate}
                                    />

                                    {/* <input
                                        type={allDay ? "date" : "datetime-local"}
                                        // value={allDay ? formatDate(startDate) : formatDateTimeLocal(startDate)}
                                        value={startDate}
                                        onChange={(e) => {
                                            const val = e?.target?.value ?? "";
                                            // // devConsoleLog(val);
                                            const date = new Date(val);
                                            // // devConsoleLog(date);
                                            setStartDate(date);
                                        }}
                                        className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                    /> */}
                                </div>
                            </div>
                            {/* End Time */}
                            {
                                allDay ?
                                    null
                                    :
                                    <div
                                        className={`  ${CustomBootstrap["col-12"] ?? ""}  ${CustomBootstrap["col-sm-6"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                        style={{
                                            textAlign: "center",
                                            fontSize: "17px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["mb-3"] ?? ""}   `}
                                        >
                                            {capitalizeText("End Time")}
                                        </div>
                                        <div
                                            className={` ${CustomBootstrap["mb-3"] ?? ""}   `}
                                        >
                                            <input
                                                type={"time"}
                                                value={endTime}
                                                // format={isShowing24hoursPerDay ? "HH:mm" : "h:mm aa"}
                                                onChange={(e) => {
                                                    const val = e?.target?.value ?? endTime_default;
                                                    // HH:MM
                                                    setEndTime(val);
                                                }}
                                                className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                            />
                                        </div>
                                    </div>
                            }
                            {/* Repeat */}
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}  ${CustomBootstrap["col-sm-6"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                style={{
                                    textAlign: "center",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                }}
                            >
                                <div
                                    className={`  ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    {capitalizeText("Repeat")}
                                </div>
                                <div
                                    className={` ${CustomBootstrap["mb-3"] ?? ""}   `}
                                >
                                    <div className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["form-switch"] ?? ""}  ${CustomBootstrap["form-check"] ?? ""} `} >
                                        <input className={`  ${CustomBootstrap["form-check-input"] ?? ""} `} type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                            onChange={(e) => {
                                                if (e?.target?.hasOwnProperty("checked")) {
                                                    setIsRepeatable(e?.target?.checked)
                                                }
                                            }}
                                            checked={isRepeatable}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Title */}
                        {/* <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            >
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    {capitalizeText("Title")}
                                </div>
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => {
                                            const val = e?.target?.value ?? "";
                                            setTitle(val);
                                        }}
                                        className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                    />
                                </div>
                            </div>
                        </div> */}



                        {/* Start Date */}
                        {/* <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            >
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    {capitalizeText("Start Date")}
                                </div>
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    <input
                                        type={allDay ? "date" : "datetime-local"}
                                        value={allDay ? formatDate(startDate) : formatDateTimeLocal(startDate)}
                                        onChange={(e) => {
                                            const val = e?.target?.value ?? "";
                                            // // devConsoleLog(val);
                                            const date = new Date(val);
                                            // // devConsoleLog(date);
                                            setStartDate(date);
                                        }}
                                        className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                    />
                                </div>
                            </div>
                        </div> */}



                        {/* End Time */}
                        {
                            // allDay ?
                            //     null
                            //     :
                            //     <div
                            //         className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                            //     >
                            //         <div
                            //             className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            //         >
                            //             <div
                            //                 className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                            //             >
                            //                 {capitalizeText("End Time")}
                            //             </div>
                            //             <div
                            //                 className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                            //             >
                            //                 <input
                            //                     type={"time"}
                            //                     value={endTime}
                            //                     onChange={(e) => {
                            //                         const val = e?.target?.value ?? endTime_default;
                            //                         // HH:MM
                            //                         setEndTime(val);
                            //                     }}
                            //                     className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                            //                 />
                            //             </div>
                            //         </div>
                            //     </div>
                        }


                        {/* All Day */}
                        {/* <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            >
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    {capitalizeText("All Day")}
                                </div>
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    <div className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["form-switch"] ?? ""}  ${CustomBootstrap["form-check"] ?? ""} `} >
                                        <input className={`  ${CustomBootstrap["form-check-input"] ?? ""} `} type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                            onChange={(e) => {
                                                if (e?.target?.hasOwnProperty("checked")) {
                                                    setAllDay(e?.target?.checked)
                                                }
                                            }}
                                            checked={allDay}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}



                        {/* Repeat */}
                        {/* <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            >
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    {capitalizeText("Repeat")}
                                </div>
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    <div className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["form-switch"] ?? ""}  ${CustomBootstrap["form-check"] ?? ""} `} >
                                        <input className={`  ${CustomBootstrap["form-check-input"] ?? ""} `} type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                            onChange={(e) => {
                                                if (e?.target?.hasOwnProperty("checked")) {
                                                    setIsRepeatable(e?.target?.checked)
                                                }
                                            }}
                                            checked={isRepeatable}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* More Information */}
                        <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                style={{
                                    // textAlign: "center",
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                }}
                            >
                                {capitalizeText("More Information")}
                            </div>
                        </div>



                        {/* Notes */}
                        <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            >
                                <textarea
                                    className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                    placeholder={capitalizeText("Notes")}
                                    value={notes}
                                    onChange={(e) => {
                                        const val = e?.target?.value ?? "";
                                        setNotes(val);
                                    }}
                                />
                            </div>
                        </div>


                        {/* not used in lwe -------------------------------------------- */}
                        {/* Event Type */}
                        {/* <div
                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                        >
                            <div
                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                            >
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    {capitalizeText("Event Type")}
                                </div>
                                <div
                                    className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                >
                                    <Select
                                        options={eventTypeOptions ?? []}
                                        value={eventTypeOptions?.find((x) => eventType === x.value) ?? {}}
                                        onChange={(e) => {
                                            setEventType(e.value);
                                        }}
                                        fullWidth
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                        }}
                                        menuPortalTarget={document.body}
                                        menuPosition='fixed'
                                    />
                                </div>
                            </div>
                        </div> */}



                        {
                            isRepeatable ?
                                <>
                                    {/* Repeat */}
                                    <div
                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                        >
                                            <div
                                                className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                                style={{
                                                    fontSize: "17px",
                                                    fontWeight: "bold",
                                                    textAlign: "start",
                                                    padding: 0,
                                                }}
                                            >
                                                {capitalizeText("Repeat")}
                                            </div>
                                            <div
                                                className={`  ${CustomBootstrap["col-6"] ?? ""}   `}
                                                style={{
                                                    fontSize: "17px",
                                                    fontWeight: "bold",
                                                    // textAlign: "start",
                                                    // padding: 0,
                                                }}
                                            >
                                                <Select
                                                    options={FREQ_options?.map((x) => ({ value: x, label: x })) ?? []}
                                                    value={{ value: FREQ, label: FREQ }}
                                                    onChange={(e) => {
                                                        setFREQ(e.value);
                                                    }}
                                                    fullWidth
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    menuPosition='fixed'

                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* daily */}
                                    {/* every */}
                                    <div
                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}    `}
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                                // textAlign: "start",
                                                // padding: 0,
                                            }}
                                        >
                                            {capitalizeText("Repeat every")}
                                            &nbsp;
                                            <input
                                                type="number"
                                                style={{
                                                    width: "80px",
                                                }}
                                                value={INTERVAL}
                                                onChange={(e) => {
                                                    const val = e?.target?.value ?? 1;
                                                    setINTERVAL(val);
                                                }}
                                                className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                            />
                                            &nbsp;
                                            {(() => {
                                                let tmp_FREQ = '';
                                                switch (FREQ) {
                                                    case DAILY:
                                                        tmp_FREQ = "day";
                                                        break;
                                                    case WEEKLY:
                                                        tmp_FREQ = "week";
                                                        break;
                                                    case MONTHLY:
                                                        tmp_FREQ = "month";
                                                        break;
                                                    case YEARLY:
                                                        tmp_FREQ = "year";
                                                        break;

                                                    default:
                                                        break;
                                                }
                                                return capitalizeText(`${tmp_FREQ}(s)`);
                                            })()}
                                            {
                                                (WEEKLY === FREQ) ?
                                                    <>
                                                        &nbsp;
                                                        {capitalizeText("on")}
                                                        :
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>

                                    {(() => {
                                        switch (FREQ) {
                                            // select WEEKLY days
                                            case WEEKLY:
                                                return (
                                                    <div
                                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                                    >
                                                        <div
                                                            className={`  ${CustomBootstrap["d-flex"] ?? ""}  ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                            style={{
                                                                gap: "15px",
                                                            }}
                                                        >
                                                            {
                                                                day_names?.map((x) => {
                                                                    let val = false;
                                                                    let callback = (val) => { };
                                                                    switch (x) {
                                                                        case "Sat":
                                                                            val = SA;
                                                                            callback = setSA;
                                                                            break;
                                                                        case "Sun":
                                                                            val = SU;
                                                                            callback = setSU;
                                                                            break;
                                                                        case "Mon":
                                                                            val = MO;
                                                                            callback = setMO;
                                                                            break;
                                                                        case "Tue":
                                                                            val = TU;
                                                                            callback = setTU;
                                                                            break;
                                                                        case "Wed":
                                                                            val = WE;
                                                                            callback = setWE;
                                                                            break;
                                                                        case "Thu":
                                                                            val = TH;
                                                                            callback = setTH;
                                                                            break;
                                                                        case "Fri":
                                                                            val = FR;
                                                                            callback = setFR;
                                                                            break;

                                                                        default:
                                                                            break;
                                                                    }
                                                                    const tmp_class_day = `btn-${val ? "" : "outline-"}primary`;
                                                                    return (
                                                                        <button
                                                                            key={x}
                                                                            className={`  ${CustomBootstrap["btn"] ?? ""}   ${CustomBootstrap[tmp_class_day] ?? ""}     `}
                                                                            value={val}
                                                                            onClick={() => {
                                                                                callback(!val)
                                                                            }}
                                                                            style={{
                                                                                fontSize: "17px",
                                                                                fontWeight: "bold",
                                                                                // textAlign: "start",
                                                                                // padding: 0,
                                                                            }}
                                                                        >
                                                                            {capitalizeText(x)}
                                                                        </button>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                                break;

                                            // select monthly days
                                            case MONTHLY:
                                                return (
                                                    <>

                                                        {/* radio On */}
                                                        <div
                                                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                                        >
                                                            <div
                                                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                                            >
                                                                <div
                                                                    className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                                    style={end_repeat_radio_style}
                                                                >
                                                                    <input
                                                                        className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                                        type="radio" name={monthly_repeat_radio_name} id={monthly_Repeat_on}
                                                                        checked={monthlyRepeatRadio === monthly_Repeat_on}
                                                                        onChange={() => {
                                                                            setMonthlyRepeatRadio(monthly_Repeat_on);
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className={`  ${CustomBootstrap["form-check-label"] ?? ""}   `}
                                                                    >
                                                                        <div
                                                                            className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}     `}
                                                                            style={{
                                                                                fontSize: "17px",
                                                                                fontWeight: "bold",
                                                                                // textAlign: "start",
                                                                                // padding: 0,
                                                                            }}
                                                                        >
                                                                            {capitalizeText("On")}
                                                                            &nbsp;
                                                                            <input
                                                                                type="number"
                                                                                style={{
                                                                                    width: "80px",
                                                                                }}
                                                                                value={BYMONTHDAY}
                                                                                onChange={(e) => {
                                                                                    const val = e?.target?.value ?? 1;
                                                                                    setBYMONTHDAY(val);
                                                                                }}
                                                                                className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                                                            />
                                                                            &nbsp;
                                                                            {capitalizeText("of every month")}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* radio BYDAY */}
                                                        <div
                                                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                                        >
                                                            <div
                                                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                                            >
                                                                <div
                                                                    className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                                    style={end_repeat_radio_style}
                                                                >
                                                                    <input
                                                                        className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                                        type="radio" name={monthly_repeat_radio_name} id={monthly_Repeat_byday}
                                                                        checked={monthlyRepeatRadio === monthly_Repeat_byday}
                                                                        onChange={() => {
                                                                            setMonthlyRepeatRadio(monthly_Repeat_byday);
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}     `}
                                                                        style={{
                                                                            fontSize: "17px",
                                                                            fontWeight: "bold",
                                                                            // textAlign: "start",
                                                                            // padding: 0,
                                                                        }}
                                                                    >
                                                                        {capitalizeText("The")}
                                                                        &nbsp;
                                                                        <div>
                                                                            <Select
                                                                                id='BYDAY_order'
                                                                                value={BYDAY_orderOptions?.find((x) => BYDAY_order === x.value)}
                                                                                options={BYDAY_orderOptions}
                                                                                onChange={(e) => {
                                                                                    setBYDAY_order(e.value);
                                                                                }}
                                                                                fullWidth
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                menuPosition='fixed'
                                                                            />
                                                                        </div>
                                                                        &nbsp;
                                                                        <div>
                                                                            <Select
                                                                                id='BYDAY_name'
                                                                                value={BYDAY_nameOptions?.find((x) => BYDAY_name === x.value)}
                                                                                options={BYDAY_nameOptions}
                                                                                onChange={(e) => {
                                                                                    setBYDAY_name(e.value);
                                                                                }}
                                                                                fullWidth
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                menuPosition='fixed'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                );
                                                break;

                                            // select yearly days
                                            case YEARLY:
                                                return (
                                                    <>

                                                        {/* radio Every */}
                                                        <div
                                                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                                        >
                                                            <div
                                                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                                            >
                                                                <div
                                                                    className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                                    style={end_repeat_radio_style}
                                                                >
                                                                    <input
                                                                        className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                                        type="radio" name={yearly_repeat_radio_name} id={yearly_Repeat_every}
                                                                        checked={yearlyRepeatRadio === yearly_Repeat_every}
                                                                        onChange={() => {
                                                                            setYearlyRepeatRadio(yearly_Repeat_every);
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className={`  ${CustomBootstrap["form-check-label"] ?? ""}   `}
                                                                    // htmlFor={yearly_Repeat_every}
                                                                    >
                                                                        <div
                                                                            className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}     `}
                                                                            style={{
                                                                                fontSize: "17px",
                                                                                fontWeight: "bold",
                                                                                // textAlign: "start",
                                                                                // padding: 0,
                                                                            }}
                                                                        >
                                                                            {capitalizeText("Every")}
                                                                            &nbsp;
                                                                            <div>
                                                                                <Select
                                                                                    value={{ value: BYMONTH, label: monthNamesLong[BYMONTH] }}
                                                                                    options={monthNamesLong?.map((month, month_num) => ({ value: month_num, label: month }))}
                                                                                    onChange={(e) => {
                                                                                        setBYMONTH(e.value);
                                                                                    }}
                                                                                    fullWidth
                                                                                    styles={{
                                                                                        menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                                                    }}
                                                                                    menuPortalTarget={document.body}
                                                                                    menuPosition='fixed'
                                                                                />
                                                                            </div>
                                                                            &nbsp;
                                                                            <input
                                                                                type="number"
                                                                                style={{
                                                                                    width: "80px",
                                                                                }}
                                                                                value={BYMONTHDAY}
                                                                                onChange={(e) => {
                                                                                    const val = e?.target?.value ?? 1;
                                                                                    setBYMONTHDAY(val);
                                                                                }}
                                                                                className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                                                            />
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* radio BYDAY */}
                                                        <div
                                                            className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                                        >
                                                            <div
                                                                className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                                            >
                                                                <div
                                                                    className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                                    style={end_repeat_radio_style}
                                                                >
                                                                    <input
                                                                        className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                                        type="radio" name={yearly_repeat_radio_name} id={yearly_Repeat_byday}
                                                                        checked={yearlyRepeatRadio === yearly_Repeat_byday}
                                                                        onChange={() => {
                                                                            setYearlyRepeatRadio(yearly_Repeat_byday);
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}     `}
                                                                        style={{
                                                                            fontSize: "17px",
                                                                            fontWeight: "bold",
                                                                            // textAlign: "start",
                                                                            // padding: 0,
                                                                        }}
                                                                    >
                                                                        {capitalizeText("The")}
                                                                        &nbsp;
                                                                        <div>
                                                                            <Select
                                                                                value={BYDAY_orderOptions?.find((x) => BYDAY_order === x.value)}
                                                                                options={BYDAY_orderOptions}
                                                                                onChange={(e) => {
                                                                                    setBYDAY_order(e.value);
                                                                                }}
                                                                                fullWidth
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                menuPosition='fixed'
                                                                            />
                                                                        </div>
                                                                        &nbsp;
                                                                        <div>
                                                                            <Select
                                                                                value={BYDAY_nameOptions?.find((x) => BYDAY_name === x.value)}
                                                                                options={BYDAY_nameOptions}
                                                                                onChange={(e) => {
                                                                                    setBYDAY_name(e.value);
                                                                                }}
                                                                                fullWidth
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                menuPosition='fixed'
                                                                            />
                                                                        </div>
                                                                        &nbsp;
                                                                        {capitalizeText("of")}
                                                                        &nbsp;
                                                                        <div>
                                                                            <Select
                                                                                value={{ value: BYMONTH, label: monthNamesLong[BYMONTH] }}
                                                                                options={monthNamesLong?.map((month, month_num) => ({ value: month_num, label: month }))}
                                                                                onChange={(e) => {
                                                                                    setBYMONTH(e.value);
                                                                                }}
                                                                                fullWidth
                                                                                styles={{
                                                                                    menuPortal: base => ({ ...base, zIndex: 9999999 }),
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                menuPosition='fixed'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                );
                                                break;
                                            default:
                                                break;
                                        }
                                    })()}

                                    {/* End Repeat */}
                                    <div
                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "bold",
                                                // textAlign: "start",
                                                // padding: 0,
                                            }}
                                        >
                                            {capitalizeText("End Repeat")}
                                        </div>
                                    </div>


                                    {/* radio Never */}
                                    <div
                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                        >
                                            <div
                                                className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                style={end_repeat_radio_style}
                                            >
                                                <input
                                                    className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                    type="radio" name={end_repeat_radio_name} id={End_Repeat_Never}
                                                    checked={endRepeatRadio === End_Repeat_Never}
                                                    onChange={() => {
                                                        setEndRepeatRadio(End_Repeat_Never);
                                                    }}
                                                />
                                                <label
                                                    className={`  ${CustomBootstrap["form-check-label"] ?? ""}   `}
                                                    style={{
                                                        fontSize: "17px",
                                                        fontWeight: "bold",
                                                        // textAlign: "start",
                                                        // padding: 0,
                                                    }}
                                                >
                                                    {capitalizeText("Never")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>



                                    {/* radio On */}
                                    <div
                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                        >
                                            <div
                                                className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                style={end_repeat_radio_style}
                                            >
                                                <input
                                                    className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                    type="radio" name={end_repeat_radio_name} id={End_Repeat_COUNT}
                                                    checked={endRepeatRadio === End_Repeat_COUNT}
                                                    onChange={() => {
                                                        setEndRepeatRadio(End_Repeat_COUNT);
                                                    }}
                                                />
                                                <label
                                                    className={`  ${CustomBootstrap["form-check-label"] ?? ""}   `}
                                                // htmlFor={End_Repeat_COUNT}
                                                >
                                                    <div
                                                        className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}     `}
                                                        style={{
                                                            fontSize: "17px",
                                                            fontWeight: "bold",
                                                            // textAlign: "start",
                                                            // padding: 0,
                                                        }}
                                                    >
                                                        {capitalizeText("On")}
                                                        &nbsp;
                                                        <input
                                                            type="number"
                                                            style={{
                                                                width: "80px",
                                                            }}
                                                            value={COUNT}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value ?? 1;
                                                                setCOUNT(val);
                                                            }}
                                                            className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                                        />
                                                        &nbsp;
                                                        {capitalizeText("occurrence(s)")}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>



                                    {/* radio After */}
                                    <div
                                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["mb-3"] ?? ""}    `}
                                    >
                                        <div
                                            className={`  ${CustomBootstrap["col-12"] ?? ""}   ${CustomBootstrap["row"] ?? ""}    `}
                                        >
                                            <div
                                                className={`  ${CustomBootstrap["form-check"] ?? ""}  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}   `}
                                                style={end_repeat_radio_style}
                                            >
                                                <input
                                                    className={`  ${CustomBootstrap["form-check-input"] ?? ""}   `}
                                                    type="radio" name={end_repeat_radio_name} id={End_Repeat_Until}
                                                    checked={endRepeatRadio === End_Repeat_Until}
                                                    onChange={() => {
                                                        setEndRepeatRadio(End_Repeat_Until);
                                                    }}
                                                />
                                                <label
                                                    className={`  ${CustomBootstrap["form-check-label"] ?? ""}   `}
                                                // htmlFor={End_Repeat_Until}
                                                >
                                                    <div
                                                        className={`  ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["align-items-center"] ?? ""}     `}
                                                        style={{
                                                            fontSize: "17px",
                                                            fontWeight: "bold",
                                                            // textAlign: "start",
                                                            // padding: 0,
                                                        }}
                                                    >
                                                        {capitalizeText("Until")}
                                                        &nbsp;
                                                        <input
                                                            style={{
                                                                width: allDay ? "150px" : "250px",
                                                            }}
                                                            type={allDay ? "date" : "datetime-local"}
                                                            value={allDay ? formatDate(UNTIL) : formatDateTimeLocal(UNTIL)}
                                                            onChange={(e) => {
                                                                const val = e?.target?.value ?? "";
                                                                // // devConsoleLog(val);
                                                                const date = new Date(val);
                                                                // // devConsoleLog(date);
                                                                setUNTIL(date);
                                                            }}
                                                            className={`  ${CustomBootstrap["form-control"] ?? ""}   `}
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                </>
                                :
                                null
                        }


                        {/* /////////////////////////////////////////////////////////////////////////// */}
                    </div>


                    {/* btns */}
                    <div
                        className={`  ${CustomBootstrap["row"] ?? ""}    ${CustomBootstrap["my-3"] ?? ""}    `}
                    >
                        <div
                            className={`  ${CustomBootstrap["col-6"] ?? ""}     `}
                        >
                            <button
                                className={`  ${CustomBootstrap["btn"] ?? ""}   ${CustomBootstrap["btn-primary"] ?? ""}     `}
                                style={{
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    // textAlign: "start",
                                    // padding: 0,
                                }}
                                onClick={() => {
                                    if ((WEEKLY === FREQ) && (!(SA || SU || MO || TU || WE || TH || FR))) {

                                        Swal.fire({
                                            icon: "warning",
                                            title: capitalizeText("Please, Select at least a day!"),
                                            // title: "Please, Select at least a day!",
                                            showConfirmButton: false,
                                            showDenyButton: false,
                                            cancelButtonText: capitalizeText("Ok"),
                                            showCloseButton: false,
                                            showCancelButton: true,
                                            // showLoaderOnDeny: true
                                        })
                                        return;
                                    }
                                    if (!(title?.trim()?.length ?? 0)) {

                                        Swal.fire({
                                            icon: "warning",
                                            title: capitalizeText("Please, Type a title!"),
                                            // title: "Please, Select at least a day!",
                                            showConfirmButton: false,
                                            showDenyButton: false,
                                            cancelButtonText: capitalizeText("Ok"),
                                            showCloseButton: false,
                                            showCancelButton: true,
                                            // showLoaderOnDeny: true
                                        })
                                        return;
                                    }
                                    Swal.showLoading();
                                    // const currentUrl = router.asPath;
                                    const userId = user?.id ?? null;
                                    const courseId = (props?.is_in_course ? (props?.courseId) : (null)) ?? null;
                                    const data_obj = {
                                        allDay,
                                        title,
                                        notes,
                                        endTime,
                                        // // not used in lwe --------------------------------------------
                                        // section_id -> not lwe
                                        // here we use courseId
                                        courseId,
                                        userId,
                                        // // not used in lwe --------------------------------------------
                                        // eventType,
                                        // eventTypeName: eventTypeOptions?.find((x) => eventType === x.value)?.label ?? "",
                                        startDate: formatDateTime(startDate),
                                        creatorId: userId,
                                        url: window.location.href,
                                    };
                                    if (isRepeatable) {
                                        data_obj.rRule = rRuleEncode();
                                    }
                                    // // devConsoleLog("courseId");
                                    // // devConsoleLog(courseId);
                                    // // devConsoleLog("user");
                                    // // devConsoleLog(user);

                                    // // devConsoleLog("Full URL:", window.location.href);

                                    // devConsoleLog("data_obj");
                                    // devConsoleLog(data_obj);

                                    if (modalUsage_edit_event === modalUsage) {
                                        const id = selected_id ?? "";
                                        Swal.fire({
                                            icon: "question",
                                            title: capitalizeText("Are you sure you want to edit this event?"),
                                            showConfirmButton: true,
                                            confirmButtonText: capitalizeText("Edit"),
                                            showCancelButton: true,
                                            cancelButtonText: capitalizeText("Cancel"),
                                            showDenyButton: false,
                                            showCloseButton: false,
                                            // showLoaderOnDeny: true
                                        })?.then((result) => {
                                            if (result?.value ?? false) {

                                                swal_loading();
                                                closeModal();

                                                try {
                                                    axios?.put(`${BACKEND_ROOT}/calender/events/${id}`, data_obj)?.then((res) => {
                                                        // // devConsoleLog("res");
                                                        // // devConsoleLog(res);
                                                        const res_data = res?.data ?? false;
                                                        if (res_data ?? false) {
                                                            Swal.fire({
                                                                icon: "success",
                                                                title: capitalizeText("Editted successfully!"),
                                                                showConfirmButton: false,
                                                                showDenyButton: false,
                                                                cancelButtonText: capitalizeText("Ok"),
                                                                showCloseButton: false,
                                                                showCancelButton: true,
                                                                // showLoaderOnDeny: true
                                                            });
                                                            const arr = [...calenderEvents]
                                                            const index = arr.findIndex(obj => obj._id === id);

                                                            if (index !== -1) {
                                                                arr[index] = res_data;
                                                            }

                                                            setCalenderEvents(arr);
                                                        }
                                                    })?.catch((err) => {
                                                        // devConsoleLog("err");
                                                        // devConsoleLog(err.message || err.code);
                                                        // Swal.close();
                                                        Swal.fire({
                                                            icon: "error",
                                                            title: capitalizeText("Failed!"),
                                                            showConfirmButton: false,
                                                            showDenyButton: false,
                                                            cancelButtonText: capitalizeText("Ok"),
                                                            showCloseButton: false,
                                                            showCancelButton: true,
                                                            // showLoaderOnDeny: true
                                                        });
                                                    });
                                                } catch (err) {
                                                    // devConsoleLog("err");
                                                    // devConsoleLog(err.message || err.code);
                                                    // Swal.close();
                                                    Swal.fire({
                                                        icon: "error",
                                                        title: capitalizeText("Failed!"),
                                                        showConfirmButton: false,
                                                        showDenyButton: false,
                                                        cancelButtonText: capitalizeText("Ok"),
                                                        showCloseButton: false,
                                                        showCancelButton: true,
                                                        // showLoaderOnDeny: true
                                                    });
                                                }
                                            }
                                        });
                                    } else {
                                        closeModal();
                                        try {
                                            axios?.post(`${BACKEND_ROOT}/calender/events`, data_obj)?.then((res) => {
                                                // // devConsoleLog("res");
                                                // // devConsoleLog(res);
                                                const res_data = res?.data ?? false;
                                                if (res_data) {
                                                    Swal.fire({
                                                        icon: "success",
                                                        title: capitalizeText("Added successfully!"),
                                                        // title: "Added successfully!",
                                                        showConfirmButton: false,
                                                        showDenyButton: false,
                                                        cancelButtonText: capitalizeText("Ok"),
                                                        showCloseButton: false,
                                                        showCancelButton: true,
                                                        // showLoaderOnDeny: true
                                                    });
                                                    setCalenderEvents([...calenderEvents, res_data]);
                                                }
                                            })?.catch((err) => {
                                                // devConsoleLog("err");
                                                // devConsoleLog(err.message || err.code);
                                                // Swal.close();
                                                Swal.fire({
                                                    icon: "error",
                                                    title: capitalizeText("Failed!"),
                                                    showConfirmButton: false,
                                                    showDenyButton: false,
                                                    cancelButtonText: capitalizeText("Ok"),
                                                    showCloseButton: false,
                                                    showCancelButton: true,
                                                    // showLoaderOnDeny: true
                                                });
                                            });

                                        } catch (err) {
                                            // devConsoleLog("err");
                                            // devConsoleLog(err.message || err.code);
                                            // Swal.close();
                                            Swal.fire({
                                                icon: "error",
                                                title: capitalizeText("Failed!"),
                                                showConfirmButton: false,
                                                showDenyButton: false,
                                                cancelButtonText: capitalizeText("Ok"),
                                                showCloseButton: false,
                                                showCancelButton: true,
                                                // showLoaderOnDeny: true
                                            });
                                        }
                                    }

                                }}
                            >
                                {capitalizeText("Submit")}
                            </button>
                        </div>
                        <div
                            className={`  ${CustomBootstrap["col-6"] ?? ""}     `}
                        >
                            <button
                                className={`  ${CustomBootstrap["btn"] ?? ""}   ${CustomBootstrap["btn-danger"] ?? ""}     `}
                                style={{
                                    fontSize: "17px",
                                    fontWeight: "bold",
                                    // textAlign: "start",
                                    // padding: 0,
                                }}
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                {capitalizeText("Cancel")}
                            </button>
                        </div>
                    </div>

                </div>
            </CustomModal>

        </div >
    )
}

export default withStore(CalenderComponent)