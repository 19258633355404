"use client"

import { useTranslate } from "@/client/hooks"
import { Fragment, useMemo, useState } from "react"
import { FaStar } from "@react-icons/all-files/fa/FaStar"
import { FaGraduationCap } from "@react-icons/all-files/fa/FaGraduationCap"
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline"
import Select from "react-select"
import { FaUpload } from "@react-icons/all-files/fa/FaUpload";
import { FaImage } from "@react-icons/all-files/fa/FaImage";
import { Switch } from "@headlessui/react"
import Image from "next/image"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { AxiosRequest, devConsoleLog, formatKeyText } from "@/client/utils"
import { BACKEND_ROOT } from "@/constants"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import CreatableSelect from "react-select/creatable";
import { clone } from "lodash"


const General = ({ options, formData, setFormData, setLastStep, setOptions }) => {
    const translate = useTranslate()
    const [errors, setErrors] = useState({})
    const [validating, setValidating] = useState(false)

    const formFields = useMemo(() => {
        const mainFields = {
            "title": {
                text: translate("Course Title"),
                invalid: (value) => !Boolean(value) || value.length < 3,
                error: "Must be 3 letters at least!"
            },
            "description": {
                text: translate("Course Description"),
                invalid: (value) => Boolean(value) && value.length < 20,
                error: "Must be 20 characters at least!"
            },
            "file": {
                text: translate("Course Image"),
                invalid: (value) => !Boolean(value) || value instanceof File ? !(value?.type?.startsWith('image/') && value.size <= 1024 * 1024) : !value.startsWith('https:'),
                error: "Must be image file (jpg, png, etc..) with max size equal to 1mb!"
            },
            "keywords": {
                text: translate("Related Keywords"),
                invalid: (value) => !Array.isArray(value) || value.length == 0,
                error: "You have to add 1 keyword at least!"
            },
            "audiences": {
                text: translate("Target Audiences"),
                invalid: (value) => !Array.isArray(value) || value.length == 0,
                error: "You have to choose at least 1 category!"
            },
            ...formData.audiences?.reduce((acc, audience, audienceIdx) => {
                acc[`audience-${audienceIdx}-category`] = {
                    text: translate("Category"),
                    invalid: (value) => !Boolean(value),
                    error: "Category field is required"
                }
                acc[`audience-${audienceIdx}-learners`] = {
                    text: translate("Intended Learners"),
                    invalid: (value) => Boolean(value) && !Array.isArray(value),
                    error: "Invalid value!"
                }

                return acc
            }, {})
        }

        const academicFields = {
            "educationalSystem": {
                text: translate("Educational System"),
                invalid: (value) => !Boolean(value),
                error: "This field is required!"
            },
            "gradeLevel": {
                text: translate("Grade Level"),
                invalid: (value) => !Boolean(value),
                error: "This field is required!"
            },
        }

        const professionalFields = {
            "pathway": {
                text: translate("Course Pathway"),
                invalid: (value) => !Boolean(value),
                error: "This field is required!"
            },
            "order": {
                text: translate("Order in Pathway"),
                invalid: (value) => !Boolean(value) || value <= 0,
                error: "Must be greater than 0!"
            },
        }

        return formData.type == 'academic' ? { ...mainFields, ...academicFields } : { ...mainFields, ...professionalFields }
    }, [formData.type, formData.audiences?.length])

    const validateField = (fieldName, fieldValue) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[fieldName]

        const isInvalid = formFields[fieldName].invalid(fieldValue)

        if (isInvalid) {
            updatedErrors[fieldName] = formFields[fieldName].error
        }

        setErrors(updatedErrors)

        return isInvalid
    }

    const handleChange = (e) => {
        const fieldName = e.target.id
        const fieldValue = e.target.value

        setFormData({
            ...formData,
            [fieldName]: fieldValue,
        })

        validateField(fieldName, fieldValue)
    }

    const ltr = document.dir == 'ltr'

    const deleteKeyword = (kwIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.keywords.splice(kwIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(errId => {
            // clear error field of deleted kw
            if (errId.includes(`kw-${kwIdx}`)) {
                delete updatedErrors[errId]
            } else if (errId.includes(`kw-`)) {
                // shift up errors of other keywords
                let errIdKwIdx = errId.split('kw-')[1]

                if (errIdKwIdx > kwIdx) {
                    updatedErrors[`kw-${errIdKwIdx - 1}`] = updatedErrors[errId]
                    delete updatedErrors[errId]
                }
            }
        })

        if (updatedFormData.keywords.length == 0) {
            updatedErrors[`keywords`] = "You have to add 1 keyword at least!"
        }

        setErrors(updatedErrors)
    }

    const handleKeywordChange = (e, kwIdx) => {
        const fieldId = e.target.id
        const fieldValue = e.target.value

        let updatedErrors = { ...errors }
        delete updatedErrors[fieldId]

        let updatedFormData = { ...formData }
        updatedFormData.keywords[kwIdx] = fieldValue
        setFormData(updatedFormData)

        if (!fieldValue) {
            updatedErrors[fieldId] = `This field is required!`
        }

        setErrors(updatedErrors)
    }

    const addNewKeyword = () => {
        let updatedErrors = { ...errors }
        delete updatedErrors.keywords

        let updatedFormData = { ...formData }

        if (formData.hasOwnProperty('keywords')) {
            updatedFormData.keywords.push("")
        } else {
            updatedFormData.keywords = [""]
        }

        setFormData(updatedFormData)

        const kwIdx = updatedFormData.keywords.length - 1
        updatedErrors[`kw-${kwIdx}`] = "This field is required"

        setErrors(updatedErrors)
    }

    const handleSubmit = async e => {
        e.preventDefault()

        // check for invalidFields required fields
        let invalidFields = {};
        for (let key of Object.keys(formFields)) {
            const { invalid, error } = formFields[key]

            if (key.startsWith('audience-')) {
                let keyTokens = key.split('audience-')[1].split('-')
                let audienceIdx = +keyTokens[0]
                let audienceKey = keyTokens.slice(1).join('-')

                if (invalid(formData.audiences[audienceIdx][audienceKey])) {
                    invalidFields[key] = error
                }
            } else {
                if (invalid(formData[key])) {
                    invalidFields[key] = error
                }
            }
        }
        if (Object.keys(invalidFields).length != 0) return setErrors(invalidFields);

        // validate course exiists
        try {
            setValidating(true)

            const data = await AxiosRequest.post(`${BACKEND_ROOT}/course/validateExists`, formData).then(res => res.data)
            if (data.errors) return setErrors(data.errors)
        } catch (err) {
            devConsoleLog(`Failed to validate course exists: ${err.message}`)
        } finally {
            setValidating(false)
        }

        setLastStep(`outcomes`)
    }

    const addNewAudience = () => {
        let updatedErrors = { ...errors }
        delete updatedErrors.audiences

        let updatedFormData = { ...formData }

        const newAudience = { category: "", intendedLearners: [] }

        if (updatedFormData.hasOwnProperty('audiences')) {
            updatedFormData.audiences.push(newAudience)
        } else {
            updatedFormData.audiences = [newAudience]
        }

        setFormData(updatedFormData)

        const audience = updatedFormData.audiences.length - 1
        updatedErrors[`audience-${audience}-category`] = "Category field is required!"

        setErrors(updatedErrors)
    }

    const deleteAudience = (audienceIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.audiences.splice(audienceIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`audience-${audienceIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`audience-`)) {
                let keyTokens = key.split('audience-')[1].split('-')
                let keyAudienceIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (keyAudienceIdx > audienceIdx) {
                    updatedErrors[`audience-${keyAudienceIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.audiences.length == 0) {
            updatedErrors[`audiences`] = "You have to add 1 audience at least!"
        }

        setErrors(updatedErrors)
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 flex-grow">
            {/* header */}
            <h1 className="text-3xl text-blue-500 font-bold">{translate("General Course Info")}</h1>

            {/* course type toggler */}
            <div className="flex gap-x-4 items-center justify-between">
                <h2 className="capitalize">{translate("Course Type")}<span className="text-red-500 text-sm"> *</span></h2>
                <Switch
                    checked={formData.type == 'professional'}
                    onChange={() => {
                        let updatedFormData = { ...formData }
                        let updatedErrors = { ...errors }

                        const newType = formData.type == 'professional' ? 'academic' : 'professional'
                        updatedFormData.type = newType

                        if (newType == 'academic') {
                            delete updatedFormData.pathway
                            delete updatedFormData.order

                            delete updatedErrors.pathway
                            delete updatedErrors.order

                        } else {
                            delete updatedFormData.educationalSystem
                            delete updatedFormData.gradeLevel

                            delete updatedErrors.educationalSystem
                            delete updatedErrors.gradeLevel
                        }

                        setFormData(updatedFormData)
                        setErrors(updatedErrors)
                    }}
                    className={`bg-gray-200 border-gray-300 flex h-[30px] min-w-[60px] md:min-w-[250px] items-center rounded-full border shadow w-fit`}
                >
                    <div className={`${formData.type == 'professional' ? ltr ? 'translate-x-full' : '-translate-x-full' : 'translate-x-0'} w-1/2 h-full transition-all duration-500 text-gray-50 rounded-full bg-blue-500 hover:bg-blue-400 gap-x-1 flex justify-center items-center`}>
                        <span className={`${formData.type == 'professional' ? 'rotate-0' : ltr ? '-rotate-[360deg]' : 'rotate-[360deg]'} transition-all duration-500`}>
                            {
                                formData.type == 'professional' ? <FaStar fontSize={20} />
                                    : <FaGraduationCap fontSize={20} />
                            }
                        </span>

                        <span className="text-sm capitalize truncate max-md:hidden">{formData.type == 'professional' ? translate('Professional') : translate('Academic')}</span>
                    </div>
                </Switch>
            </div>

            {/* title */}
            <div className="flex flex-col w-full">
                <label htmlFor="title" className="text-start">{formFields.title.text}<span className="text-red-500 text-sm"> *</span></label>
                <input
                    id="title"
                    type="text"
                    className="input-bar"
                    placeholder={translate("Add title for your course")}
                    value={formData.title || ""}
                    onChange={handleChange}
                />
                {
                    errors.title &&
                    <small className='text-red-700 flex items-center gap-1'>
                        <MdErrorOutline /> {translate(errors.title)}
                    </small>
                }
            </div>

            {/* keywords */}
            <div className="flex flex-col w-full gap-y-2">
                <h2 className="text-start font-bold text-lg">{formFields.keywords.text}<span className="text-red-500 text-sm"> *</span></h2>

                <div className="flex flex-wrap gap-2">
                    {
                        formData.keywords?.map((kw, kwIdx) => (
                            <div key={kwIdx} className="flex flex-col flex-grow">
                                {/* input & delete kw*/}
                                <div className="relative">
                                    {/* input */}
                                    <input value={kw} id={`kw-${kwIdx}`} type="text" className="input-bar" placeholder={translate("Add your keyword..")} onChange={e => { handleKeywordChange(e, kwIdx) }} />

                                    {/* delete kw */}
                                    <button type="button" className="danger-icon-btn absolute inset-y-0 right-1" onClick={() => { deleteKeyword(kwIdx) }}>
                                        <MdDelete size={18} />
                                    </button>
                                </div>

                                {
                                    errors[`kw-${kwIdx}`] &&
                                    <small className='text-red-700 flex items-center gap-1'>
                                        <MdErrorOutline /> {translate(errors[`kw-${kwIdx}`])}
                                    </small>
                                }
                            </div>
                        ))
                    }

                    {/* new kw button */}
                    <button type="button" className="flex-grow h-fit rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={addNewKeyword}>
                        <FaPlus />
                        {translate("New Keyword")}
                    </button>
                </div>

                {
                    errors.keywords &&
                    <small className='text-red-700 flex items-center gap-1'>
                        <MdErrorOutline /> {translate(errors.keywords)}
                    </small>
                }
            </div>

            {/* description */}
            <div className="flex flex-col w-full">
                <label htmlFor="description" className="text-start">{formFields.description.text}<span className="text-gray-500 text-sm italic capitalize"> ({translate("optional")})</span></label>
                <textarea
                    id="description"
                    className="input-bar min-h-[100px]"
                    placeholder={translate("Add description for your course")}
                    value={formData.description || ""}
                    onChange={handleChange}
                />
                {
                    errors.description &&
                    <small className='text-red-700 flex items-center gap-1'>
                        <MdErrorOutline /> {translate(errors.description)}
                    </small>
                }
            </div>

            {/* audiences */}
            <div className="flex flex-col gap-y-2">
                <h2 className="text-start font-bold text-lg">{formFields.audiences.text}<span className="text-red-500 text-sm"> *</span></h2>

                {
                    formData.audiences?.map((audience, audienceIdx) => (
                        <div className="flex flex-col gap-y-2 rounded p-2 border border-dashed border-gray-400 bg-gray-100 relative" key={audienceIdx}>
                            {/* audience category */}
                            <div className="flex flex-col">
                                <h3 className="text-start">{formFields[`audience-${audienceIdx}-category`].text} {audienceIdx + 1}<span className="text-red-500 text-sm"> *</span></h3>
                                <CreatableSelect
                                    options={options?.audienceCategories?.filter(op => formData.audiences.every(aud => aud.category != op.value))}
                                    isSearchable isClearable
                                    classNames={{
                                        control: () => "select-input",
                                        container: () => "text-sm text-start",
                                    }}
                                    value={options?.audienceCategories?.find(op => op?.value == audience.category)}
                                    onChange={async selectedOption => {
                                        let newValue = selectedOption?.value || ""

                                        // check if new category
                                        if (Boolean(newValue)) {
                                            const valueFound = options?.audienceCategories?.find(op => op?.value == newValue)

                                            if (!Boolean(valueFound)) {
                                                await AxiosRequest.post(`${BACKEND_ROOT}/constants/newRecord/AudienceCategory`, {
                                                    sector: newValue
                                                }).then(res => {
                                                    const newOption = {
                                                        value: res.data._id,
                                                        label: res.data.sector,
                                                        intendedLearners: res.data.intendedLearners.map(pos => ({ value: pos, label: pos }))
                                                    }

                                                    setOptions(old => {
                                                        let updated = clone(old)

                                                        updated.audienceCategories.push(newOption)

                                                        return updated
                                                    })

                                                    newValue = newOption.value
                                                }).catch(err => {
                                                    devConsoleLog(`Failed to create new audience category: ${err.message}`)
                                                })
                                            }
                                        }

                                        setFormData(oldFormData => {
                                            // clone
                                            let newFormData = { ...oldFormData };

                                            // update
                                            newFormData.audiences[audienceIdx].category = newValue;
                                            newFormData.audiences[audienceIdx].intendedLearners = [];

                                            // return
                                            return newFormData;
                                        })

                                        validateField(`audience-${audienceIdx}-category`, newValue);
                                    }}
                                    placeholder={translate("Choose your course audience category..")}
                                />
                                {
                                    errors[`audience-${audienceIdx}-category`] &&
                                    <small className='text-red-700 flex items-center gap-1'>
                                        <MdErrorOutline /> {translate(errors[`audience-${audienceIdx}-category`])}
                                    </small>
                                }
                            </div>

                            {/* intended learners */}
                            {
                                audience.category &&
                                <div className="flex flex-col">
                                    <h3 className="text-start">{formFields[`audience-${audienceIdx}-learners`].text} {audienceIdx + 1} <span className="text-gray-500 text-sm italic capitalize">({translate("optional")})</span></h3>
                                    <CreatableSelect
                                        options={options?.audienceCategories?.find(op => op?.value == audience.category)?.intendedLearners}
                                        isSearchable isClearable isMulti
                                        classNames={{
                                            control: () => "select-input",
                                            container: () => "text-sm text-start",
                                            multiValue: () => "!bg-gray-50 border border-gray-400 border-dashed !rounded"
                                        }}
                                        value={
                                            options?.audienceCategories?.find(op =>
                                                op?.value == audience.category
                                            )?.intendedLearners?.filter(op =>
                                                audience?.intendedLearners?.includes(op?.value)
                                            ) || []
                                        }
                                        onChange={async selectedOptions => {
                                            const newValues = selectedOptions ? selectedOptions.map(op => op?.value) : [];

                                            // check if new learner
                                            if (newValues.length > 0) {
                                                const selectedCategoryIdx = options?.audienceCategories?.findIndex(op => op?.value == audience.category)
                                                const learnersOptionsValues = options?.audienceCategories[selectedCategoryIdx]?.intendedLearners.map(op => op.value)

                                                const valueMissing = newValues?.find(v => !learnersOptionsValues?.includes(v))

                                                if (Boolean(valueMissing)) {
                                                    await AxiosRequest.put(`${BACKEND_ROOT}/constants/newIntendedLearner`, {
                                                        categoryId:  audience.category,
                                                        newLearner: valueMissing
                                                    }).then(res => {
                                                        const updatedCategoryOption = {
                                                            value: res.data._id,
                                                            label: res.data.sector,
                                                            intendedLearners: res.data.intendedLearners.map(l => ({ value: l, label: l }))
                                                        }

                                                        setOptions(old => {
                                                            let updated = clone(old)

                                                            updated.audienceCategories[selectedCategoryIdx] = updatedCategoryOption

                                                            return updated
                                                        })
                                                    }).catch(err => {
                                                        devConsoleLog(`Failed to create new audience category: ${err.message}`)
                                                    })
                                                }
                                            }

                                            setFormData(oldFormData => {
                                                // clone
                                                let newFormData = { ...oldFormData };

                                                // update
                                                newFormData.audiences[audienceIdx].intendedLearners = newValues;

                                                // return
                                                return newFormData;
                                            });

                                            validateField(`audience-${audienceIdx}-learners`, newValues);
                                        }}
                                        placeholder={translate("Choose your course audience category..")}
                                    />
                                    {
                                        errors[`audience-${audienceIdx}-learners`] &&
                                        <small className='text-red-700 flex items-center gap-1'>
                                            <MdErrorOutline /> {translate(errors[`audience-${audienceIdx}-learners`])}
                                        </small>
                                    }
                                </div>
                            }

                            {/* delete button */}
                            <button type="button" className="danger-icon-btn p-2 absolute top-0 right-0" onClick={() => deleteAudience(audienceIdx)}>
                                <MdDelete size={18} />
                            </button>
                        </div>
                    ))
                }

                {/* new audience button */}
                <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={addNewAudience}>
                    <FaPlus />
                    {translate("New Audience")}
                </button>

                {
                    errors.audiences &&
                    <small className='text-red-700 flex items-center gap-1'>
                        <MdErrorOutline /> {translate(errors.audiences)}
                    </small>
                }
            </div>

            {/* file */}
            <div className="flex flex-col w-full text-start">
                <h2 className="capitalize">{translate("Course Image")}<span className="text-red-500 text-sm"> *</span></h2>

                <div className="relative w-full h-[200px] overflow-hidden bg-gray-100">
                    {
                        formData.file ?
                            <Image alt="cover image" width={50} height={50} src={formData.file instanceof File ? URL.createObjectURL(formData.file) : formData.file} className="w-full h-full object-cover rounded-lg" />
                            :
                            <div className="flex flex-col w-full h-full justify-center items-center text-gray-500 border-2 border-dashed border-gray-400 rounded-lg">
                                <FaImage className="w-full h-full p-5" />
                            </div>
                    }

                    <label htmlFor="file" className={`rounded-lg ${errors.file ? "opacity-80" : "opacity-0 hover:opacity-80"} cursor-pointer absolute flex flex-col gap-y-2 justify-center items-center bg-black/40 text-gray-50 inset-0 transition-all duration-300`}>
                        {
                            errors.file ?
                                <Fragment>
                                    <MdErrorOutline className="w-fit h-1/3 text-red-600" />
                                    <span className="text-center font-semibold text-sm uppercase text-red-600">{translate(errors.file)}</span>
                                </Fragment>
                                :
                                <Fragment>
                                    <FaUpload className="w-fit h-1/3" />
                                    <span className="capitalize font-semibold">{translate(formData.file ? 'change' : 'add')} {translate("image")}</span>
                                </Fragment>
                        }
                    </label>

                    <input id="file" type="file" accept="image/*" hidden onChange={e => {
                        const file = e.target.files[0];

                        if (!file) return;

                        const isInvalid = validateField('file', file)
                        let updatedFormData = { ...formData }

                        if (isInvalid) {
                            delete updatedFormData.file
                        } else {
                            updatedFormData.file = file
                        }

                        setFormData(updatedFormData)
                    }} />
                    {
                        errors.file &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.file)}
                        </small>
                    }
                </div>
            </div>

            {
                formData.type == 'academic' &&
                <div className="flex gap-4 max-md:flex-col">
                    {/* educational system */}
                    <div className="flex flex-col w-full">
                        <label htmlFor="educationalSystem" className="text-start">{formFields.educationalSystem.text}<span className="text-red-500 text-sm"> *</span></label>
                        <Select
                            id='educationalSystem'
                            options={options?.educationalSystems}
                            isSearchable isClearable
                            classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                            value={options?.educationalSystems?.find(op => op.value == formData.educationalSystem) || ""}
                            onChange={op => {
                                let updatedErrors = { ...errors }
                                delete updatedErrors['educationalSystem']
                                setErrors(updatedErrors)

                                const newValue = op?.value || ""
                                setFormData({ ...formData, educationalSystem: newValue })

                                validateField('educationalSystem', newValue)
                            }}
                            placeholder={translate("Choose your course educational system..")}
                        />
                        {
                            errors.educationalSystem &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.educationalSystem)}
                            </small>
                        }
                    </div>

                    {/* grade level */}
                    <div className="flex flex-col w-full">
                        <label htmlFor="gradeLevel" className="text-start">{formFields.gradeLevel.text}<span className="text-red-500 text-sm"> *</span></label>
                        <Select
                            id='gradeLevel'
                            options={options?.gradeLevels}
                            isSearchable isClearable
                            classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                            value={options?.gradeLevels?.find(op => op.value == formData.gradeLevel) || ""}
                            onChange={op => {
                                let updatedErrors = { ...errors }
                                delete updatedErrors['gradeLevel']
                                setErrors(updatedErrors)

                                const newValue = op?.value || ""
                                setFormData({ ...formData, gradeLevel: newValue })

                                validateField('gradeLevel', newValue)
                            }}
                            placeholder={translate("Choose your course grade level..")}
                        />
                        {
                            errors.gradeLevel &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.gradeLevel)}
                            </small>
                        }
                    </div>
                </div>
            }

            {
                formData.type == 'professional' &&
                <div className="flex gap-4 max-md:flex-col">
                    {/* pathway */}
                    <div className="flex flex-col w-full">
                        <label htmlFor="pathway" className="text-start">{formFields.pathway.text}<span className="text-red-500 text-sm"> *</span></label>
                        <CreatableSelect
                            id='pathway'
                            options={options?.pathways}
                            isSearchable isClearable
                            classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                            value={options?.pathways?.find(op => op.value == formData.pathway) || ""}
                            onChange={async selectedOption => {
                                let newValue = selectedOption?.value || ""

                                // check if new pathway
                                if (Boolean(newValue)) {
                                    const valueFound = options?.pathways?.find(op => op?.value == newValue)

                                    if (!Boolean(valueFound)) {
                                        await AxiosRequest.post(`${BACKEND_ROOT}/constants/newRecord/Pathway`, {
                                            name: newValue
                                        }).then(res => {
                                            const newOption = {
                                                value: res.data._id,
                                                label: res.data.name,
                                            }

                                            setOptions(old => {
                                                let updated = clone(old)

                                                updated.pathways.push(newOption)

                                                return updated
                                            })

                                            newValue = newOption.value
                                        }).catch(err => {
                                            devConsoleLog(`Failed to create new audience category: ${err.message}`)
                                        })
                                    }
                                }

                                setFormData({ ...formData, pathway: newValue })

                                validateField('pathway', newValue)
                            }}
                            placeholder={translate("Choose your course pathway..")}
                        />
                        {
                            errors.pathway &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.pathway)}
                            </small>
                        }
                    </div>

                    {/* order */}
                    <div className="flex flex-col w-full">
                        <label htmlFor="order" className="text-start">{formFields.order.text}<span className="text-red-500 text-sm"> *</span></label>
                        <input
                            id="order"
                            type="number"
                            className="input-bar"
                            placeholder={translate("Add order for your course")}
                            value={formData.order || ""}
                            onChange={handleChange}
                        />
                        {
                            errors.order &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.order)}
                            </small>
                        }
                    </div>
                </div>
            }

            {/* navigation button */}
            <div className='flex items-center justify-end'>
                <button type="submit" className='submit-btn' disabled={validating || Object.values(errors).some(Boolean)}>
                    <span className='max-md:hidden'>{translate("Forward to Outcomes")}</span>
                    {validating ? <AiOutlineLoading3Quarters className="animate-spin" /> : <FaArrowRight />}
                </button>
            </div>

            {
                Object.values(errors).some(Boolean) &&
                <small className='text-red-700 flex flex-col gap-y-1'>
                    {
                        Object.entries(errors).map(([key, value]) => (
                            <span key={key} className="flex items-center gap-x-1">
                                <MdErrorOutline />
                                {translate(formatKeyText(key))}: {value}
                            </span>
                        ))
                    }
                </small>
            }
        </form>
    )
}

export default General