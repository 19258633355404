"use client"
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
// import Select from 'react-select';
// import { useSelector } from 'react-redux';
import CustomBootstrap from '@/styles/CustomBootstrap.module.css';
// import CustomModal from './CustomModal';
import { useTranslate } from '@/client/hooks';
import Swal from 'sweetalert2';
// import { getUser } from '@/client/store/reducers/userReducer';
import { BACKEND_ROOT } from '@/constants';
import axios from 'axios';
// import { RRule, RRuleSet, rrulestr } from 'rrule';
// import { useParams } from 'next/navigation';
// import DatePicker from 'react-date-picker';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
// import DateTimePicker from 'react-datetime-picker';
// import 'react-datetime-picker/dist/DateTimePicker.css';
import { border_color, day_filter, get_ui_Hour } from './CalenderComponent';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { devConsoleLog } from "@/client/utils";

function limitString(str) {
    return str.length > 100 ? str.substring(0, 100) : str;
}

const CalenderSingleDayComponent = (props) => {
    // const myRef = useRef(null);
    // const [width_CalenderSingleDayComponent, setWidth_CalenderSingleDayComponent] = useState(0);
    // const [height_CalenderSingleDayComponent, setHeight_CalenderSingleDayComponent] = useState(0);

    // useLayoutEffect(() => {
    //     if (myRef.current) {
    //         devConsoleLog("myRef.current.offsetWidth");
    //         devConsoleLog(myRef.current.offsetWidth);
    //         setWidth_CalenderSingleDayComponent(myRef.current.offsetWidth);
    //         setHeight_CalenderSingleDayComponent(myRef.current.offsetHeight);
    //     }
    // }, []);

    // for translation
    const translate = useTranslate();
    const capitalizeText = (str) => {
        if (!(str?.trim()?.length ?? 0)) {
            return "";
        }
        const text = translate(str);
        if (!(text?.trim()?.length ?? 0)) {
            return "";
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
    const header_fn = props?.header_fn ?? (() => { });
    const currentlyViewingDate = props?.currentlyViewingDate;
    const calenderEvents = props?.calenderEvents ?? [];
    const isShowing24hoursPerDay = props?.isShowing24hoursPerDay ?? true;
    const courseId = (props?.courseId) ?? null;
    const width_CalenderSingleWeekComponent = (props?.width_CalenderSingleWeekComponent) ?? false;
    // const height_CalenderSingleWeekComponent = (props?.height_CalenderSingleWeekComponent) ?? false;
    // const inWeek = (props?.inWeek) ?? false;
    // const written_date = ` ${capitalizeText(currentlyViewingDate.toLocaleDateString('en-EG', { weekday: 'long' }))} ${currentlyViewingDate?.getDate()} / ${currentlyViewingDate?.getMonth() + 1} / ${currentlyViewingDate?.getFullYear()} `;
    const main_style = {
        border: `1px solid ${border_color}`,
        // borderRadius: "10px",
        overflow: "hidden",
    }
    if (width_CalenderSingleWeekComponent) {
        main_style.width = `${width_CalenderSingleWeekComponent}px`;
    }
    // if (height_CalenderSingleWeekComponent) {
    //     main_style.minHeight = `${height_CalenderSingleWeekComponent}px`;
    // }
    return (
        <div
            // ref={myRef}
            className={`        ${CustomBootstrap["text-center"] ?? ""}      ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["flex-column"] ?? ""}      ${CustomBootstrap["align-items-center"] ?? ""}      `}
            style={main_style}
        >
            <div
                className={`     ${CustomBootstrap["btn-secondary"] ?? ""}  ${CustomBootstrap["btn"] ?? ""}   `}
                onClick={header_fn}
                style={{
                    border: `1px solid ${border_color}`,
                    cursor: "context-menu",
                    borderRadius: "0",
                    width: "100%",
                    fontSize: "17px",
                    fontWeight: "bold",
                }}
            >
                <div>{capitalizeText(currentlyViewingDate.toLocaleDateString('en-EG', { weekday: 'long' }))}</div>
                <div>{`${currentlyViewingDate?.getDate()} / ${currentlyViewingDate?.getMonth() + 1} / ${currentlyViewingDate?.getFullYear()} `}</div>
            </div>
            <div
                className={`        ${CustomBootstrap["text-center"] ?? ""}      ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["flex-column"] ?? ""}      ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}      ${CustomBootstrap["p-2"] ?? ""}   `}
                style={{
                    width: "100%",
                    gap: "10px",
                }}
            >
                {
                    (() => {
                        const this_day = new Date(currentlyViewingDate);
                        // Set the time to 23:59:59.999 to represent the end of this day
                        const calenderEvents_filtered = calenderEvents?.filter((calenderEvent) => {
                            return day_filter(calenderEvent, this_day, courseId);
                        }) ?? [];
                        // devConsoleLog("calenderEvents_filtered");
                        // devConsoleLog(calenderEvents_filtered);

                        if (!(calenderEvents_filtered?.length ?? 0)) {
                            return (

                                <div
                                // className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                >
                                    {`${capitalizeText("No events in this day")}`}
                                </div>
                            );
                        }

                        // conflict ? red : green
                        const depend_on_conflict = ((calenderEvents_filtered?.length ?? 0) - 1) ? "danger" : "success";
                        const class_depend_on_conflict = `btn-outline-${depend_on_conflict}`;
                        const calenderEvents_filtered_allDay = calenderEvents_filtered?.filter((calenderEvent) => (calenderEvent?.allDay ?? false)) ?? [];
                        const calenderEvents_filtered_timed = calenderEvents_filtered?.filter((calenderEvent) => !(calenderEvent?.allDay ?? false)) ?? [];
                        calenderEvents_filtered_timed.sort((b, a) => new Date(b.startDate) - new Date(a.startDate));
                        return [...calenderEvents_filtered_allDay, ...calenderEvents_filtered_timed,]?.map((calenderEvent, calenderEvent_i) => {
                            let written_date = "All Day";
                            const tmp_allDay = calenderEvent?.allDay ?? true;
                            const isoString = calenderEvent?.startDate ?? false;
                            let startDate_dateObject = null;
                            if (!(tmp_allDay)) {
                                if (isoString) {
                                    startDate_dateObject = new Date(isoString);
                                    // // devConsoleLog(startDate_dateObject.getFullYear()); // 2024
                                    // // devConsoleLog(startDate_dateObject.getMonth() + 1); // 9 (Months are zero-indexed, so add 1)
                                    // // devConsoleLog(startDate_dateObject.getDate()); // 8
                                    // // devConsoleLog(startDate_dateObject.getHours()); // 20 (Local time)
                                    // // devConsoleLog(startDate_dateObject.getMinutes()); // 2
                                    // // devConsoleLog(startDate_dateObject.getSeconds()); // 57
                                    const startDate_dateObject_Hours = startDate_dateObject.getHours();
                                    const startDate_dateObject_Minutes = startDate_dateObject.getMinutes();
                                    written_date = get_ui_Hour(startDate_dateObject_Hours, isShowing24hoursPerDay, startDate_dateObject_Minutes);
                                }
                            }
                            const tmp_id = calenderEvent?._id ?? false;
                            const tmp_title = calenderEvent?.title ?? "";
                            const tmp_notes = calenderEvent?.notes ?? "";
                            const tmp_url = calenderEvent?.url ?? "";
                            const tmp_endTime = calenderEvent?.endTime ?? false;
                            const tmp_rRule = calenderEvent?.rRule ?? false;
                            return (
                                <div
                                    key={tmp_id ? tmp_id : `${calenderEvent_i} - ${tmp_title}`}
                                    className={`  ${CustomBootstrap["pt-2"] ?? ""}   ${CustomBootstrap["btn"] ?? ""}  ${CustomBootstrap[class_depend_on_conflict] ?? ""}  `}
                                    style={{
                                        width: "100%",
                                        fontSize: "17px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {/* Title */}
                                    <div
                                        className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                    >
                                        {`${capitalizeText("Title")} : ${tmp_title}`}
                                    </div>

                                    {/* Start Time */}
                                    <div
                                        className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                    >
                                        {tmp_allDay ? written_date : `${capitalizeText("Start Time")} : ${written_date ?? ""}`}
                                    </div>





                                    {/* End Time */}
                                    {
                                        (tmp_allDay) ?
                                            null
                                            :
                                            <>
                                                {
                                                    (() => {
                                                        if (tmp_endTime) {
                                                            const [endTime_hours, endTime_minutes] = tmp_endTime.split(':');
                                                            const end_ui = get_ui_Hour(endTime_hours, isShowing24hoursPerDay, endTime_minutes);
                                                            return (
                                                                <div
                                                                    className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                                                >
                                                                    {`${capitalizeText("End Time")} : ${end_ui ?? ""}`}
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    })()
                                                }

                                            </>
                                    }
                                    {/* notes */}
                                    {
                                        ((tmp_notes)?.trim()?.length ?? 0) ?
                                            <>
                                                <div>
                                                    {`${capitalizeText("Notes")}`}
                                                </div>
                                                <Tooltip
                                                    title={tmp_notes}
                                                    placement="top"
                                                    arrow
                                                    TransitionComponent={Zoom}
                                                    describeChild
                                                >
                                                    <div
                                                        style={{
                                                            wordWrap: "break-word",
                                                            overflow: "hidden",
                                                        }}
                                                        className={`  ${CustomBootstrap["mb-3"] ?? ""} `}
                                                    >
                                                        {limitString(tmp_notes)}
                                                    </div>
                                                </Tooltip>
                                            </>
                                            :
                                            null
                                    }
                                    {/* url */}
                                    {
                                        ((tmp_url)?.trim()?.length ?? 0) ?
                                            <Tooltip
                                                title={tmp_url}
                                                placement="top"
                                                arrow
                                                TransitionComponent={Zoom}
                                                describeChild
                                            >
                                                <div
                                                    style={{
                                                        wordWrap: "break-word",
                                                        overflow: "hidden",
                                                    }}
                                                    className={`  ${CustomBootstrap["href_blue_only_when_hover"] ?? ""}  ${CustomBootstrap["mb-3"] ?? ""} `}
                                                >
                                                    <a href={tmp_url}>{`${capitalizeText("LINK")}`}</a>
                                                </div>
                                            </Tooltip>
                                            :
                                            null
                                    }
                                    {/* not used in lwe -------------------------------------------- */}
                                    {/* Event Type */}


                                    {/* btns */}
                                    <div
                                        className={` ${width_CalenderSingleWeekComponent ? "" : (CustomBootstrap["row"] ?? "")}   `}
                                    >
                                        <div
                                            className={` ${CustomBootstrap["my-3"] ?? ""} ${width_CalenderSingleWeekComponent ? "" : (CustomBootstrap["col-6"] ?? "")}   ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}    `}
                                        >
                                            <button
                                                className={`  ${CustomBootstrap["btn"] ?? ""}   ${CustomBootstrap["btn-primary"] ?? ""}     `}
                                                style={{
                                                    fontSize: "17px",
                                                    fontWeight: "bold",
                                                    border: "1px solid white",
                                                    // textAlign: "start",
                                                    // padding: 0,
                                                }}
                                                disabled={!tmp_id}
                                                onClick={() => {
                                                    if (!tmp_id) {
                                                        return;
                                                    }
                                                    openModal({ mode: 'open' });
                                                    setModalUsage(modalUsage_edit_event);
                                                    // set data 
                                                    setSelected_id(tmp_id);
                                                    setAllDay(tmp_allDay);

                                                    // this is date obj
                                                    setStartDate(startDate_dateObject ?? currentlyViewingDate_default);

                                                    // HH:MM
                                                    setEndTime(tmp_endTime);

                                                    setTitle(tmp_title);
                                                    setNotes(tmp_notes);

                                                    if (tmp_rRule) {

                                                        const tmp_rRuleDecoded = rRuleDecode(tmp_rRule);

                                                        let tmp_property = '';
                                                        if (tmp_rRuleDecoded) {
                                                            setIsRepeatable(true);
                                                            tmp_property = 'INTERVAL';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setINTERVAL(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'FREQ';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setFREQ(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'BYMONTH';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setBYMONTH(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'BYMONTHDAY';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setBYMONTHDAY(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'COUNT';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setCOUNT(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'UNTIL';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setUNTIL(tmp_rRuleDecoded[tmp_property]);
                                                            }

                                                            // =====================================================

                                                            tmp_property = 'SA';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setSA(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'SU';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setSU(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'MO';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setMO(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'TU';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setTU(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'WE';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setWE(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'TH';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setTH(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'FR';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setFR(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'BYDAY_order';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setBYDAY_order(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'BYDAY_name';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setBYDAY_name(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'EndRepeatRadio';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setEndRepeatRadio(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'MonthlyRepeatRadio';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setMonthlyRepeatRadio(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                            tmp_property = 'YearlyRepeatRadio';
                                                            if (tmp_rRuleDecoded?.hasOwnProperty(tmp_property)) {
                                                                setYearlyRepeatRadio(tmp_rRuleDecoded[tmp_property]);
                                                            }
                                                        }
                                                    }
                                                    // // not used in lwe --------------------------------------------
                                                    // setEventType(eventType_default);
                                                }}
                                            >
                                                {capitalizeText("Edit")}
                                            </button>
                                        </div>
                                        <div
                                            className={`  ${CustomBootstrap["my-3"] ?? ""}  ${width_CalenderSingleWeekComponent ? "" : (CustomBootstrap["col-6"] ?? "")}   ${CustomBootstrap["d-flex"] ?? ""}   ${CustomBootstrap["justify-content-center"] ?? ""}  ${CustomBootstrap["align-items-center"] ?? ""}    `}
                                        >
                                            <button
                                                className={`  ${CustomBootstrap["btn"] ?? ""}   ${CustomBootstrap["btn-danger"] ?? ""}     `}
                                                style={{
                                                    fontSize: "17px",
                                                    fontWeight: "bold",
                                                    border: "1px solid white",
                                                    // textAlign: "start",
                                                    // padding: 0,
                                                }}
                                                disabled={!tmp_id}
                                                onClick={() => {
                                                    if (!tmp_id) {
                                                        return;
                                                    }
                                                    Swal.fire({
                                                        icon: "question",
                                                        title: capitalizeText("Are you sure you want to delete this event?"),
                                                        showConfirmButton: true,
                                                        confirmButtonText: capitalizeText("Delete"),
                                                        showCancelButton: true,
                                                        cancelButtonText: capitalizeText("Cancel"),
                                                        showDenyButton: false,
                                                        showCloseButton: false,
                                                        // showLoaderOnDeny: true
                                                    })?.then((result) => {
                                                        if (result?.value ?? false) {

                                                            swal_loading();

                                                            try {
                                                                axios?.delete(`${BACKEND_ROOT}/calender/events/${tmp_id}`)?.then((res) => {
                                                                    // // devConsoleLog("res");
                                                                    // // devConsoleLog(res);
                                                                    const res_data = res?.data ?? false;
                                                                    if (res_data?.ok ?? false) {
                                                                        Swal.fire({
                                                                            icon: "success",
                                                                            title: capitalizeText("Deleted successfully!"),
                                                                            showConfirmButton: false,
                                                                            showDenyButton: false,
                                                                            cancelButtonText: capitalizeText("Ok"),
                                                                            showCloseButton: false,
                                                                            showCancelButton: true,
                                                                            // showLoaderOnDeny: true
                                                                        });
                                                                        const arr = [...calenderEvents]
                                                                        const index = arr.findIndex(obj => obj._id === (tmp_id));

                                                                        if (index !== -1) {
                                                                            arr.splice(index, 1);
                                                                        }

                                                                        setCalenderEvents(arr);
                                                                    }
                                                                })?.catch((err) => {
                                                                    // devConsoleLog("err");
                                                                    // devConsoleLog(err.message || err.code);
                                                                    // Swal.close();
                                                                    Swal.fire({
                                                                        icon: "error",
                                                                        title: capitalizeText("Failed!"),
                                                                        showConfirmButton: false,
                                                                        showDenyButton: false,
                                                                        cancelButtonText: capitalizeText("Ok"),
                                                                        showCloseButton: false,
                                                                        showCancelButton: true,
                                                                        // showLoaderOnDeny: true
                                                                    });
                                                                });
                                                            } catch (err) {
                                                                // devConsoleLog("err");
                                                                // devConsoleLog(err.message || err.code);
                                                                // Swal.close();
                                                                Swal.fire({
                                                                    icon: "error",
                                                                    title: capitalizeText("Failed!"),
                                                                    showConfirmButton: false,
                                                                    showDenyButton: false,
                                                                    cancelButtonText: capitalizeText("Ok"),
                                                                    showCloseButton: false,
                                                                    showCancelButton: true,
                                                                    // showLoaderOnDeny: true
                                                                });
                                                            }
                                                        }
                                                    });
                                                }}
                                            >
                                                {capitalizeText("Delete")}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            );
                        })
                    })()
                }
            </div>

        </div>
    )
}

export default CalenderSingleDayComponent