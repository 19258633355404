"use client"

import { BACKEND_ROOT } from "@/constants"
import CustomModal from "./CustomModal"
import { AxiosRequest, formatNumber } from "@/client/utils";
import { MdLocalGroceryStore } from "@react-icons/all-files/md/MdLocalGroceryStore";
import { Fragment, useRef, useEffect, useState } from "react"
import { useTranslate } from "@/client/hooks";
import { FaDollarSign } from "@react-icons/all-files/fa/FaDollarSign";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { GiTwoCoins } from "@react-icons/all-files/gi/GiTwoCoins";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatistics, setUserStatistics } from "@/client/store/reducers/userReducer";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { clone } from "lodash";
import { BsQuestionOctagon } from '@react-icons/all-files/bs/BsQuestionOctagon';
import { BsExclamationOctagon } from '@react-icons/all-files/bs/BsExclamationOctagon';


const CoinsStore = () => {
    const translate = useTranslate()

    const [modal, setModal] = useState({ open: false })
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })


    const AvailableItems = () => {
        const coinsRef = useRef(useSelector(getUserStatistics).coins)
        const dispatch = useDispatch()

        const [categories, setCategories] = useState(null)

        const [loading, setLoading] = useState(false)
        const [confirmModal, setConfirmModal] = useState({ open: false })
        const openConfirmModal = (params) => setConfirmModal({ open: true, ...params })
        const closeConfirmModal = () => setConfirmModal({ open: false })


        const handlePurchaseItem = async () => {
            const { item, itemIdx, catIdx } = confirmModal

            setLoading(true)

            await AxiosRequest.post(`${BACKEND_ROOT}/coins-store/purchaseItem`, { itemId: item._id }).then(res => {
                // check for server validation errors
                if (res.data.errors) return setConfirmModal({ ...confirmModal, errors: res.data.errors });

                const { userCoins, itemAvailable } = res.data

                // update current coinsRef
                coinsRef.current = userCoins

                // update store
                let updated = clone(categories)
                if (itemAvailable == 0) {
                    updated[catIdx].items.splice(itemIdx, 1)
                } else {
                    updated[catIdx].items[itemIdx].available = itemAvailable
                }

                setCategories(updated)

                // finally close confirm modal
                closeConfirmModal()
            }).catch(err => {
                console.info(err.message)
            })

            setLoading(false)
        }


        useEffect(() => {
            if (modal.open && !categories) {
                const controller = new AbortController();

                AxiosRequest.get(`${BACKEND_ROOT}/coins-store/listAvailableItems`, {
                    signal: controller.signal,
                }).then(res => {
                    setCategories([
                        {
                            name: 'all',
                            description: 'all available items uncategorized',
                            items: res.data.flatMap(category => category.items),
                        },
                        ...res.data,
                    ])
                }).catch(err => {
                    console.info(err.message)
                })

                return () => {
                    controller.abort();
                }
            }
        }, [modal.open, categories])


        useEffect(()=>{
            return () => {
                dispatch(setUserStatistics({ coins: coinsRef.current }))
            }
        }, [])


        return (
            <Fragment>
                <CustomModal open={confirmModal.open} onClose={closeConfirmModal} className={`max-w-xl`}>
                    <div className="p-8 max-md:px-4 flex flex-col gap-y-4 items-center">
                        {
                            loading ? <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-1/6 max-w-[100px] mx-auto h-fit" />
                            : confirmModal.errors ?
                            <Fragment>
                                <BsExclamationOctagon className="text-red-600" fontSize={100} />

                                {Object.values(confirmModal.errors).map((errMsg, idx) => <h1 key={idx} className="capitalize text-2xl font-semibold text-red-600">{errMsg}</h1>)}

                                <button className="submit-btn max-w-xs w-full" onClick={closeConfirmModal}>
                                    {translate(`ok`)}
                                </button>
                            </Fragment>
                            :
                            <Fragment>
                                <BsQuestionOctagon className="text-blue-400" fontSize={100} />

                                <h1 className="capitalize text-2xl font-semibold text-gray-500">{translate(`please, confirm your purchase!`)}</h1>

                                <button className="submit-btn max-w-xs w-full" onClick={handlePurchaseItem}>
                                    {translate(`confirm purchase`)}
                                </button>
                            </Fragment>
                        }
                    </div>
                </CustomModal>

                <div className="flex gap-x-1 items-center justify-end text-amber-400">
                    <GiTwoCoins fontSize={24} />
                    <span className="capitalize text-lg font-semibold">{formatNumber(coinsRef.current)}</span>
                </div>


                {
                    !categories ? <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-1/6 max-w-[100px] mx-auto h-fit" />
                    :
                    <TabGroup className="flex gap-x-4 flex-grow" vertical>
                        <TabList className={`flex flex-col gap-y-4 border-r-2 border-gray-300 min-w-fit`}>
                            {
                                categories.map(({ name }, catIdx) => {
                                    return <Tab key={catIdx} className="store-cat-btn">{name}</Tab>
                                })
                            }
                        </TabList>

                        <TabPanels as={Fragment}>
                            {
                                categories.map(({ items }, catIdx) => {
                                    return (
                                        <TabPanel key={catIdx} className="flex-grow flex flex-wrap gap-4">
                                            {
                                                items.map((item, itemIdx) => {

                                                    return (
                                                        <div key={itemIdx} className="store-item-card">
                                                            <h1 className="capitalize text-xl text-blue-400 font-semibold">{item.name}</h1>
                                                            <img src={item.image} className="rounded-full w-[100px] h-[100px] object-cover border-2 border-blue-400" />
                                                            <span className="capitalize text-gray-500 text-sm line-clamp-3">{item.description}</span>

                                                            <div className="flex items-center gap-x-2 font-semibold">
                                                                <span className="capitalize text-gray-500">{translate('available')}:</span>
                                                                <span className={`capitalize ${item.available > 0 ? "text-green-600" : "text-red-600"}`}>{item.available}</span>
                                                            </div>

                                                            <div className="flex items-center gap-x-1 text-amber-400">
                                                                <span className="font-semibold text-lg">{formatNumber(item.price)}</span>
                                                                <GiTwoCoins fontSize={24} />
                                                            </div>

                                                            <button className="submit-btn-outline w-full" onClick={() => openConfirmModal({ item, itemIdx, catIdx })}>
                                                                <FaDollarSign fontSize={20} />
                                                                <span>{translate(`purchase`)}</span>
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </TabPanel>
                                    )
                                })
                            }
                        </TabPanels>
                    </TabGroup>
                }
            </Fragment>
        )
    }


    const Warehouse = () => {
        const [userWarehouse, setUserWarehouse] = useState(null)


        useEffect(() => {
            if (modal.open && !userWarehouse) {
                const controller = new AbortController();

                AxiosRequest.get(`${BACKEND_ROOT}/coins-store/getOrCreateUserWarehouse`, {
                    signal: controller.signal,
                }).then(res => {
                    setUserWarehouse(res.data)
                }).catch(err => {
                    console.info(err.message)
                })

                return () => {
                    controller.abort();
                }
            }
        }, [modal.open, userWarehouse])


        return !userWarehouse ? <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-1/6 max-w-[100px] mx-auto h-fit" />
        : userWarehouse.items.length == 0 ? <span className="capitalize text-gray-400 italic w-full text-center text-lg">{translate("your warehouse is empty for the current time!")}</span>
        : <div className="flex flex-wrap gap-4">
            {
                userWarehouse.items.map((item, idx) => {

                    return (
                        <div key={idx} className="store-item-card">
                            <h1 className="capitalize text-xl text-blue-400 font-semibold">{item.storeItem.name}</h1>
                            <img src={item.storeItem.image} className="rounded-full w-[100px] h-[100px] object-cover border-2 border-blue-400" />
                            <span className="capitalize text-gray-500 text-sm line-clamp-3">{item.storeItem.description}</span>

                            <div className="flex items-center gap-x-2 font-semibold">
                                <span className="capitalize text-gray-500">{translate('count')}:</span>
                                <span className={`capitalize text-green-600`}>{item.count}</span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    }


    return (
        <Fragment>
            <button onClick={() => openModal()}>
                <MdLocalGroceryStore fontSize={20} />
            </button>

            <CustomModal open={modal.open} onClose={closeModal} className={`w-full min-h-[90vh]`}>
                <TabGroup className="p-8 max-md:px-4 flex-grow flex flex-col">
                    <TabList className={`flex justify-between overflow-x-auto small-scroll`}>
                        <Tab className={`tab-btn text-xl font-semibold`}>{translate(`coins store`)}</Tab>

                        <Tab className={`tab-btn text-xl font-semibold`}>{translate(`warehouse`)}</Tab>
                    </TabList>

                    <TabPanels as={Fragment}>
                        <TabPanel className="flex flex-col py-4 relative flex-grow">
                            <AvailableItems />
                        </TabPanel>

                        <TabPanel className="flex flex-col gap-y-4 py-4">
                            <Warehouse />
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            </CustomModal>
        </Fragment>
    )
}

export default CoinsStore