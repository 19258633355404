"use client"

import { useMemo } from "react"
import { useTranslate } from "@/client/hooks"


const Heatmap = ({ legend, labels, levels, actions = [] }) => {
    const translate = useTranslate()

    const dynamic = useMemo(() => {
        const colsCount = labels.length + 1
        const width = `${colsCount * 80}px`
        const gridCols = `grid-cols-${colsCount}`

        return { colsCount, width, gridCols }
    }, [labels, levels])


    const legends = {
        performance: [
            { name: "Not Tested, yet!", bgColor: 'bg-gray-400/80' },
            { name: "Needs Improvement", bgColor: 'bg-red-400/80' },
            { name: "Basic Level", bgColor: 'bg-yellow-400/80' },
            { name: "Developing Level", bgColor: 'bg-orange-400/80' },
            { name: "Proficient Level", bgColor: 'bg-sky-400/80' },
            { name: "Partial Mastery Level", bgColor: 'bg-blue-400' },
            { name: "Near Mastery Level", bgColor: 'bg-green-400/80' },
            { name: "Mastery Level", bgColor: 'bg-emerald-500' },
        ],
        cognitive: [
            { name: "Not Tested, yet!", bgColor: 'bg-gray-400/80' },
            { name: "Needs Improvement", bgColor: 'bg-red-400/80' },
            { name: "Remember", bgColor: 'bg-yellow-400/80' },
            { name: "Understand", bgColor: 'bg-orange-400/80' },
            { name: "Apply", bgColor: 'bg-sky-400/80' },
            { name: "Analyze", bgColor: 'bg-blue-400' },
            { name: "Evaluate", bgColor: 'bg-green-400/80' },
            { name: "Create", bgColor: 'bg-emerald-500' },
        ]
    }

    
    return (
        <section className={`flex flex-col gap-y-2 w-full max-w-fit`}>
            {/* legend */}
            <div className="flex flex-wrap gap-2">
                {
                    legends[legend].map((level, idx) => {

                        return (
                            <p key={idx} className={`${level.bgColor} px-2 py-1 text-gray-800 italic text-sm truncate rounded`}>
                                {translate(level.name)}
                            </p>
                        )
                    })
                }
            </div>

            {/* chart */}
            <div
                style={{ 'gridTemplateColumns': `repeat(${dynamic.colsCount}, minmax(0, 1fr))`, minWidth: dynamic.width }}
                className={`grid w-fit divide-x divide-y divide-gray-300 border-b border-r border-gray-300`}
            >
                <span className="text-xs p-2 min-w-[80px] h-[80px] flex items-center border-l border-t border-gray-300">{translate(`Learning Outcome`)}</span>
                {
                    labels.map((label, idx) => {

                        return <button onClick={actions[idx]} key={idx} className="p-2 min-w-[80px] h-[80px] text-start text-xs overflow-y-auto small-scroll">{label}</button>
                    })
                }


                <span className="text-xs p-2 min-w-[80px] h-[80px] flex items-center capitalize">{translate(legend)}</span>
                {
                    levels.map((level, idx) => {
                        const bgColor = legends[legend][level].bgColor
                        return <button onClick={actions[idx]} key={idx} className={`p-2 min-w-[80px] h-[80px] ${bgColor}`} />
                    })
                }

            </div>
        </section>
    )
}

export default Heatmap