"use client"

import { useTranslate } from "@/client/hooks"
import { Fragment, useEffect, useMemo, useState } from "react"
import { AxiosRequest, devConsoleLog } from "@/client/utils"
import { BACKEND_ROOT } from "@/constants"
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import { Reorder, motion } from "framer-motion"
import { clone, isEqual } from "lodash"
import { v4 as uuidv4 } from 'uuid';
import DraggableItem from "./DraggableItem"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import EnhanceButton from "./EnhanceButton"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"


const Outcomes = ({ options, formData, setFormData, setLastStep }) => {
    const translate = useTranslate()

    const [errors, setErrors] = useState({})
    const [expand, setExpand] = useState({})

    const toggleExpand = (loTempId) => {
        setExpand(prev => {
            let next = clone(prev)

            next[loTempId] = !Boolean(next[loTempId])

            return next
        })
    }

    const formFields = useMemo(() => {
        const losFields = formData.learningOutcomes?.reduce((acc, lo, loIdx) => {
            acc[`lo-${loIdx}-statement`] = {
                invalid: (value) => !Boolean(value),
                error: "Statement field is required"
            }
            acc[`lo-${loIdx}-scope`] = {
                invalid: (value) => !Boolean(value),
                error: "Scope field is required"
            }
            acc[`lo-${loIdx}-domain`] = {
                invalid: (value) => !Boolean(value),
                error: "Domain field is required"
            }
            acc[`lo-${loIdx}-performanceCriteria`] = {
                invalid: (value) => !Array.isArray(value) || value.length == 0,
                error: "You have to add 1 criteria at least!"
            }

            lo.performanceCriteria?.forEach((pc, cIdx) => {
                acc[`lo-${loIdx}-pc-${cIdx}-description`] = {
                    invalid: (value) => !Boolean(value),
                    error: "Description field is required"
                }
            })

            return acc
        }, {})

        return {
            learningOutcomes: {
                text: translate("Course Learning Outcomes"),
                invalid: (value) => !Array.isArray(value) || value.length == 0,
                error: "You have to add 1 learning outcome at least!"
            },
            ...losFields
        }
    }, [formData.learningOutcomes?.length])

    const validateField = (fieldName, fieldValue) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[fieldName]

        const isInvalid = formFields[fieldName].invalid(fieldValue)

        if (isInvalid) {
            updatedErrors[fieldName] = formFields[fieldName].error
        }

        setErrors(updatedErrors)

        return isInvalid
    }

    const addNewLO = () => {
        let updatedErrors = { ...errors }
        delete updatedErrors.learningOutcomes

        let updatedFormData = { ...formData }

        const newLO = { tempId: uuidv4(), statement: "", scope: "", domain: "", performanceCriteria: [] }

        if (updatedFormData.hasOwnProperty('learningOutcomes')) {
            updatedFormData.learningOutcomes.push(newLO)
        } else {
            updatedFormData.learningOutcomes = [newLO]
        }

        setFormData(updatedFormData)

        const loIdx = updatedFormData.learningOutcomes.length - 1
        updatedErrors[`lo-${loIdx}-statement`] = "Statement field is required"
        updatedErrors[`lo-${loIdx}-scope`] = "Scope field is required"
        updatedErrors[`lo-${loIdx}-domain`] = "Domain field is required"
        updatedErrors[`lo-${loIdx}-performanceCriteria`] = "You have to add 1 criteria at least!"

        setErrors(updatedErrors)
    }

    const handleLOFieldChange = (e, loIdx) => {
        const fieldId = e.target.id
        const fieldName = fieldId.split(`lo-${loIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        validateField(fieldId, fieldValue)
    }

    const deleteLO = (loIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes.splice(loIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`lo-${loIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`lo-`)) {
                let keyTokens = key.split('lo-')[1].split('-')
                let kloIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kloIdx > loIdx) {
                    updatedErrors[`lo-${kloIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.learningOutcomes.length == 0) {
            updatedErrors[`learningOutcomes`] = "You have to add 1 learning outcome at least!"
        }

        setErrors(updatedErrors)
    }

    const handleLOCriteriaChange = (e, loIdx, cIdx) => {
        const fieldId = e.target.id
        const fieldName = fieldId.split(`lo-${loIdx}-pc-${cIdx}-`)[1]
        const fieldValue = e.target.value

        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx].performanceCriteria[cIdx][fieldName] = fieldValue
        setFormData(updatedFormData)

        validateField(fieldId, fieldValue)
    }

    const addNewLOCriteria = (loIdx) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[`lo-${loIdx}-performanceCriteria`]

        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx].performanceCriteria.push({ tempId: uuidv4(), description: "" })
        setFormData(updatedFormData)

        updatedErrors[`lo-${loIdx}-pc-${updatedFormData.learningOutcomes[loIdx].performanceCriteria.length - 1}-description`] = "Description field is required"
        setErrors(updatedErrors)
    }

    const deleteLOCriteria = (loIdx, cIdx) => {
        let updatedFormData = { ...formData }
        updatedFormData.learningOutcomes[loIdx].performanceCriteria.splice(cIdx, 1)
        setFormData(updatedFormData)

        let updatedErrors = { ...errors }
        Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`lo-${loIdx}-pc-${cIdx}`)) {
                delete updatedErrors[key]
            } else if (key.includes(`lo-${loIdx}-pc-`)) {
                let keyTokens = key.split(`lo-${loIdx}-pc-`)[1].split('-')
                let kcIdx = +keyTokens[0]
                let rest = keyTokens.slice(1).join('-')

                if (kcIdx > cIdx) {
                    updatedErrors[`lo-${loIdx}-pc-${kcIdx - 1}-${rest}`] = updatedErrors[key]
                    delete updatedErrors[key]
                }
            }
        })

        if (updatedFormData.learningOutcomes[loIdx].performanceCriteria.length == 0) {
            updatedErrors[`lo-${loIdx}-performanceCriteria`] = "You have to add 1 criteria at least!"
        }
        setErrors(updatedErrors)
    }

    const handleReorderOutcomes = (orderedLearningOutcomes) => {
        // Re-map errors to match the new order
        const reorderedErrors = {}

        orderedLearningOutcomes.forEach((outcome, newIndex) => {
            const oldIndex = formData.learningOutcomes.findIndex(lo => isEqual(lo, outcome));

            // Update all error keys related to the current outcome
            Object.keys(errors).forEach((key) => {
                if (key.includes(`lo-${oldIndex}`)) {
                    const updatedKey = key.replace(`lo-${oldIndex}`, `lo-${newIndex}`);
                    reorderedErrors[updatedKey] = errors[key];
                }
            })
        })

        setFormData(prev => ({
            ...prev,
            learningOutcomes: orderedLearningOutcomes
        }))

        setErrors(reorderedErrors);
    }

    const handleEnhanceLearningOutcome = async (userPrompt, loIdx) => {
        const generalCourseInfo = {
            type: formData.type,
            title: formData.title,
            keywords: formData.keywords,
            description: formData.description || "",
            audiences: formData.audiences.map(aud => {
                return {
                    category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
                    intendedLearners: aud.intendedLearners
                }
            }),
            gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
            educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
        }

        const reqPromise = await AxiosRequest.post(`${BACKEND_ROOT}/ai/enhanceLearningOutcome`, {
            generalCourseInfo,
            givenLearningOutcome: formData.learningOutcomes[loIdx],
            userPrompt,
        }).then(res => {
            const enhancedLearningOutcome = res.data

            setFormData(prevFormData => {
                let newFormData = clone(prevFormData)

                newFormData.learningOutcomes[loIdx] = enhancedLearningOutcome

                return newFormData
            })

            setErrors(prevErrors => {
                let newErrors = clone(prevErrors)

                delete newErrors.learningOutcomes

                Object.keys(newErrors).forEach(key => {
                    if (key.includes(`lo-${loIdx}`)) {
                        delete newErrors[key]
                    }
                })

                return newErrors
            })
        })

        return reqPromise
    }

    const handleReorderCriteria = (orderedPerformanceCriteria, loIdx) => {
        // Re-map errors to match the new order
        let reorderedErrors = {}

        orderedPerformanceCriteria.forEach((criteria, newIndex) => {
            const oldIndex = formData.learningOutcomes[loIdx].performanceCriteria.findIndex(pc => isEqual(pc, criteria));

            // Update all error keys related to the current criteria
            Object.keys(errors).forEach((key) => {
                if (key.includes(`lo-${loIdx}-pc-${oldIndex}`)) {
                    const updatedKey = key.replace(`pc-${oldIndex}`, `pc-${newIndex}`);
                    reorderedErrors[updatedKey] = errors[key];
                }
            })
        })

        let newFormData = clone(formData)
        newFormData.learningOutcomes[loIdx].performanceCriteria = orderedPerformanceCriteria

        setFormData(newFormData)
        setErrors(reorderedErrors)
    }

    const handleSubmit = e => {
        e.preventDefault()

        // check for invalidFields required fields
        let invalidFields = {};
        for (let key of Object.keys(formFields)) {
            const { invalid, error } = formFields[key]

            if (key.startsWith('lo-')) {
                let keyTokens = key.split('lo-')[1].split('-')
                let loIdx = +keyTokens[0]
                let loKey = keyTokens.slice(1).join('-')

                if(loKey.startsWith('pc-')){
                    let loKeyTokens = key.split('pc-')[1].split('-')
                    let pcIdx = +loKeyTokens[0]
                    let pcKey = loKeyTokens.slice(1).join('-')

                    if (invalid(formData.learningOutcomes[loIdx].performanceCriteria[pcIdx][pcKey])) {
                        invalidFields[key] = error
                    }
                } else {
                    if (invalid(formData.learningOutcomes[loIdx][loKey])) {
                        invalidFields[key] = error
                    }
                }
            } else {
                if (invalid(formData[key])) {
                    invalidFields[key] = error
                }
            }
        }
        if (Object.keys(invalidFields).length != 0) return setErrors(invalidFields);

        setLastStep(`modules`)
    }

    useEffect(() => {
        if (((!Boolean(formData.learningOutcomes) || formData?.learningOutcomes?.length == 0))) {
            const generalCourseInfo = {
                type: formData.type,
                title: formData.title,
                keywords: formData.keywords,
                description: formData.description || "intendedLearners",
                audiences: formData.audiences.map(aud => {
                    return {
                        category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
                        intendedLearners: aud.intendedLearners
                    }
                }),
                gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
                educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
            }

            const controller = new AbortController();

            AxiosRequest.post(`${BACKEND_ROOT}/ai/generateLearningOutcomes`, {
                generalCourseInfo,
            }, {
                signal: controller.signal,
            }).then(res => {
                const generatedlearningOutcomes = res.data.learningOutcomes

                setFormData(prevFormData => {
                    let newFormData = clone(prevFormData)

                    newFormData.learningOutcomes = generatedlearningOutcomes

                    return newFormData
                })

                setErrors(prevErrors => {
                    let newErrors = clone(prevErrors)

                    delete newErrors.learningOutcomes

                    Object.keys(newErrors).forEach(key => {
                        if (key.includes(`lo-`)) {
                            delete newErrors[key]
                        }
                    })

                    return newErrors
                })
            }).catch(err => {
                devConsoleLog(`Failed to generate learning outcomes: ${err.message}`)
            })

            return () => {
                controller.abort();
            }
        }

    }, [Boolean(formData.learningOutcomes), formData?.learningOutcomes?.length])


    const regenerateLearningOutcomes = async (userPrompt) => {
        const generalCourseInfo = {
            type: formData.type,
            title: formData.title,
            keywords: formData.keywords,
            description: formData.description || "intendedLearners",
            audiences: formData.audiences.map(aud => {
                return {
                    category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
                    intendedLearners: aud.intendedLearners
                }
            }),
            gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
            educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
        }

        const currentLearningOutcomes = clone(formData.learningOutcomes)
        currentLearningOutcomes.forEach(lo => {
            delete lo.modules
        })

        const reqPromise = await AxiosRequest.post(`${BACKEND_ROOT}/ai/generateLearningOutcomes`, {
            generalCourseInfo,
            currentLearningOutcomes,
            userPrompt,
        }).then(res => {
            const enhancedLearningOutcomes = res.data.learningOutcomes

            setFormData(prevFormData => {
                let newFormData = clone(prevFormData)

                newFormData.learningOutcomes = enhancedLearningOutcomes

                return newFormData
            })

            setErrors(prevErrors => {
                let newErrors = clone(prevErrors)

                delete newErrors.learningOutcomes

                Object.keys(newErrors).forEach(key => {
                    if (key.includes(`lo-`)) {
                        delete newErrors[key]
                    }
                })

                return newErrors
            })
        })

        return reqPromise
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 flex-grow">
            {/* header */}
            <div className="flex items-center justify-between gap-x-4 relative">
                <h1 className="text-3xl text-blue-500 font-bold">{formFields.learningOutcomes.text}</h1>
                <EnhanceButton show handleEnhance={regenerateLearningOutcomes} />
            </div>

            <div className="flex flex-col flex-grow w-full gap-y-2">
                {
                    ((!Boolean(formData.learningOutcomes) || formData?.learningOutcomes?.length == 0)) ?
                        <div className="flex-grow flex flex-col gap-y-4 items-center justify-center">
                            <AiOutlineLoading3Quarters className="animate-spin text-blue-500 text-7xl" />
                            <h2 className="text-gray-500 italic font-semibold">{translate("Please, Wait till your course learning outcomes is generated")}!</h2>
                        </div>
                        : <Fragment>
                            <Reorder.Group as={Fragment} axis="y" values={formData.learningOutcomes || []} onReorder={handleReorderOutcomes}>
                                {
                                    formData.learningOutcomes?.map((lo, loIdx) => (
                                        <DraggableItem key={lo.tempId} value={lo}>
                                            <motion.div
                                                initial={{ height: expand[lo.tempId] ? "auto" : 50 }}
                                                animate={{ height: expand[lo.tempId] ? "auto" : 50 }}
                                                transition={{ duration: 0.5, ease: "easeInOut" }}
                                                className="w-full flex flex-col gap-y-2 rounded border-dashed border border-gray-400 bg-gray-100 p-2 overflow-hidden"
                                            >
                                                {/* los header */}
                                                <div className="flex justify-between items-center relative">
                                                    <button type="button" className="flex items-center gap-x-1 truncate" onClick={() => toggleExpand(lo.tempId)}>
                                                        <h2 className="font-bold text-lg truncate">{translate("Learning Outcome")} {loIdx + 1}: {lo.statement || translate("No Title")}</h2>
                                                        <MdExpandMore className={`w-[26px] h-[26px] ${expand[lo.tempId] ? "-rotate-180" : "rotate-0"} duration-500 transition-all text-gray-500 hover:bg-gray-200 active:bg-gray-200 rounded-full`} />
                                                    </button>

                                                    <div className="flex items-center">
                                                        {/* magic button */}
                                                        <EnhanceButton
                                                            handleEnhance={userPrompt => handleEnhanceLearningOutcome(userPrompt, loIdx)}
                                                            show={expand[lo.tempId]}
                                                        />

                                                        {/* delete button */}
                                                        <button type="button" className="danger-icon-btn p-2" onClick={() => deleteLO(loIdx)}>
                                                            <MdDelete size={18} />
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* statement */}
                                                <div className="flex flex-col w-full">
                                                    <label htmlFor={`lo-${loIdx}-statement`} className="text-start">{translate(`Statement`)}<span className="text-red-500 text-sm"> *</span></label>
                                                    <textarea
                                                        value={lo.statement}
                                                        id={`lo-${loIdx}-statement`}
                                                        className="input-bar min-h-[100px]"
                                                        placeholder={translate("Add your learning outcome statement..")}
                                                        onChange={e => { handleLOFieldChange(e, loIdx) }}
                                                    />
                                                    {
                                                        errors[`lo-${loIdx}-statement`] &&
                                                        <small className='text-red-700 flex items-center gap-1'>
                                                            <MdErrorOutline /> {translate(errors[`lo-${loIdx}-statement`])}
                                                        </small>
                                                    }
                                                </div>

                                                {/* scope and domain */}
                                                <div className="flex gap-2 max-md:flex-col">
                                                    {/* scope */}
                                                    <div className="flex flex-col w-full">
                                                        <label htmlFor={`lo-${loIdx}-scope`} className="text-start">{translate(`Scope`)}<span className="text-red-500 text-sm"> *</span></label>
                                                        <input value={lo.scope} id={`lo-${loIdx}-scope`} type="text" className="input-bar" placeholder={translate("Add your learning outcome scope..")} onChange={e => { handleLOFieldChange(e, loIdx) }} />
                                                        {
                                                            errors[`lo-${loIdx}-scope`] &&
                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                <MdErrorOutline /> {translate(errors[`lo-${loIdx}-scope`])}
                                                            </small>
                                                        }
                                                    </div>

                                                    {/* domain */}
                                                    <div className="flex flex-col w-full">
                                                        <label htmlFor={`lo-${loIdx}-domain`} className="text-start">{translate(`Domain`)}<span className="text-red-500 text-sm"> *</span></label>
                                                        <input value={lo.domain} id={`lo-${loIdx}-domain`} type="text" className="input-bar" placeholder={translate("Add your learning outcome domain..")} onChange={e => { handleLOFieldChange(e, loIdx) }} />
                                                        {
                                                            errors[`lo-${loIdx}-domain`] &&
                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                <MdErrorOutline /> {translate(errors[`lo-${loIdx}-domain`])}
                                                            </small>
                                                        }
                                                    </div>
                                                </div>

                                                {/* Performance Criteria */}
                                                <div className="flex flex-col gap-y-2">
                                                    <h2 className="text-start font-bold text-lg">{translate(`Performance Criteria`)}<span className="text-red-500 text-sm"> *</span></h2>
                                                    <Reorder.Group as={Fragment} axis="y" values={lo.performanceCriteria} onReorder={orderedPerformanceCriteria => {
                                                        handleReorderCriteria(orderedPerformanceCriteria, loIdx)
                                                    }}>
                                                        {
                                                            lo.performanceCriteria?.map((pc, cIdx) => (
                                                                <DraggableItem key={pc.tempId} value={pc}>
                                                                    <div className="w-full flex flex-col">
                                                                        <div className="relative">
                                                                            <textarea
                                                                                id={`lo-${loIdx}-pc-${cIdx}-description`}
                                                                                className="input-bar min-h-fit"
                                                                                placeholder={translate("Add criteria description..")}
                                                                                value={pc.description || ""}
                                                                                onChange={(e) => { handleLOCriteriaChange(e, loIdx, cIdx) }}
                                                                            />

                                                                            <button type="button" className="danger-icon-btn absolute top-2 right-1" onClick={() => { deleteLOCriteria(loIdx, cIdx) }}>
                                                                                <MdDelete size={18} />
                                                                            </button>
                                                                        </div>

                                                                        {
                                                                            errors[`lo-${loIdx}-pc-${cIdx}-description`] &&
                                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                                <MdErrorOutline /> {translate(errors[`lo-${loIdx}-pc-${cIdx}-description`])}
                                                                            </small>
                                                                        }
                                                                    </div>
                                                                </DraggableItem>
                                                            ))
                                                        }
                                                    </Reorder.Group>
                                                    {/* new pc */}
                                                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewLOCriteria(loIdx)}>
                                                        <FaPlus /> {translate("Add Performance Criteria")}
                                                    </button>

                                                    {
                                                        errors[`lo-${loIdx}-performanceCriteria`] &&
                                                        <small className='text-red-700 flex items-center gap-1'>
                                                            <MdErrorOutline /> {translate(errors[`lo-${loIdx}-performanceCriteria`])}
                                                        </small>
                                                    }
                                                </div>
                                            </motion.div>
                                        </DraggableItem>
                                    ))
                                }
                            </Reorder.Group>

                            {/* new lo button */}
                            <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={addNewLO}>
                                <FaPlus />
                                {translate("New Learning Outcome")}
                            </button>

                            {
                                errors.learningOutcomes &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.learningOutcomes)}
                                </small>
                            }
                        </Fragment>
                }
            </div>

            {/* navigation buttons */}
            <div className='flex items-center justify-between gap-x-4'>
                <button type="button" className='submit-btn-outline' onClick={() => {
                    setLastStep(`general`)
                }}>
                    <FaArrowLeft />
                    <span className='max-md:hidden'>{translate(`Back to General`)}</span>
                </button>

                <button type="submit" className='submit-btn' disabled={Object.values(errors).some(Boolean)}>
                    <span className='max-md:hidden'>{translate("Forward to Modules")}</span>
                    <FaArrowRight />
                </button>
            </div>
        </form>
    )
}

export default Outcomes