"use client"

import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import { useTranslate } from "@/client/hooks"
import { AxiosRequest, devConsoleLog, formatKeyText } from "@/client/utils"
import { Fragment, useState } from "react";
import Image from "next/image";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline"
import { BACKEND_ROOT } from "@/constants"
import { clone } from "lodash";
import { useParams } from "next/navigation";


const Review = ({ options, formData, setLastStep, setLastSave }) => {
    const translate = useTranslate()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const { draftId } = useParams()

    const handleSubmit = async e => {
        e.preventDefault()

        // create course
        setLoading(true)

        let { file, ...rest } = formData

        await AxiosRequest.post(`${BACKEND_ROOT}/course/createCourse`,
            { file, json: JSON.stringify(rest), draftId },
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(async res => {
            const data = res.data

            // check for server validation errors
            if (data.errors) return setErrors(data.errors)

            // do something
            setLastSave(prev => {
                let updated = clone(prev)
                updated.course = res.data._id

                return updated
            })

            setLastStep("success")
        }).catch(err => {
            devConsoleLog(err.message || err.code);
        })

        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 flex-grow">
            {/* header */}
            <h1 className="text-3xl text-blue-500 font-bold">{translate("Review Course")}</h1>

            {/* general info */}
            <div className="flex flex-col flex-grow w-full gap-y-2">
                <h2 className="text-2xl font-bold text-blue-500">{translate("General Information")}</h2>

                <div className="flex gap-x-2 items-center text-lg flex-wrap">
                    <span className="text-gray-500 font-bold">{translate("Course Type")}:</span>
                    <span className="capitalize">{formData.type}</span>
                </div>

                <div className="flex gap-x-2 items-center text-lg flex-wrap">
                    <span className="text-gray-500 font-bold">{translate("Course Title")}:</span>
                    <span className="capitalize">{formData.title}</span>
                </div>

                <div className="flex gap-x-2 items-center text-lg flex-wrap">
                    <span className="text-gray-500 font-bold">{translate("Course Description")}:</span>
                    <span className="capitalize">{formData.description || translate("No Description")}</span>
                </div>

                {
                    formData.type == 'academic' && (
                        <Fragment>
                            <div className="flex gap-x-2 items-center text-lg flex-wrap">
                                <span className="text-gray-500 font-bold">{translate("Grade Level")}:</span>
                                <span className="capitalize">{options?.gradeLevels?.find(gl => gl.value == formData.gradeLevel)?.label || <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />}</span>
                            </div>

                            <div className="flex gap-x-2 items-center text-lg flex-wrap">
                                <span className="text-gray-500 font-bold">{translate("Educational System")}:</span>
                                <span className="capitalize">{options?.educationalSystems?.find(gl => gl.value == formData.educationalSystem)?.label || <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />}</span>
                            </div>
                        </Fragment>
                    )
                }

                {
                    formData.type == 'professional' && (
                        <Fragment>
                            <div className="flex gap-x-2 items-center text-lg flex-wrap">
                                <span className="text-gray-500 font-bold">{translate("Pathway")}:</span>
                                <span className="capitalize">{options?.pathways?.find(gl => gl.value == formData.pathway)?.label || <AiOutlineLoading3Quarters className="animate-spin text-blue-500" />}</span>
                            </div>

                            <div className="flex gap-x-2 items-center text-lg flex-wrap">
                                <span className="text-gray-500 font-bold">{translate("Order")}:</span>
                                <span className="capitalize">{formData.order}</span>
                            </div>
                        </Fragment>
                    )
                }

                <div className="flex flex-col text-lg">
                    <span className="text-gray-500 font-bold">{translate("Cover Image")}:</span>
                    {
                        formData.file &&
                        <Image
                            src={formData.file instanceof File ? URL.createObjectURL(formData.file) : formData.file}
                            alt={`${formData.title} cover image`}
                            width={150} height={150}
                            className="rounded border border-gray-300 object-cover shadow"
                        />
                    }
                </div>

                <div className="flex gap-x-2 items-center text-lg flex-wrap">
                    <span className="text-gray-500 font-bold">{translate("Related Keywords")}:</span>
                    <span className="capitalize">{formData.keywords?.join(", ")}</span>
                </div>

                <div className="flex flex-col text-lg">
                    <span className="text-gray-500 font-bold">{translate("Audiences")}:</span>
                    {
                        formData.audiences?.map((aud, idx) => (
                            <span key={idx} className="capitalize">{idx + 1}. {options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label}: {aud.intendedLearners?.join(", ")}</span>
                        ))
                    }
                </div>
            </div>

            {/* divider */}
            <div className="bg-gray-300 w-full h-[1px] rounded-full" />

            {/* learning outcomes */}
            <div className="flex flex-col flex-grow w-full gap-y-2">
                <h2 className="text-2xl font-bold text-blue-500">{translate("Learning Outcomes")}</h2>

                {
                    formData.learningOutcomes?.map((lo, idx) => (
                        <div className="flex flex-col gap-y-1" key={idx}>
                            <h3 className="text-gray-500 font-bold text-xl">{translate(`Learning Outcome`)} {idx + 1}:</h3>

                            <div className="flex flex-col">
                                <span className="font-bold">{translate("Statement")}:</span>
                                <span>{lo.statement}</span>
                            </div>

                            <div className="flex flex-col">
                                <span className="font-bold">{translate("Scope")}:</span>
                                <span>{lo.scope}</span>
                            </div>

                            <div className="flex flex-col">
                                <span className="font-bold">{translate("Domain")}:</span>
                                <span>{lo.domain}</span>
                            </div>

                            <div className="flex flex-col">
                                <span className="font-bold">{translate(`Performance Criteria`)}:</span>
                                {lo.performanceCriteria.map((pc, pcIdx) => <span key={pcIdx}>{pcIdx + 1}. {pc.description}</span>)}
                            </div>

                            {/* modules */}
                            <Fragment>
                                <h2 className="text-lg font-bold text-blue-500">{translate("Modules")}</h2>

                                {   
                                    lo.modules?.map((module, idx) => (
                                        <Fragment key={idx}>
                                            <span className="text-gray-500 font-bold">{translate(`Module`)} {idx + 1}: {module.name}</span>

                                            {
                                                module.lessons.map((lesson, lessonIdx) => (
                                                    <div key={lessonIdx} className="flex flex-col">
                                                        <span className="font-bold">{translate(`Lesson`)} {lessonIdx + 1}: {lesson.name}</span>
                                                        {lesson.learningOutcomes.map((lo, loIdx) => <span key={loIdx}>{loIdx + 1}. {lo}</span>)}
                                                    </div>
                                                ))
                                            }
                                        </Fragment>
                                    ))
                                }
                            </Fragment>
                        </div>
                    ))
                }
            </div>

            {/* navigation buttons */}
            <div className='flex items-center justify-between gap-x-4'>
                <button type="button" className='submit-btn-outline' onClick={() => {
                    setLastStep(`modules`)
                }}>
                    <FaArrowLeft />
                    <span className='max-md:hidden'>{translate(`Back to Modules`)}</span>
                </button>

                <button type="submit" className='submit-btn' disabled={loading || Object.values(errors).some(Boolean)}>
                    <span className='max-md:hidden'>{translate("Create Course")}</span>
                    {loading ? <AiOutlineLoading3Quarters className="animate-spin" /> : <FaCheck />}
                </button>
            </div>

            {
                Object.values(errors).some(Boolean) &&
                <small className='text-red-700 flex flex-col gap-y-1'>
                    {
                        Object.entries(errors).map(([key, value]) => (
                            <span key={key} className="flex items-center gap-x-1">
                                <MdErrorOutline />
                                {translate(formatKeyText(key))}: {value}
                            </span>
                        ))
                    }
                </small>
            }
        </form>
    )
}

export default Review