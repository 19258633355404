"use client"

import { useState, Fragment } from "react";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import { MdEdit } from "@react-icons/all-files/md/MdEdit"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import { AxiosRequest } from "@/client/utils";
import { BACKEND_ROOT } from "@/constants";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import MDEditor from '@uiw/react-md-editor';


const PreviewNode = ({ rootReference, tree, depth = 0, rootBuilder, setParent = () => { }, ...props }) => {
    const [referenceTree, setReferenceTree] = useState(tree)
    const [previewTree, setPreviewTree] = useState(tree)
    const [showChildren, setShowChildren] = useState(depth < 2)
    const [editMode, setEditMode] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleSaveEdits = async (e) => {
        // open edit mode if closed
        if (!editMode) return setEditMode(true);

        // close edit mode without save if text not changed
        if (referenceTree.text == previewTree.text) return setEditMode(false);

        setSaving(true)
        await AxiosRequest.put(`${BACKEND_ROOT}/course/updateTextByReference`, {
            ...rootReference,
            textReference: previewTree.textReference,
            text: previewTree.text
        }).then(res => {
            const updatedText = res.data[previewTree.textReference];

            setPreviewTree({ ...previewTree, text: updatedText });
            setReferenceTree({ ...referenceTree, text: updatedText });

            setEditMode(false)
        }).catch(err => {
            console.info(err.message)
        })
        setSaving(false)
    }

    const handleAddNewChild = async (e) => {
        const updatedData = { ...previewTree }
        const newChild = updatedData.newChild

        setSaving(true)

        await AxiosRequest.put(`${BACKEND_ROOT}/course/pushNestedRecord`, {
            ...rootReference,
            parentReference: newChild.parentReference,
            newRecord: newChild.bodyData
        }).then(async res => {
            const updatedCourse = res.data
            await rootBuilder.rebuildCourseTree(updatedCourse)

            // update changes
            const updatedNode = rootBuilder.getTreeNodeByBranch(previewTree.branch)
            setPreviewTree(updatedNode)
            setReferenceTree(updatedNode)
        }).catch(err => {
            console.info(err.message)
        })

        setSaving(false)
    }

    const handleDelete = async (e) => {
        setSaving(true)

        await AxiosRequest.put(`${BACKEND_ROOT}/course/popNestedRecord`, {
            ...rootReference,
            ...previewTree.deleteReference
        }).then(async res => {
            const updatedCourse = res.data
            await rootBuilder.rebuildCourseTree(updatedCourse)

            // update changes
            const branchTokens = previewTree.branch.split(".")
            branchTokens.splice(-1, 1)
            const parentBranch = branchTokens.join(".")
            const updatedParent = rootBuilder.getTreeNodeByBranch(parentBranch)
            setParent(updatedParent)
        }).catch(err => {
            console.info(err.message)
        })

        setSaving(false)
    }

    return (
        <div
            {...props}
            className={`${depth % 2 == 0 ? "bg-gray-100" : "bg-gray-50"} border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col`}
        >
            <div className="flex gap-x-4">
                {editMode &&
                    <Fragment>
                        <textarea autoFocus className="input-bar min-h-fit" value={previewTree.text} onChange={e => { setPreviewTree({ ...previewTree, text: e.target.value }) }} />
                        <div className="w-1 bg-gray-300 rounded-full" />
                    </Fragment>
                }

                <MDEditor.Markdown>
                    {previewTree.text}
                </MDEditor.Markdown>

                <div className={`flex gap-x-2 items-center h-12`}>
                    {
                        (saving && (previewTree.textReference || (previewTree.deleteReference && previewTree.deleteActive))) ?
                            <AiOutlineLoading3Quarters className="animate-spin text-lg text-blue-400 duration-300" />
                            :
                            <Fragment>
                                {
                                    previewTree.textReference &&
                                    <button disabled={saving} className="green-icon-btn" onClick={handleSaveEdits}>
                                        {
                                            editMode ? <FaCheck className="text-lg" />
                                                : <MdEdit className="text-lg" />
                                        }
                                    </button>
                                }

                                {
                                    (previewTree.deleteReference && previewTree.deleteActive) &&
                                    <button disabled={saving} className="danger-icon-btn" onClick={handleDelete}>
                                        <MdDelete className="text-lg" />
                                    </button>
                                }
                            </Fragment>
                    }

                    {
                        (previewTree.children.length > 0 || previewTree.newChild) &&
                        <button className="blue-icon-btn" onClick={() => { setShowChildren(prev => !prev) }}>
                            <MdExpandMore className={`text-3xl transition-transform duration-500 ${showChildren ? 'rotate-180' : 'rotate-0'}`} />
                        </button>
                    }
                </div>
            </div>

            <div className={`flex flex-col gap-y-2 ${showChildren ? "max-h-[500dvh]" : "max-h-0"} duration-500 transition-all overflow-hidden`}>
                {/* conditional gap */}
                <div />
                {
                    previewTree.children.map((child, idx) => (
                        <PreviewNode
                            key={idx}
                            rootReference={rootReference}
                            rootBuilder={rootBuilder}
                            tree={child}
                            depth={depth + 1}
                            setParent={(updatedParent) => {
                                setPreviewTree(updatedParent);
                                setReferenceTree(updatedParent);
                            }}
                        />
                    ))
                }

                {
                    previewTree.newChild &&
                    <button disabled={saving} onClick={handleAddNewChild} className={`${depth % 2 == 0 ? "bg-gray-50" : "bg-gray-100"} border-2 border-dashed border-gray-300 rounded-lg p-4 flex items-center gap-x-2 text-lg font-semibold`}>
                        {saving ? <AiOutlineLoading3Quarters className="animate-spin text-blue-400 duration-300" /> : <FaPlus />}
                        {previewTree.newChild.buttonText}
                    </button>
                }
            </div>
        </div>
    )
}

export default PreviewNode