"use client"

import general from "./General"
import outcomes from "./Outcomes"
import modules from "./Modules"
import review from "./Review"
import success from "./Success"

export default {
    general,
    outcomes,
    modules,
    review,
    success
}