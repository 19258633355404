"use client"

import { BACKEND_ROOT, BASE_URL } from "@/constants"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { AxiosRequest, getTimeAgo, devConsoleLog, formatNumber, ReactSwal } from "@/client/utils";
import Swal from "sweetalert2";
import { Fragment, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import { IoNotifications } from "@react-icons/all-files/io5/IoNotifications"
import { IoRefresh } from "@react-icons/all-files/io5/IoRefresh"
import CustomModal from "./CustomModal"
import { clone } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUserStatistics } from "@/client/store/reducers/userReducer";
import { useTranslate } from "@/client/hooks";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GiTrophyCup } from "@react-icons/all-files/gi/GiTrophyCup";
import { openSocket, closeSocket, socketListen } from "@/client/store/reducers/socketReducer";
import { GiProgression } from "@react-icons/all-files/gi/GiProgression";
import { FaStar } from "@react-icons/all-files/fa/FaStar";

import { GiAchievement } from "@react-icons/all-files/gi/GiAchievement";
import { getAnnouncements10, setAnnouncements10, getUnreadAnnouncementsCount, readSingleAnnouncement, addSingleAnnouncement, deleteSingleAnnouncement } from "@/client/store/reducers/announcementsReducer";
import AnnouncementModal from "./AnnouncementModal";
import { useParams, useRouter, usePathname } from "next/navigation";
import { FaMedal } from "@react-icons/all-files/fa/FaMedal";
import { GiSportMedal } from "@react-icons/all-files/gi/GiSportMedal";
import { IoPerson } from "@react-icons/all-files/io5/IoPerson";
import { HiSpeakerphone } from "@react-icons/all-files/hi/HiSpeakerphone";



const announcement_text_length_max = 400;

const fn_announcement_text_400 = (announcement_text) => {
    return (announcement_text_length_max < announcement_text?.length) ? announcement_text.slice(0, announcement_text_length_max) : announcement_text;
}

const NotificationsCenter = () => {
    const router = useRouter();
    const pathname = usePathname();
    const { lang } = useParams();
    const user = useSelector(getUser)
    const translate = useTranslate();
    const dispatch = useDispatch()

    const unreadAnnouncementsCount = useSelector(getUnreadAnnouncementsCount);
    const announcements = useSelector(getAnnouncements10);
    const setAnnouncements = (newAnnouncements) => {
        dispatch(setAnnouncements10(newAnnouncements));
    }
    const [notifications, setNotifications] = useState(null)
    const [unseenCountNotifications, setUnseenCountNotifications] = useState(null)
    const [loadMore, setLoadMore] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)
    const [modal, setModal] = useState({ open: false })
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })


    // modalAnnouncement 
    const [modalAnnouncement, setModalAnnouncement] = useState({ open: false });
    const [announcementTitle, setAnnouncementTitle] = useState("");
    const [announcementText, setAnnouncementText] = useState("");
    const [announcement_written_date, setAnnouncement_written_date] = useState(false);
    const openModalAnnouncement = (params) => setModalAnnouncement({ open: true, ...params })
    const closeModalAnnouncement = () => {
        setModalAnnouncement({ open: false });
        // reset modalAnnouncement
        setAnnouncementTitle("");
        setAnnouncementText("");
        setAnnouncement_written_date(false);
    }


    const readAnnouncement = (announcement) => {
        const tmp_id = announcement._id ?? null;
        if (!tmp_id) {
            return;
        }
        const announcement_text = announcement.text;
        const announcement_title = announcement.title;

        let written_date = false;
        const createdAt = announcement?.createdAt;
        if (createdAt) {
            const dateObj = new Date(createdAt);
            written_date = `${dateObj.getHours()}:${dateObj.getMinutes()} - ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        }


        setAnnouncementTitle(announcement_title);
        setAnnouncementText(announcement_text);
        setAnnouncement_written_date(written_date);
        openModalAnnouncement({ mode: 'open' });

        dispatch(readSingleAnnouncement(tmp_id));
    };


    const fetchAnnouncements = () => {
        AxiosRequest.get(`${BACKEND_ROOT}/announcements`)
            .then(res => {
                const x = res?.data ?? null;
                if (x) {
                    setAnnouncements(x);
                }
            }).catch(err => {
                devConsoleLog(err.message || err.code);
            });
    }


    const fetchNotifications = async (limit, skip) => {
        if (skip > 0) {
            setMoreLoading(true)
            setLoadMore(false)
        }

        await AxiosRequest.get(`${BACKEND_ROOT}/notification/listAll`, {
            params: {
                limit,
                skip,
            }
        }).then(res => {
            let justFetched = res.data
            let fetchedEarlier = notifications || []
            let all = skip > 0 ? [...justFetched, ...fetchedEarlier] : justFetched
            all = all.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

            setNotifications(all)
            setUnseenCountNotifications(all.reduce((count, i) => count + (!i.seenAt ? 1 : 0), 0))

            if (justFetched.length == limit) setLoadMore(true)
        }).catch(err => {
            devConsoleLog(err.message || err.code);
        })

        if (skip > 0) setMoreLoading(false)
    }


    const CompetitionResult = ({ studentsCompetitions, competition }) => {
        const tierCards = {
            gold: ({ sc }) => (
                <div className="flex gap-x-2 p-2 items-center justify-between bg-amber-100 rounded">
                    <div className="flex gap-x-2 items-center w-[70%]">
                        <span>{sc.rank}</span>
                        <div className="rounded-full h-[50px] w-[50px] border-2 border-amber-400">
                            {
                                sc.user.image ? <img src={sc.user.image} className="h-full w-full object-cover rounded-full" />
                                    : <IoPerson className="h-full w-full p-1" />
                            }
                        </div>
                        <h1>{`${sc.user.firstName} ${sc.user.lastName}`}</h1>
                    </div>

                    <span className="uppercase">{`${formatNumber(sc.xpPoints)} ${translate("xp")}`}</span>
                    <FaMedal className="text-amber-400" fontSize={30} />
                </div>
            ),
            silver: ({ sc }) => (
                <div className="flex gap-x-2 p-2 items-center justify-between bg-slate-100 rounded">
                    <div className="flex gap-x-2 items-center w-[70%]">
                        <span>{sc.rank}</span>
                        <div className="rounded-full h-[50px] w-[50px] border-2 border-slate-400">
                            {
                                sc.user.image ? <img src={sc.user.image} className="h-full w-full object-cover rounded-full" />
                                    : <IoPerson className="h-full w-full p-1" />
                            }
                        </div>
                        <h1>{`${sc.user.firstName} ${sc.user.lastName}`}</h1>
                    </div>

                    <span className="uppercase">{`${formatNumber(sc.xpPoints)} ${translate("xp")}`}</span>
                    <FaMedal className="text-slate-400" fontSize={30} />
                </div>
            ),
            bronze: ({ sc }) => (
                <div className="flex gap-x-2 p-2 items-center justify-between bg-[#CD7F32]/10 rounded">
                    <div className="flex gap-x-2 items-center w-[70%]">
                        <span>{sc.rank}</span>
                        <div className="rounded-full h-[50px] w-[50px] border-2 border-[#CD7F32]">
                            {
                                sc.user.image ? <img src={sc.user.image} className="h-full w-full object-cover rounded-full" />
                                    : <IoPerson className="h-full w-full p-1" />
                            }
                        </div>
                        <h1>{`${sc.user.firstName} ${sc.user.lastName}`}</h1>
                    </div>

                    <span className="uppercase">{`${formatNumber(sc.xpPoints)} ${translate("xp")}`}</span>
                    <FaMedal className="text-[#CD7F32]" fontSize={30} />
                </div>
            ),
            participant: ({ sc }) => (
                <div className="flex gap-x-2 p-2 items-center justify-between rounded bg-blue-50">
                    <div className="flex gap-x-2 items-center w-[70%]">
                        <span>{sc.rank}</span>
                        <div className="rounded-full h-[50px] w-[50px] border-2 border-blue-400">
                            {
                                sc.user.image ? <img src={sc.user.image} className="h-full w-full object-cover rounded-full" />
                                    : <IoPerson className="h-full w-full p-1" />
                            }
                        </div>
                        <h1>{`${sc.user.firstName} ${sc.user.lastName}`}</h1>
                    </div>

                    <span className="uppercase">{`${formatNumber(sc.xpPoints)} ${translate("xp")}`}</span>
                    <GiSportMedal className="text-blue-400" fontSize={30} />
                </div>
            )
        }

        return (
            <div className="flex flex-col gap-y-2 h-[80vh] w-[768px] overflow-y-auto">
                <h1 className="capitalize text-2xl my-2">{competition.course.title} {translate(`competition result`)}</h1>
                {
                    studentsCompetitions.map((sc, idx) => {
                        const TierCard = tierCards[sc.tier]

                        return <TierCard key={idx} sc={sc} />
                    })
                }
            </div>
        )
    }


    useEffect(() => {
        if (user) {
            // initially fetch notifications
            fetchNotifications(5, 0)

            // initially fetch announcements here
            fetchAnnouncements();

            // open socket
            dispatch(openSocket({ user }))

            // competition
            dispatch(socketListen({
                event: 'competition-result',
                callback: (studentsCompetitions, competition) => {
                    ReactSwal.fire({
                        width: 'auto',
                        showConfirmButton: false,
                        html: <CompetitionResult studentsCompetitions={studentsCompetitions} competition={competition} />
                    })
                }
            }))

            // streak up
            dispatch(socketListen({
                event: 'streak-up',
                callback: ({ daysCount, xpPoints }) => {
                    // update announcements here
                    devConsoleLog(`Streak UP ${daysCount} days reached`);
                    dispatch(setUserStatistics({ streakDays: daysCount }))
                    
                    toast(
                        <div className="flex flex-col gap-y-2">
                            <h1 className="flex gap-x-2 items-center">
                                <GiProgression className="text-blue-400 h-[30px] w-fit" />
                                <span className="capitalize text-lg text-gray-500">{translate("Streak Up!")}</span>
                            </h1>

                            <h2 className="flex gap-x-2 items-center text-sm">
                                <FaStar className="text-blue-400 h-[20px] w-fit" />
                                <p className="capitalize">{daysCount} {translate("streak days reached!")}</p>
                            </h2>

                            <h2 className="flex gap-x-2 items-center text-sm">
                                <FaStar className="text-blue-400 h-[20px] w-fit" />
                                <p className="capitalize">
                                    {xpPoints}
                                    <span className="uppercase"> {translate("XP")} </span>
                                    {translate("points gained")}
                                </p>
                            </h2>
                        </div>
                    )
                }
            }))

            // new_achievement
            dispatch(socketListen({
                event: 'new_achievement',
                callback: ({ titleCached, xpPoints }) => {

                    toast(
                        <div className="flex flex-col gap-y-2">
                            <h1 className="flex gap-x-2 items-center">
                                <GiAchievement className="text-blue-400 h-[30px] w-fit" />
                                <span className="capitalize text-lg text-gray-500">{translate("New Achievement!")}</span>
                            </h1>

                            <h2 className="flex gap-x-2 items-center text-sm">
                                <FaStar className="text-blue-400 h-[20px] w-fit" />
                                <p className="capitalize">{translate(titleCached)}</p>
                            </h2>

                            <h2 className="flex gap-x-2 items-center text-sm">
                                <FaStar className="text-blue-400 h-[20px] w-fit" />
                                <p className="capitalize">
                                    {xpPoints}
                                    <span className="uppercase"> {translate("XP")} </span>
                                    {translate("points gained")}
                                </p>
                            </h2>
                        </div>
                    )
                }
            }))

            // xp gained
            dispatch(socketListen({
                event: 'xp-gained',
                callback: (xpPoints) => {
                    // update announcements here
                    devConsoleLog('Received XP:', xpPoints);

                    toast(
                        <h1 className="flex gap-x-2 items-center">
                            <GiTrophyCup className="text-blue-400 h-[30px] w-fit" />
                            <span className="capitalize text-lg text-gray-500">{xpPoints} {translate("XP points gained!")}</span>
                        </h1>
                    )
                }
            }))

            // new announcement
            dispatch(socketListen({
                event: 'announcement',
                callback: (newAnnouncement) => {
                    devConsoleLog('Received announcement:', newAnnouncement);

                    try {
                        devConsoleLog('123');
                        try {
                            devConsoleLog('b4 audio');
                            // Play sound on new notification
                            const audio = new Audio('/notification.mp3');
                            audio.play();
                            devConsoleLog('after audio');
                        } catch (error) {

                        }
                        devConsoleLog('b4 SwalToast');
                        Swal.fire({
                            toast: true,
                            title: newAnnouncement?.title ?? "",
                            iconColor: 'white',
                            showConfirmButton: false,
                            timerProgressBar: true,
                            position: "top-end",
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.onmouseenter = Swal.stopTimer;
                                toast.onmouseleave = Swal.resumeTimer;
                                toast.addEventListener('click', () => {
                                    devConsoleLog("SwalToast clicked")
                                    readAnnouncement(newAnnouncement);
                                    Swal.close()
                                })
                            }
                        })
                        devConsoleLog('after SwalToast');
                    } catch (error) {
                        devConsoleLog('error');
                    }
                    devConsoleLog('456');

                    dispatch(addSingleAnnouncement(newAnnouncement));
                }
            }));

            // an announcement got deleted
            dispatch(socketListen({
                event: 'deletedAnnouncement',
                callback: (announcement_id) => {
                    devConsoleLog('deletedAnnouncement:', announcement_id);

                    dispatch(deleteSingleAnnouncement(announcement_id));
                    devConsoleLog('after dispatch deletedAnnouncement');
                }
            }));

            // new notification
            dispatch(socketListen({
                event: 'notification',
                callback: (newNotification) => {
                    devConsoleLog('Received notification:', newNotification);

                    // Play sound on new notification
                    const audio = new Audio('/notification.mp3');
                    audio.play()

                    // Update notifications with the most recent state
                    setNotifications((prevNotifications) => {
                        return [newNotification, ...prevNotifications];
                    });

                    // Update unseen count
                    setUnseenCountNotifications((prevCount) => prevCount + 1);
                }
            }))

            // refresh notifications
            dispatch(socketListen({
                event: 'refreshNotifications',
                callback: (refreshed) => {
                    setNotifications(refreshed)
                    setUnseenCountNotifications(refreshed.reduce((count, i) => count + (!i.seenAt ? 1 : 0), 0))
                }
            }))

            // close socket
            return () => {
                dispatch(closeSocket());
            }
        }
    }, [user])


    const NotificationDetails = ({ notification, idx }) => {
        useEffect(() => {
            if (notification && !notification.seenAt) {
                AxiosRequest.put(`${BACKEND_ROOT}/notification/seen/${notification._id}`)
                    .then(res => {
                        let updated = clone(notifications);
                        updated[idx].seenAt = res.data.seenAt;

                        setNotifications(updated);
                        setUnseenCountNotifications(unseenCountNotifications - 1);
                    }).catch(err => {
                        devConsoleLog(err.message || err.code);
                    })
            }
        }, [notification]);

        return (
            <div className="flex flex-col px-4 py-8 gap-y-1 text-start">
                <div className="flex gap-x-2 justify-between items-center font-semibold mb-3">
                    <span>{notification?.attributes.reduce((acc, { key, value }) => acc.replace(key, value), notification.notificationEvent.short)}</span>
                    <span className="text-xs italic">{getTimeAgo(notification?.createdAt)}</span>
                </div>
                {
                    notification?.attributes.reduce((acc, { key, value }) => acc.replace(key, value), notification.notificationEvent.template).split("\n").map((line, lineIdx) => {
                        return (
                            <p key={lineIdx}>{line}</p>
                        )
                    })
                }
            </div>
        )
    }


    const NotificationShort = ({ notification, idx }) => {
        // extract short & template
        const short = notification.attributes.reduce((acc, { key, value }) => acc.replace(key, value), notification.notificationEvent.short)
        const template = notification.attributes.reduce((acc, { key, value }) => acc.replace(key, value), notification.notificationEvent.template)
        const customTemplates = {
            "compeition-result": () => {
                const studentsCompetitions = JSON.parse(notification.attributes.find(attr => attr.key == '[studentsCompetitions]').value)
                const competition = JSON.parse(notification.attributes.find(attr => attr.key == '[competition]').value)

                ReactSwal.fire({
                    width: 'auto',
                    showConfirmButton: false,
                    html: <CompetitionResult studentsCompetitions={studentsCompetitions} competition={competition} />
                }).then(async action => {
                    if (notification && !notification.seenAt) {
                        await AxiosRequest.put(`${BACKEND_ROOT}/notification/seen/${notification._id}`)
                            .then(res => {
                                let updated = clone(notifications);
                                updated[idx].seenAt = res.data.seenAt;

                                setNotifications(updated);
                                setUnseenCountNotifications(unseenCountNotifications - 1);
                            }).catch(err => {
                                devConsoleLog(err.message || err.code);
                            })
                    }
                })
            }
        }

        const actions = {
            link: () => {
                const newPath = "/"+lang+template
                const newUrl = new URL(newPath, BASE_URL)
                newUrl.pathname == pathname ? router.replace(newPath) : router.push(newPath)

                AxiosRequest.put(`${BACKEND_ROOT}/notification/seen/${notification._id}`).then(res => {
                    if (notification && !notification.seenAt) {
                        let updated = clone(notifications);
                        updated[idx].seenAt = res.data.seenAt;

                        setNotifications(updated);
                        setUnseenCountNotifications(unseenCountNotifications - 1);
                    }
                }).catch(err => console.info(err.message))
            },
            text: () => openModal({ notification, idx }),
            custom: customTemplates[template]
        }


        return (
            <MenuItem
                as="button"
                onClick={actions[notification.notificationEvent.templateType]}
                className={`flex items-center px-4 py-2 gap-x-2 ${!notification.seenAt ? 'font-semibold bg-gray-200 hover:bg-blue-200' : 'hover:bg-blue-100 bg-gray-100'} hover:text-blue-500 transition-colors duration-300`}
            >
                <div className='line-clamp-2 flex-grow text-start' dangerouslySetInnerHTML={{ __html: short }} />
                <span className="text-xs italic">{getTimeAgo(notification.createdAt)}</span>
            </MenuItem>
        )
    }


    return (
        <Fragment>
            {/* announcement */}
            <Menu>
                <MenuButton className="relative">
                    <HiSpeakerphone fontSize={20} />
                    {
                        (() => {
                            if (0 < (unreadAnnouncementsCount)) {
                                return (<span className="rounded-full bg-orange-500 w-[15px] h-[15px] text-xs -top-1 -right-1 absolute">{unreadAnnouncementsCount}</span>);
                            }
                            return null;
                        })()
                    }
                </MenuButton>

                <MenuItems style={{ zIndex: "99999999", }} transition anchor="bottom end" className="bg-gray-50 rounded text-gray-500 text-sm flex flex-col shadow border border-gray-300 mt-2 h-[250px] w-[500px] overflow-y-auto small-scroll divide-y divide-gray-300">
                    <MenuItem
                        as={'h1'}
                        className="px-4 py-2 text-center font-semibold capitalize  hover:bg-blue-200 hover:text-blue-500 transition-colors duration-300 "
                        onClick={() => router.push(`/${lang}/dashboard/announcements`)}
                        style={{ cursor: "pointer", }}
                    >
                        {translate(`Announcements`)}
                    </MenuItem>
                    {
                        (() => {
                            if (0 >= (unreadAnnouncementsCount)) {
                                return (
                                    <MenuItem as={'h1'} className="px-4 py-2 text-center font-semibold">
                                        {translate(`You don't have any unread announcements in the current time!`)}
                                    </MenuItem>);
                            }
                            return (
                                announcements
                                    ?.filter((x) => !((x?.is_read ?? 0) - 0))
                                    ?.map((announcement, idx) => {
                                        const tmp_id = announcement._id ?? null;
                                        const announcement_text = announcement.text;
                                        const announcement_title = announcement.title;
                                        let written_date = false;
                                        const createdAt = announcement?.createdAt;
                                        if (createdAt) {
                                            const dateObj = new Date(createdAt);
                                            written_date = `${dateObj.getHours()}:${dateObj.getMinutes()} - ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
                                        }
                                        return (
                                            <MenuItem
                                                as={'button'}
                                                key={tmp_id}
                                                className={`relative px-4 py-2 text-start font-semibold bg-gray-200 hover:bg-blue-200 hover:text-blue-500 transition-colors duration-300`}
                                                onClick={() => {
                                                    readAnnouncement(announcement);
                                                }}
                                            >
                                                <h2 className="text-xl font-bold">{fn_announcement_text_400(announcement_title)}</h2>
                                                {
                                                    written_date ? (
                                                        <div
                                                            key={tmp_id}
                                                            style={{
                                                                color: "#5f5f5f",
                                                                textAlign: "right",
                                                            }}
                                                        >
                                                            {written_date}
                                                        </div>
                                                    )
                                                        :
                                                        null
                                                }
                                                <p className="text-gray-700">{fn_announcement_text_400(announcement_text)}</p>
                                            </MenuItem>
                                        )
                                    })
                            );
                        })()
                    }
                </MenuItems>

                <AnnouncementModal
                    open={modalAnnouncement.open}
                    onClose={closeModalAnnouncement}
                    announcementTitle={announcementTitle}
                    announcementText={announcementText}
                    announcement_written_date={announcement_written_date}
                />
            </Menu>


            <Menu>
                <MenuButton className="relative">
                    <IoNotifications fontSize={20} />
                    {
                        (() => {
                            if (0 < (unseenCountNotifications)) {
                                return (<span className="rounded-full bg-orange-500 w-[15px] h-[15px] text-xs -top-1 -right-1 absolute">{unseenCountNotifications}</span>);
                            }
                            return null;
                        })()
                    }
                </MenuButton>

                <MenuItems style={{ zIndex: "99999999", }} transition anchor="bottom end" className="bg-gray-50 rounded text-gray-500 text-sm flex flex-col shadow border border-gray-300 mt-2 h-[250px] w-[500px] overflow-y-auto small-scroll divide-y divide-gray-300">
                    {
                        !notifications ?
                            <MenuItem as={'span'} className="text-blue-500 mx-auto px-4 py-2 text-[20px]">
                                <AiOutlineLoading3Quarters className="animate-spin" />
                            </MenuItem>
                            :
                            <Fragment>
                                <MenuItem as={'div'} className="flex justify-end">
                                    <button
                                        className="p-1 text-blue-500 hover:bg-blue-200 transition-colors duration-300 rounded-full"
                                        onClick={e => {
                                            e.preventDefault()
                                            setNotifications(null)
                                            fetchNotifications(5, 0)
                                        }}
                                    >
                                        <IoRefresh fontSize={20} />
                                    </button>
                                </MenuItem>
                                {
                                    notifications?.length == 0 ?
                                        <MenuItem as={'h1'} className="px-4 py-2 text-center font-semibold">
                                            {translate(`You don't have any notifications in the current time!`)}
                                        </MenuItem>
                                        : notifications?.map((notification, idx) => {

                                            return <NotificationShort key={idx} notification={notification} idx={idx} />
                                        })
                                }
                                {/* load more button */}
                                {
                                    loadMore &&
                                    <MenuItem
                                        as={'button'}
                                        className="text-blue-500 hover:underline px-2 py-1 hover:bg-blue-100 duration-300 transition-colors"
                                        onClick={e => {
                                            e.preventDefault()
                                            fetchNotifications(5, notifications?.length)
                                        }}
                                    >
                                        {translate(`Load More`)}
                                    </MenuItem>
                                }

                                {/* more loading icon */}
                                {
                                    moreLoading &&
                                    <MenuItem as={'span'} className="text-blue-500 mx-auto px-4 py-2 text-[20px]">
                                        <AiOutlineLoading3Quarters className="animate-spin" />
                                    </MenuItem>
                                }
                            </Fragment>
                    }
                </MenuItems>

                <CustomModal open={modal.open} onClose={closeModal} className={`max-w-4xl`}>
                    <NotificationDetails notification={modal.notification} idx={modal.idx} />
                </CustomModal>
            </Menu>
        </Fragment>

    )
}

export default NotificationsCenter