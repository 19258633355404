"use client"

import { useSearchParams } from "next/navigation"
import { Fragment, useState } from "react"
import CommentForm from "./CommentForm"
import { IoEllipsisVertical } from "@react-icons/all-files/io5/IoEllipsisVertical"
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { MdReport } from "@react-icons/all-files/md/MdReport";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { getTimeAgo, devConsoleLog } from "@/client/utils"
import { useTranslate } from "@/client/hooks"
import BadgeSingleComp from "@/client/components/BadgeSingleComp";


const ReplyCard = ({
    r,
    handleEditReply,
    handleDeleteReply,
    handleReportReply,
    user,
    courseSeoTxt,
    postSeoTxt
}) => {
    const translate = useTranslate()

    const [editReply, setEditReply] = useState(false)
    const searchParams = useSearchParams()

    return editReply ?
        <CommentForm
            initial={r}
            handleFormAction={handleEditReply}
            closeForm={() => setEditReply(false)}
            postSeoTxt={postSeoTxt}
        />
        :
        <div className="flex gap-x-2 justify-between items-start">
            <div className="flex flex-col w-full">
                <div className="flex gap-x-2 justify-between items-center text-gray-500">
                    <div>
                        <h2 className="font-semibold">{r.user.firstName} {r.user.lastName}</h2>
                        {
                            (() => {
                                const tmp_BadgesArr = r?.user?.BadgesArr;
                                // devConsoleLog("tmp_BadgesArr");
                                // devConsoleLog(tmp_BadgesArr);
                                if (!(tmp_BadgesArr?.length)) {
                                    return null;
                                }
                                return (
                                    <div
                                        style={{
                                            overflowY: "auto",
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                        }}
                                    >
                                        {
                                            tmp_BadgesArr.map((badge,index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: "50px",
                                                        }}
                                                    >
                                                        <BadgeSingleComp
                                                            parent_is={"parent_is"}
                                                            badge={badge}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                )
                            })()
                        }
                    </div>
                    <span className="italic text-xs text-gray-500">{getTimeAgo(r.createdAt)}</span>
                </div>

                <p id={r._id} className={`${r._id == searchParams.get('reply') ? 'bg-green-200' : window.location.pathname.includes(postSeoTxt) ? 'bg-gray-100' : 'bg-gray-50'} border border-gray-300 p-2 rounded-lg`}>{r.content}</p>
            </div>

            {
                user &&
                <Menu>
                    <MenuButton className="text-gray-500 p-2 rounded-full hover:bg-gray-200 transition-colors duration-300 text-[18px]">
                        <IoEllipsisVertical />
                    </MenuButton>
                    <MenuItems transition anchor="bottom end" className="menu-3dots">
                        {
                            (r.user._id == user.id || user?.permissionLevel > 2) &&
                            <Fragment>
                                <MenuItem as={'button'} onClick={() => setEditReply(true)} className="menu-item-3dots">
                                    <MdEdit />
                                    <span>{translate(`Edit`)}</span>
                                </MenuItem>

                                <MenuItem as={'button'} onClick={handleDeleteReply} className="menu-item-3dots">
                                    <MdDelete />
                                    <span>{translate(`Delete`)}</span>
                                </MenuItem>
                            </Fragment>
                        }

                        {
                            (r.user._id != user?.id || user?.permissionLevel > 2) &&
                            <MenuItem as={'button'} onClick={handleReportReply} className="menu-item-3dots">
                                <MdReport />
                                <span>{translate(`Report`)}</span>
                            </MenuItem>
                        }
                    </MenuItems>
                </Menu>
            }
        </div>
}

export default ReplyCard