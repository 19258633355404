"use client";

import { persistor, store } from "@/client/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { getDict, setDict } from "@/client/store/reducers/dictReducer";
import { getUser, setUser } from "@/client/store/reducers/userReducer";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { devConsoleLog } from "@/client/utils";
import { SEO_API, SEO_API_KEY, AMAZON_ACCESS_KEY, AMAZON_SECRET_KEY, AMAZON_REGION, AMAZON_BUCKET_NAME } from "@/constants";
import AWS from 'aws-sdk';
import axios from 'axios';


const StoreProvider = ({ children }) => {

  const handleBeforeLift = async () => {
    const state = store.getState();

    // Only set dict and user in the state if they're not already initialized.
    if (!getDict(state)) {
      let dict = {}

      // try get dict from bucket
      const BUCKET_NAME = AMAZON_BUCKET_NAME;
      const dictKey = 'dict.json';

      // AWS S3 Configuration
      const s3 = new AWS.S3({
        accessKeyId: AMAZON_ACCESS_KEY,
        secretAccessKey: AMAZON_SECRET_KEY,
        region: AMAZON_REGION,
      });
      
      try {
        const data = await s3.getObject({ Bucket: BUCKET_NAME, Key: dictKey }).promise();
        dict = JSON.parse(data.Body.toString('utf-8'))
      } catch (err) {
        devConsoleLog(`Failed to get dict from s3: ${err.message}`)

        // if s3 failed, try get it from seo
        await axios.get(`${SEO_API}/translation/getDict/lwe`, {
          headers: {
            API_KEY: SEO_API_KEY
          }
        }).then(async res => {
          dict = res.data

          // write to s3
          const result = await s3.upload({
            Bucket: BUCKET_NAME,
            Key: dictKey,
            Body: JSON.stringify(dict),
            ContentType: "application/json",
          }).promise();

          devConsoleLog(`dict saved to s3 at: ${result.Location}`);
      }).catch(err => {
          devConsoleLog(`Failed to get dict from seo: ${err.message}`)
      })
      } finally {
        // set dict value whatever it is
        store.dispatch(setDict(dict));
      }
    }

    if (!getUser(state)) {
      // extract user
      const accessToken = Cookies.get('access_token');
      const user = accessToken ? jwtDecode(accessToken).user : null;

      if (user) store.dispatch(setUser(user));
    }
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null} onBeforeLift={handleBeforeLift}>
        {children}
      </PersistGate>
    </Provider>
  )
}

export default StoreProvider
