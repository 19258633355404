"use client"

import { useIntersectionObserver } from "@/client/hooks";

const AnimatedContent = ({ children, className = "", animate = "animate-fade-down", ...props }) => {
    const [ref, isIntersecting] = useIntersectionObserver({
        root: null,             // root container is the whole browser
        rootMargin: '0px',
        threshold: 0.1,
    });

    return (
        <div
            ref={ref}
            className={`transition-opacity duration-1000 ${isIntersecting ? `${animate} ${className} opacity-100` : 'opacity-0'}`}
            {...props}
        >
            {children}
        </div>
    );
};

export default AnimatedContent;
