"use client"

import { Heatmap } from "@/client/components";
import { useState, useMemo } from "react"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Switch } from "@headlessui/react"
import { useTranslate } from "@/client/hooks"


const ProgressHeatmap = ({ p, expand = true }) => {
    const translate = useTranslate()
    
    const [legend, setLegend] = useState('performance');
    const [levelType, setLevelType] = useState('max');

    const daysMarks = [
        ...new Set(p.learningPerformance.flatMap(lp => lp.questions.map(q => new Date(q.createdAt).toDateString())))
    ].sort((a, b) => new Date(a) - new Date(b));

    const [daysRange, setDaysRange] = useState([0, daysMarks.length - 1])

    const labels = p.course.learningOutcomes.map(lo => lo.statement)

    const filterQuestionsByDaysRange = lp => lp.questions.filter(q => {
        const createdAtDate = new Date(q.createdAt);

        const [startDateIdx, endDateIdx] = daysRange;

        const startOfStartDate = new Date(daysMarks[startDateIdx]);
        startOfStartDate.setHours(0, 0, 0, 0);

        const endOfEndDate = new Date(daysMarks[endDateIdx]);
        endOfEndDate.setHours(23, 59, 59, 999);

        return createdAtDate <= endOfEndDate && createdAtDate >= startOfStartDate;
    });

    const levelCalculation = {
        performance: rangeQuestions => {
            const binaryMap = [0, 0, 0]; // Initial binary mapping for difficulty levels [3, 2, 1]

            if (rangeQuestions.length > 0) {
                // proccess rangeQuestions based on levelType to get level
                if (levelType == 'max') {
                    // filter range questions to get passed questions
                    rangeQuestions.filter(q => q.passed).forEach(q => {
                        binaryMap[3 - q.difficultyLevel] = 1;
                    });
                } else {
                    const latestQuestions = [3, 2, 1].reduce((result, difficultyLevel) => {
                        const difficultyLevelQuestions = rangeQuestions.filter(q => q.difficultyLevel == difficultyLevel);

                        if (difficultyLevelQuestions.length > 0) {
                            const latestQuestion = difficultyLevelQuestions.reduce((latest, q) => {
                                return !latest || new Date(q.createdAt) > new Date(latest.createdAt) ? q : latest;
                            }, null);

                            result.push(latestQuestion);
                        }

                        return result;
                    }, []);

                    // Update binary map based on each difficultyLevel question that is passed
                    latestQuestions.filter(q => q.passed).forEach(q => {
                        binaryMap[3 - q.difficultyLevel] = 1; // Update the binary position based on difficulty level
                    });
                }

                // all questions is not passed 
                if (binaryMap.every(digit => digit == 0)) {
                    // needs improvements
                    binaryMap[3 - 1] = 1;
                }
            }

            // Convert binary mapping to decimal level
            const level = parseInt(binaryMap.join(''), 2);
            return level
        },
        cognitive: rangeQuestions => {
            let level = 0

            if (rangeQuestions.length > 0) {
                // proccess range questions based on levelType to get level
                if (levelType == 'max') {
                    // get max cognitiveLevel passed question
                    level = Math.max(...rangeQuestions.filter(q => q.passed).map(q => q.cognitiveLevel), 0);
                } else {
                    // levelType == 'current' : get latest passed question
                    const latestQuestion = rangeQuestions.reduce((latest, q) => !latest || new Date(q.createdAt) > new Date(latest.createdAt) ? q : latest, null)
                    level = latestQuestion.passed ? latestQuestion.cognitiveLevel : 0;
                }

                // all questions is not passed 
                if (level == 0) {
                    // needs improvements
                    level = 1;
                }
            }

            return level
        }
    }

    const levels = useMemo(() => p.learningPerformance.map(filterQuestionsByDaysRange).map(levelCalculation[legend]), [legend, levelType, daysRange])

    const rtl = document.dir == 'rtl'
    return (
        <div className={`flex flex-col gap-y-2 w-full transition-all duration-500 ${expand ? 'h-[360px] overflow-auto small-scroll' : 'h-0 overflow-hidden'}`}>
            {/* criteria */}
            <div className="flex flex-wrap justify-end gap-2">
                <Switch
                    checked={legend == 'cognitive'}
                    onChange={() => {
                        setLegend(legend == 'cognitive' ? 'performance' : 'cognitive')
                    }}
                    className={`bg-gray-200 border-gray-300 flex h-[25px] min-w-[200px] items-center rounded-full border shadow`}
                >
                    <span className={`${legend == 'cognitive' ? rtl ? '-translate-x-full' : 'translate-x-full' : 'translate-x-0'} w-1/2 h-full transition-all duration-500 text-gray-50 rounded-full bg-blue-500 hover:bg-blue-400 capitalize flex items-center justify-center text-sm`}>
                        {legend == 'cognitive' ? translate('cognitive') : translate('performance')}
                    </span>
                </Switch>

                <Switch
                    checked={levelType == 'current'}
                    onChange={() => {
                        setLevelType(levelType == 'current' ? 'max' : 'current')
                    }}
                    className={`bg-gray-200 border-gray-300 flex h-[25px] min-w-[200px] items-center rounded-full border shadow`}
                >
                    <span className={`${levelType == 'current' ? rtl ? '-translate-x-full' : 'translate-x-full' : 'translate-x-0'} w-1/2 h-full transition-all duration-500 text-gray-50 rounded-full bg-blue-500 hover:bg-blue-400 capitalize flex items-center justify-center text-sm`}>
                        {levelType == 'current' ? translate('current') : translate('max')}
                    </span>
                </Switch>
            </div>

            <div className={`w-[90%] mx-auto pb-[100px] relative ${expand ? 'visible' : 'invisible'} duration-500`}>
                <Slider
                    range pushable dots
                    min={0} max={daysMarks.length - 1} step={1}
                    onChange={setDaysRange}
                    defaultValue={daysRange}
                />

                {
                    daysMarks.map((mark, idx) => {
                        const positionPercent = (idx / (daysMarks.length - 1)) * 100;

                        return (
                            <span
                                key={idx}
                                className="absolute text-xs text-gray-400 truncate bottom-[40%]"
                                style={{ left: `calc(${positionPercent}% - 50px)`, transform: 'rotate(-80deg)' }}
                            >
                                {mark}
                            </span>
                        )
                    })
                }
            </div>

            <Heatmap
                legend={legend}
                labels={labels}
                levels={levels}
            />
        </div>
    );
}

export default ProgressHeatmap