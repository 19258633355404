"use client"

import { useTranslate } from "@/client/hooks"
import Link from "next/link"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { useParams } from "next/navigation"
import Image from "next/image"
import { useEffect } from "react"

const Success = ({ formData, lastSave, setLastStep }) => {
    const translate = useTranslate()
    const { lang } = useParams()

    useEffect(() => {
        if (!lastSave.course) setLastStep("general")
    }, [])

    return (
        <div className="flex flex-grow flex-col gap-y-4 justify-center items-center">
            <h1 className="table-title">
                {translate(`Congratulations!`)}
            </h1>
            <FaCheckCircle className="w-full h-fit max-w-[250px] mx-auto text-green-600" />

            <div className="text-center text-2xl text-green-600 font-bold">
                {translate(`Your`)} {formData.title} {translate(`course is successfully created!`)}
            </div>

            <div className="flex gap-x-4 items-center">
                <Image width={50} height={100} alt="eve light" src="/eve.webp" />

                <div className="text-gray-500 font-bold text-lg flex flex-col">
                    <span>{translate(`Eve: Greate Job, I will take it from here.`)}</span>
                    <span>{translate(`Eve: I'll build a number of lessons for each module for you, I will let you know when I'm done!`)}</span>
                </div>
            </div>

            <div className="flex flex-wrap gap-x-2 items-center justify-between w-full max-w-md mx-auto">
                <Link href={`/${lang}/dashboard/courses`} className="hover-link">{translate(`Back to Courses`)}</Link>
                <Link href={`/${lang}/dashboard/preview-course/${lastSave.course}`} className="hover-link">{translate(`Preview Course`)}</Link>
                <Link href={`/${lang}/dashboard`} className="hover-link">{translate(`Go to Dashbaord`)}</Link>
            </div>
        </div>
    )
}

export default Success