"use client"

import { useTranslate } from "@/client/hooks"
import { Fragment, useEffect, useMemo, useState } from "react"
import { AxiosRequest, devConsoleLog } from "@/client/utils"
import { BACKEND_ROOT } from "@/constants"
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline"
import { MdDelete } from "@react-icons/all-files/md/MdDelete"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import { Reorder, motion } from "framer-motion"
import { clone, cloneDeep, isEqual } from "lodash"
import { v4 as uuidv4 } from 'uuid';
import DraggableItem from "./DraggableItem"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import EnhanceButton from "./EnhanceButton"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"


const Modules = ({ options, formData, setFormData, setLastStep }) => {
  const translate = useTranslate()

  const [errors, setErrors] = useState({})

  const [expand, setExpand] = useState({})
  const toggleExpand = (moduleTempId) => {
    setExpand(prev => {
      let next = clone(prev)

      next[moduleTempId] = !Boolean(next[moduleTempId])

      return next
    })
  }

  const formFields = useMemo(() => {
    // nested modules fields
    const modulesFields = formData.learningOutcomes.reduce((acc, lo, loIdx) => {
      // parent modules array
      acc[`lo-${loIdx}-modules`] = {
        text: translate(`Learning Outcome`) + ` ${loIdx + 1} ` + translate("Modules"),
        invalid: (value) => !Array.isArray(value) || value.length == 0,
        error: "You have to add 1 module at least!"
      }
      
      lo.modules?.forEach((module, moduleIdx) => {
        // module name
        acc[`lo-${loIdx}-module-${moduleIdx}-name`] = {
          invalid: (value) => !Boolean(value),
          error: "Name field is required"
        }
        // module lessons
        acc[`lo-${loIdx}-module-${moduleIdx}-lessons`] = {
          invalid: (value) => !Array.isArray(value) || value.length == 0,
          error: "You have to add 1 criteria at least!"
        }
        // nested lessons fields
        module.lessons?.forEach((lesson, lessonIdx) => {
          // lesson name
          acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-name`] = {
            invalid: (value) => !Boolean(value),
            error: "Name field is required"
          }
          // lesson learningOutcomes
          acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] = {
            invalid: (value) => !Array.isArray(value) || value.length == 0,
            error: "You have to add 1 learning outcome at least!"
          }
          // nested learning outcomes fields
          lesson.learningOutcomes?.forEach((outcome, outcomeIdx) => {
            // lo string
            acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`] = {
              invalid: (value) => !Boolean(value),
              error: "This field is required"
            }
          })
          // // lesson content
          // acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-content`] = {
          //   invalid: (value) => !Boolean(value),
          //   error: "Content field is required"
          // }
          // // lesson sampleQuestions
          // acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] = {
          //   invalid: (value) => !Array.isArray(value) || value.length == 0,
          //   error: "You have to add 1 sample questions at least!"
          // }
          // // nested sample questions fields
          // lesson.sampleQuestions?.forEach((sq, sqIdx) => {
          //   // type field
          //   acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${sqIdx}-type`] = {
          //     invalid: (value) => !Boolean(value),
          //     error: "Type field is required"
          //   }
          //   // questions fields
          //   acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${sqIdx}-questions`] = {
          //     invalid: (value) => !Boolean(value),
          //     error: "Questions field is required"
          //   }
          // })
          // // lesson otherInfo (optional)
          // acc[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`] = {
          //   invalid: (value) => Boolean(value) && value.length < 20,
          //   error: "Must be 20 character at least!"
          // }
        })
      })

      return acc
    }, {})

    return modulesFields
  }, [formData])

  const validateField = (fieldName, fieldValue) => {
    let updatedErrors = { ...errors }
    delete updatedErrors[fieldName]

    const isInvalid = formFields[fieldName].invalid(fieldValue)

    if (isInvalid) {
      updatedErrors[fieldName] = formFields[fieldName].error
    }

    setErrors(updatedErrors)

    return isInvalid
  }

  const handleModuleChange = (e, loIdx, moduleIdx) => {
    const fieldId = e.target.id
    const fieldName = fieldId.split(`lo-${loIdx}-module-${moduleIdx}-`)[1]
    const fieldValue = e.target.value

    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx][fieldName] = fieldValue
    setFormData(updatedFormData)

    validateField(fieldId, fieldValue)
  }

  const handleLessonChange = (e, loIdx, moduleIdx, lessonIdx) => {
    const fieldId = e.target.id
    const fieldName = fieldId.split(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-`)[1]
    const fieldValue = e.target.value

    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx][fieldName] = fieldValue
    setFormData(updatedFormData)

    validateField(fieldId, fieldValue)
  }

  const addNewModule = (loIdx) => {
    let updatedErrors = { ...errors }
    delete updatedErrors[`lo-${loIdx}-modules`]

    let updatedFormData = { ...formData }

    const newModule = { tempId: uuidv4(), name: "", lessons: [] }

    if (updatedFormData.learningOutcomes[loIdx].hasOwnProperty('modules')) {
      updatedFormData.learningOutcomes[loIdx].modules.push(newModule)
    } else {
      updatedFormData.learningOutcomes[loIdx].modules = [newModule]
    }

    setFormData(updatedFormData)

    const moduleIdx = updatedFormData.learningOutcomes[loIdx].modules.length - 1
    updatedErrors[`lo-${loIdx}-module-${moduleIdx}-name`] = "Name field is required"
    updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lessons`] = "You have to add 1 lesson at least!"
    setErrors(updatedErrors)
  }

  const addNewLesson = (loIdx, moduleIdx) => {
    let updatedErrors = { ...errors }
    delete updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lessons`]

    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons.push({
      tempId: uuidv4(),
      name: "",
      learningOutcomes: [],
      // content: "",
      // sampleQuestions: [],
      // otherInfo: ""
    })
    setFormData(updatedFormData)

    const lessonIdx = updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons.length - 1
    updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-name`] = "Name field is required"
    updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] = "You have to add 1 learning outcome at least!"
    // updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-content`] = "Content field is required"
    // updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] = "You have to add 1 sample questions at least!"
    setErrors(updatedErrors)
  }

  const deleteModule = (loIdx, moduleIdx) => {
    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules.splice(moduleIdx, 1)
    setFormData(updatedFormData)

    let updatedErrors = { ...errors }
    Object.keys(updatedErrors).forEach(key => {
      if (key.includes(`lo-${loIdx}-module-${moduleIdx}`)) {
        delete updatedErrors[key]
      } else if (key.includes(`lo-${loIdx}-module-`)) {
        let keyTokens = key.split(`lo-${loIdx}-module-`)[1].split('-')
        let kModuleIdx = +keyTokens[0]
        let rest = keyTokens.slice(1).join('-')

        if (kModuleIdx > moduleIdx) {
          updatedErrors[`lo-${loIdx}-module-${kModuleIdx - 1}-${rest}`] = updatedErrors[key]
          delete updatedErrors[key]
        }
      }
    })

    if (updatedFormData.learningOutcomes[loIdx].modules.length == 0) {
      updatedErrors[`lo-${loIdx}-modules`] = "You have to add 1 module at least!"
    }
    setErrors(updatedErrors)
  }

  const deleteLesson = (loIdx, moduleIdx, lessonIdx) => {
    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons.splice(lessonIdx, 1)
    setFormData(updatedFormData)

    let updatedErrors = { ...errors }
    Object.keys(updatedErrors).forEach(key => {
      if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}`)) {
        delete updatedErrors[key]
      } else if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-`)) {
        let keyTokens = key.split(`lo-${loIdx}-module-${moduleIdx}-lesson-`)[1].split('-')
        let kLessonIdx = +keyTokens[0]
        let rest = keyTokens.slice(1).join('-')

        if (kLessonIdx > lessonIdx) {
          updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${kLessonIdx - 1}-${rest}`] = updatedErrors[key]
          delete updatedErrors[key]
        }
      }
    })

    if (updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons.length == 0) {
      updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lessons`] = "You have to add 1 lesson at least!"
    }
    setErrors(updatedErrors)
  }

  const handleLessonLOFieldChange = (e, loIdx, moduleIdx, lessonIdx, outcomeIdx) => {
    const fieldId = e.target.id
    const fieldValue = e.target.value

    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].learningOutcomes[outcomeIdx] = fieldValue
    setFormData(updatedFormData)

    validateField(fieldId, fieldValue)
  }

  const addNewLessonLO = (loIdx, moduleIdx, lessonIdx) => {
    let updatedErrors = { ...errors }
    delete updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`]

    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].learningOutcomes.push("")
    setFormData(updatedFormData)

    const outcomeIdx = updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].learningOutcomes.length - 1
    updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`] = "This field is required"
    setErrors(updatedErrors)
  }

  const deleteLessonLO = (loIdx, moduleIdx, lessonIdx, outcomeIdx) => {
    let updatedFormData = { ...formData }
    updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].learningOutcomes.splice(outcomeIdx, 1)
    setFormData(updatedFormData)

    let updatedErrors = { ...errors }
    Object.keys(updatedErrors).forEach(key => {
      if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`)) {
        delete updatedErrors[key]
      } else if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-`)) {
        let keyTokens = key.split(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-`)[1].split('-')
        let kOutcomeIdx = +keyTokens[0]
        let rest = keyTokens.slice(1).join('-')

        if (kOutcomeIdx > outcomeIdx) {
          updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${kOutcomeIdx - 1}-${rest}`] = updatedErrors[key]
          delete updatedErrors[key]
        }
      }
    })

    if (updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].learningOutcomes.length == 0) {
      updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] = "You have to add 1 learning outcome at least!"
    }
    setErrors(updatedErrors)
  }

  // hidden callbacks
  // const handleSampleQuestionsChange = (e, loIdx, moduleIdx, lessonIdx, questionsIdx) => {
  //   const fieldId = e.target.id
  //   const fieldName = fieldId.split(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-`)[1]
  //   const fieldValue = e.target.value

  //   let updatedFormData = { ...formData }
  //   updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].sampleQuestions[questionsIdx][fieldName] = fieldValue
  //   setFormData(updatedFormData)

  //   validateField(fieldId, fieldValue)
  // }

  // const addNewSampleQuestions = (loIdx, moduleIdx, lessonIdx) => {
  //   let updatedErrors = { ...errors }
  //   delete updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`]

  //   let updatedFormData = { ...formData }
  //   updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].sampleQuestions.push({ type: "", questions: "" })
  //   setFormData(updatedFormData)

  //   const sampleQuestionsIdx = updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].sampleQuestions.length - 1
  //   updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${sampleQuestionsIdx}-type`] = "type field is required"
  //   updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${sampleQuestionsIdx}-questions`] = "questions field is required"
  //   setErrors(updatedErrors)
  // }

  // const deleteSampleQuestions = (loIdx, moduleIdx, lessonIdx, questionsIdx) => {
  //   let updatedFormData = { ...formData }
  //   updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].sampleQuestions.splice(questionsIdx, 1)
  //   setFormData(updatedFormData)

  //   let updatedErrors = { ...errors }
  //   Object.keys(updatedErrors).forEach(key => {
  //     if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}`)) {
  //       delete updatedErrors[key]
  //     } else if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-`)) {
  //       let keyTokens = key.split(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-`)[1].split('-')
  //       let kQuestionsIdx = +keyTokens[0]
  //       let rest = keyTokens.slice(1).join('-')

  //       if (kQuestionsIdx > questionsIdx) {
  //         updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${kQuestionsIdx - 1}-${rest}`] = updatedErrors[key]
  //         delete updatedErrors[key]
  //       }
  //     }
  //   })

  //   if (updatedFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].sampleQuestions.length == 0) {
  //     updatedErrors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] = "You have to add 1 sample questions at least!"
  //   }
  //   setErrors(updatedErrors)
  // }

  const handleReorderModules = (orderedModules, loIdx) => {
    // Re-map errors to match the new order
    const reorderedErrors = {}

    orderedModules.forEach((module, newIndex) => {
      const oldIndex = formData.learningOutcomes[loIdx].modules.findIndex(m => isEqual(m, module));

      // Update all error keys related to the current module
      Object.keys(errors).forEach((key) => {
        if (key.includes(`lo-${loIdx}-module-${oldIndex}`)) {
          const updatedKey = key.replace(`lo-${loIdx}-module-${oldIndex}`, `lo-${loIdx}-module-${newIndex}`);
          reorderedErrors[updatedKey] = errors[key];
        }
      })
    })

    setFormData(oldFormData => {
      let newFormData = clone(oldFormData)

      newFormData.learningOutcomes[loIdx].modules = orderedModules

      return newFormData
    })

    setErrors(reorderedErrors);
  }

  const handleEnhanceModule = async (userPrompt, loIdx, moduleIdx) => {
    const generalCourseInfo = {
      type: formData.type,
      title: formData.title,
      keywords: formData.keywords,
      description: formData.description || "",
      audiences: formData.audiences.map(aud => {
        return {
          category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
          intendedLearners: aud.intendedLearners
        }
      }),
      gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
      educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
    }

    const reqPromise = await AxiosRequest.post(`${BACKEND_ROOT}/ai/enhanceModule`, {
      generalCourseInfo,
      moduleLearningOutcome: formData.learningOutcomes[loIdx],
      givenModule: formData.learningOutcomes[loIdx].modules[moduleIdx],
      userPrompt,
    }).then(res => {
      const enhancedModule = res.data

      setFormData(prevFormData => {
        let newFormData = clone(prevFormData)

        newFormData.learningOutcomes[loIdx].modules[moduleIdx] = enhancedModule

        return newFormData
      })

      setErrors(prevErrors => {
        let newErrors = clone(prevErrors)

        delete newErrors[`lo-${loIdx}-modules`]

        Object.keys(newErrors).forEach(key => {
          if (key.includes(`lo-${loIdx}-module-${moduleIdx}`)) {
            delete newErrors[key]
          }
        })

        return newErrors
      })
    })

    return reqPromise
  }

  const handleReorderLessons = (orderedLessons, loIdx, moduleIdx) => {
    // Re-map errors to match the new order
    let reorderedErrors = {}

    orderedLessons.forEach((lesson, newIndex) => {
      const oldIndex = formData.learningOutcomes[loIdx].modules[moduleIdx].lessons.findIndex(l => isEqual(l, lesson));

      // Update all error keys related to the current lesson
      Object.keys(errors).forEach((key) => {
        if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${oldIndex}`)) {
          const updatedKey = key.replace(`lesson-${oldIndex}`, `lesson-${newIndex}`);
          reorderedErrors[updatedKey] = errors[key];
        }
      })
    })

    let newFormData = clone(formData)
    newFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons = orderedLessons

    setFormData(newFormData)
    setErrors(reorderedErrors)
  }

  const handleEnhanceLesson = async (userPrompt, loIdx, moduleIdx, lessonIdx) => {
    const givenLesson = formData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx]

    const generalCourseInfo = {
      type: formData.type,
      title: formData.title,
      keywords: formData.keywords,
      description: formData.description || "",
      audiences: formData.audiences.map(aud => {
        return {
          category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
          intendedLearners: aud.intendedLearners
        }
      }),
      gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
      educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
    }

    const reqPromise = await AxiosRequest.post(`${BACKEND_ROOT}/ai/enhanceLesson`, {
      generalCourseInfo,
      moduleLearningOutcome: formData.learningOutcomes[loIdx],
      givenLesson,
      otherLessonsInSameModule: formData.learningOutcomes[loIdx].modules[moduleIdx].lessons.filter(l => l.tempId != givenLesson.tempId),
      userPrompt,
    }).then(res => {
      const enhancedLesson = res.data

      setFormData(prevFormData => {
        let newFormData = clone(prevFormData)

        newFormData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx] = enhancedLesson

        return newFormData
      })

      setErrors(prevErrors => {
        let newErrors = clone(prevErrors)

        delete newErrors[`lo-${loIdx}-module-${moduleIdx}-lessons`]

        Object.keys(newErrors).forEach(key => {
          if (key.includes(`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}`)) {
            delete newErrors[key]
          }
        })

        return newErrors
      })
    })

    return reqPromise
  }

  const handleSubmit = e => {
    e.preventDefault()

    // check for invalidFields required fields
    let invalidFields = {};
    for (let key of Object.keys(formFields)) {
      const { invalid, error } = formFields[key]

      // lo fields
      if (key.startsWith('lo-')) {
        let loKeyTokens = key.split('lo-')[1].split('-')
        let loIdx = +loKeyTokens[0]
        let loKey = loKeyTokens.slice(1).join('-')

        // module fields
        if (loKey.startsWith('module-')) {
          let loKeyTokens = key.split('module-')[1].split('-')
          let moduleIdx = +loKeyTokens[0]
          let moduleKey = loKeyTokens.slice(1).join('-')

          // lesson fields
          if (moduleKey.startsWith('lesson-')) {
            let moduleKeyTokens = key.split('lesson-')[1].split('-')
            let lessonIdx = +moduleKeyTokens[0]
            let lessonKey = moduleKeyTokens.slice(1).join('-')

            // los fields
            if (lessonKey.startsWith('learningOutcome-')) {
              let lessonKeyTokens = key.split('learningOutcome-')[1].split('-')
              let outcomeIdx = +lessonKeyTokens[0]

              if (invalid(formData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].learningOutcomes[outcomeIdx])) {
                invalidFields[key] = error
              }
            } else if (lessonKey.startsWith('sampleQuestions-')) {  // sample questions fields
              let lessonKeyTokens = key.split('sampleQuestions-')[1].split('-')
              let sqIdx = +lessonKeyTokens[0]
              let sqKey = lessonKeyTokens.slice(1).join('-')

              if (invalid(formData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx].sampleQuestions[sqIdx][sqKey])) {
                invalidFields[key] = error
              }
            } else {
              if (invalid(formData.learningOutcomes[loIdx].modules[moduleIdx].lessons[lessonIdx][lessonKey])) {
                invalidFields[key] = error
              }
            }
          } else {
            if (invalid(formData.learningOutcomes[loIdx].modules[moduleIdx][moduleKey])) {
              invalidFields[key] = error
            }
          }
        } else {
          if (invalid(formData.learningOutcomes[loIdx][loKey])) {
            invalidFields[key] = error
          }
        }
      } else {
        if (invalid(formData[key])) {
          invalidFields[key] = error
        }
      }
    }
    if (Object.keys(invalidFields).length != 0) return setErrors(invalidFields);

    setLastStep(`review`)
  }

  useEffect(() => {
    if (formData.learningOutcomes.every(lo => !Boolean(lo.modules) || lo?.modules?.length == 0)) {
      const generalCourseInfo = {
        type: formData.type,
        title: formData.title,
        keywords: formData.keywords,
        description: formData.description || "intendedLearners",
        audiences: formData.audiences.map(aud => {
          return {
            category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
            intendedLearners: aud.intendedLearners
          }
        }),
        gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
        educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
      }

      const courseLearningOutcomes = cloneDeep(formData.learningOutcomes)
      courseLearningOutcomes.forEach(lo => {
        delete lo.modules
      })

      const controller = new AbortController();

      AxiosRequest.post(`${BACKEND_ROOT}/ai/generateModules`, {
        generalCourseInfo,
        courseLearningOutcomes
      }, {
        signal: controller.signal,
      }).then(res => {
        const generatedModules = res.data.modules

        let updatedFormData = clone(formData)
        let updatedErrors = clone(errors)

        updatedFormData.learningOutcomes.forEach((lo, loIdx) => {
          updatedFormData.learningOutcomes[loIdx].modules = generatedModules.find(lom => lom.learningOutcomeId == lo.tempId).modules

          delete updatedErrors[`lo-${loIdx}-modules`]

          Object.keys(updatedErrors).forEach(key => {
            if (key.includes(`lo-${loIdx}-module-`)) {
              delete updatedErrors[key]
            }
          })

        })

        setFormData(updatedFormData)
        setErrors(updatedErrors)
      }).catch(err => {
        devConsoleLog(`Failed to generate modules: ${err.message}`)
      })

      return () => {
        controller.abort();
      }
    }

  }, [formData.learningOutcomes.every(lo => !Boolean(lo.modules) || lo?.modules?.length == 0)])


  const regenerateModules = async (userPrompt) => {
    const generalCourseInfo = {
      type: formData.type,
      title: formData.title,
      keywords: formData.keywords,
      description: formData.description || "intendedLearners",
      audiences: formData.audiences.map(aud => {
        return {
          category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
          intendedLearners: aud.intendedLearners
        }
      }),
      gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
      educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
    }

    const courseLearningOutcomes = cloneDeep(formData.learningOutcomes)
    const currentModules = courseLearningOutcomes.map((lo, loIdx) => {
      const loModule = {
        learningOutcomeId: lo.tempId,
        modules: lo.modules
      }

      delete courseLearningOutcomes[loIdx].modules

      return loModule
    })

    const reqPromise = await AxiosRequest.post(`${BACKEND_ROOT}/ai/generateModules`, {
      generalCourseInfo,
      courseLearningOutcomes,
      currentModules,
      userPrompt,
    }).then(res => {
      const generatedModules = res.data.modules

      let updatedFormData = clone(formData)
      let updatedErrors = clone(errors)

      updatedFormData.learningOutcomes.forEach((lo, loIdx) => {
        updatedFormData.learningOutcomes[loIdx].modules = generatedModules.find(lom => lom.learningOutcomeId == lo.tempId).modules

        delete updatedErrors[`lo-${loIdx}-modules`]

        Object.keys(updatedErrors).forEach(key => {
          if (key.includes(`lo-${loIdx}-module-`)) {
            delete updatedErrors[key]
          }
        })

      })

      setFormData(updatedFormData)
      setErrors(updatedErrors)
    })

    return reqPromise
  }

  const enhanceLOModules = async (userPrompt, loIdx) => {
    const generalCourseInfo = {
      type: formData.type,
      title: formData.title,
      keywords: formData.keywords,
      description: formData.description || "",
      audiences: formData.audiences.map(aud => {
        return {
          category: options?.audienceCategories?.find(catOp => catOp.value == aud.category)?.label,
          intendedLearners: aud.intendedLearners
        }
      }),
      gradeLevel: options?.gradeLevels?.find(gradeOp => gradeOp.value == formData.gradeLevel)?.label,
      educationalSystem: options?.educationalSystems?.find(gradeOp => gradeOp.value == formData.educationalSystem)?.label,
    }

    const learningOutcome = cloneDeep(formData.learningOutcomes[loIdx])
    const learningOutcomeCurrentModules = learningOutcome.modules
    delete learningOutcome.modules

    const reqPromise = await AxiosRequest.post(`${BACKEND_ROOT}/ai/enhanceLOModules`, {
      generalCourseInfo,
      learningOutcome,
      learningOutcomeCurrentModules,
      userPrompt,
    }).then(res => {
      const generatedLearningOutcomeModules = res.data.learningOutcomeModules

      let updatedFormData = clone(formData)
      let updatedErrors = clone(errors)

      updatedFormData.learningOutcomes[loIdx].modules = generatedLearningOutcomeModules

      delete updatedErrors[`lo-${loIdx}-modules`]
      
      Object.keys(updatedErrors).forEach(key => {
        if (key.includes(`lo-${loIdx}-module-`)) {
          delete updatedErrors[key]
        }
      })

      setFormData(updatedFormData)
      setErrors(updatedErrors)
    })

    return reqPromise
  }
  
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-y-4 flex-grow">
      {/* learning outcomes modules */}
      <div className="flex items-center justify-between gap-x-4 relative">
        <h1 className="text-3xl text-blue-500 font-bold">{translate("Course Modules")}</h1>
        <EnhanceButton show handleEnhance={regenerateModules} />
      </div>

      {
        formData.learningOutcomes.every(lo => !Boolean(lo.modules) || lo?.modules?.length == 0) ?
          <div className="flex-grow flex flex-col gap-y-4 items-center justify-center">
            <AiOutlineLoading3Quarters className="animate-spin text-blue-500 text-7xl" />
            <h2 className="text-gray-500 italic font-semibold">{translate("Please, Wait till your course modules is generated")}!</h2>
          </div>
          : formData.learningOutcomes.map((lo, loIdx) => (
            <div key={lo.tempId} className="flex flex-col flex-grow gap-y-2">
              {/* lo modules header */}
              <div className="flex items-center justify-between gap-x-4 relative">
                <h1 className="text-xl font-bold">{formFields[`lo-${loIdx}-modules`].text}</h1>
                <EnhanceButton show handleEnhance={(userPrompt) => enhanceLOModules(userPrompt, loIdx)} />
              </div>

              <h2 className="font-semibold text-lg text-gray-500 flex flex-col">
                <span className="font-bold italic">{translate("Learning Outcome")} {loIdx + 1}:</span>
                <span>{lo.statement}</span>
              </h2>

              <Reorder.Group as={Fragment} axis="y" values={lo.modules || []} onReorder={(orderedModules) => {
                handleReorderModules(orderedModules, loIdx)
              }}>
                {
                  lo.modules?.map((module, moduleIdx) => (
                    <DraggableItem key={module.tempId} value={module}>
                      <motion.div
                        initial={{ height: expand[module.tempId] ? "auto" : 50 }}
                        animate={{ height: expand[module.tempId] ? "auto" : 50 }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        className="w-full flex flex-col gap-y-2 rounded border-dashed border border-gray-400 bg-gray-100 p-2 overflow-hidden"
                      >
                        {/* module header */}
                        <div className="flex justify-between items-center relative">
                          <button type="button" className="flex items-center gap-x-1 truncate" onClick={() => toggleExpand(module.tempId)}>
                            <h2 className="font-bold text-lg truncate">{translate("Module")} {moduleIdx + 1}: {module.name || translate("No Name")}</h2>
                            <MdExpandMore className={`w-[26px] h-[26px] ${expand[module.tempId] ? "-rotate-180" : "rotate-0"} duration-500 transition-all text-gray-500 hover:bg-gray-200 active:bg-gray-200 rounded-full`} />
                          </button>

                          <div className="flex items-center">
                            {/* magic button */}
                            <EnhanceButton
                              handleEnhance={userPrompt => handleEnhanceModule(userPrompt, loIdx, moduleIdx)}
                              show={expand[module.tempId]}
                            />

                            <button type="button" className="danger-icon-btn p-2" onClick={() => deleteModule(loIdx, moduleIdx)}>
                              <MdDelete size={18} />
                            </button>
                          </div>
                        </div>

                        {/* module name */}
                        <div className="flex flex-col w-full">
                          <label htmlFor={`module-${moduleIdx}-name`} className="text-start">{translate("Name")}<span className="text-red-500 text-sm"> *</span></label>
                          <input
                            id={`lo-${loIdx}-module-${moduleIdx}-name`}
                            type="text"
                            className="input-bar"
                            placeholder={translate("Add module name")}
                            value={module.name || ""}
                            onChange={(e) => handleModuleChange(e, loIdx, moduleIdx)}
                          />
                          {
                            errors[`lo-${loIdx}-module-${moduleIdx}-name`] &&
                            <small className='text-red-700 flex items-center gap-1'>
                              <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-name`])}
                            </small>
                          }
                        </div>

                        {/* lessons */}
                        <div className="flex flex-col gap-y-1">
                          <h2 className="text-start font-bold text-lg">{translate("Lessons")}<span className="text-red-500 text-sm"> *</span></h2>

                          <Reorder.Group as={Fragment} axis="y" values={module.lessons} onReorder={orderedLessons => {
                            handleReorderLessons(orderedLessons, loIdx, moduleIdx)
                          }}>
                            {
                              module.lessons.map((lesson, lessonIdx) => (
                                <DraggableItem key={lesson.tempId} value={lesson}>
                                  <motion.div
                                    initial={{ height: expand[lesson.tempId] ? "auto" : 50 }}
                                    animate={{ height: expand[lesson.tempId] ? "auto" : 50 }}
                                    transition={{ duration: 0.5, ease: "easeInOut" }}
                                    className="flex flex-col w-full p-2 rounded border border-dashed bg-gray-50 border-gray-400 gap-y-1 text-sm overflow-hidden"
                                  >
                                    {/* lesson header */}
                                    <div className="flex justify-between items-center relative">
                                      <button type="button" className="flex items-center gap-x-1 truncate" onClick={() => toggleExpand(lesson.tempId)}>
                                        <h3 className="text-md font-bold text-base truncate">{translate("Lesson")} {lessonIdx + 1}: {lesson.name || translate("No Name")}</h3>
                                        <MdExpandMore className={`w-[26px] h-[26px] ${expand[lesson.tempId] ? "-rotate-180" : "rotate-0"} duration-500 transition-all text-gray-500 hover:bg-gray-200 active:bg-gray-200 rounded-full`} />
                                      </button>

                                      <div className="flex items-center">
                                        {/* magic button */}
                                        <EnhanceButton
                                          handleEnhance={userPrompt => handleEnhanceLesson(userPrompt, loIdx, moduleIdx, lessonIdx)}
                                          show={expand[lesson.tempId]}
                                        />

                                        <button type="button" className="danger-icon-btn p-2" onClick={() => deleteLesson(loIdx, moduleIdx, lessonIdx)}>
                                          <MdDelete size={18} />
                                        </button>
                                      </div>
                                    </div>

                                    {/* lesson name */}
                                    <div className="flex flex-col w-full">
                                      <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-name`} className="text-start">{translate("Name")}<span className="text-red-500 text-sm"> *</span></label>
                                      <input
                                        id={`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-name`}
                                        type="text"
                                        className="input-bar"
                                        placeholder={translate("Add lesson name")}
                                        value={lesson.name || ""}
                                        onChange={(e) => handleLessonChange(e, loIdx, moduleIdx, lessonIdx)}
                                      />
                                      {
                                        errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-name`] &&
                                        <small className='text-red-700 flex items-center gap-1'>
                                          <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-name`])}
                                        </small>
                                      }
                                    </div>

                                    {/* lesson learning outcomes */}
                                    <div className="flex flex-col gap-y-1">
                                      <label className="text-start text-base font-bold">{translate("Learning Outcomes")}<span className="text-red-500 text-sm"> *</span></label>
                                      {
                                        lesson.learningOutcomes.map((outcome, outcomeIdx) => (
                                          <div key={outcomeIdx} className="flex flex-col w-full">
                                            <div key={outcomeIdx} className="relative">
                                              <textarea
                                                id={`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`}
                                                className="input-bar min-h-fit"
                                                placeholder={translate("Add learning outcome")}
                                                value={outcome || ""}
                                                onChange={(e) => handleLessonLOFieldChange(e, loIdx, moduleIdx, lessonIdx, outcomeIdx)}
                                              />

                                              <button type="button" className="danger-icon-btn absolute top-2 right-1" onClick={() => deleteLessonLO(loIdx, moduleIdx, lessonIdx, outcomeIdx)}>
                                                <MdDelete size={18} />
                                              </button>
                                            </div>

                                            {
                                              errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`] &&
                                              <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcome-${outcomeIdx}`])}
                                              </small>
                                            }
                                          </div>
                                        ))
                                      }
                                      <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewLessonLO(loIdx, moduleIdx, lessonIdx)}>
                                        <FaPlus /> {translate("Add Learning Outcome")}
                                      </button>
                                      {
                                        errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`] &&
                                        <small className='text-red-700 flex items-center gap-1'>
                                          <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-learningOutcomes`])}
                                        </small>
                                      }
                                    </div>

                                    {/* hidden fields */}
                                    {
                                      // {/* lesson content */}
                                      // <div className="flex flex-col w-full">
                                      //   <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-content`} className="text-start">{translate("Content")}<span className="text-red-500 text-sm"> *</span></label>

                                      //   <div className="flex gap-x-2">
                                      //     <textarea
                                      //       id={`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-content`}
                                      //       className="input-bar min-h-[100px] small-scroll"
                                      //       placeholder={translate("Add lesson content")}
                                      //       value={lesson.content || ""}
                                      //       onChange={(e) => handleLessonChange(e, loIdx, moduleIdx, lessonIdx)}
                                      //     />

                                      //     <div className="w-1 bg-gray-300 rounded-full" />

                                      //     <ShadowDOM.div className="text-lg font-semibold -my-2 w-full">
                                      //       <Markdown>
                                      //         {lesson.content}
                                      //       </Markdown>
                                      //     </ShadowDOM.div>
                                      //   </div>

                                      //   {
                                      //     errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-content`] &&
                                      //     <small className='text-red-700 flex items-center gap-1'>
                                      //       <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-content`])}
                                      //     </small>
                                      //   }
                                      // </div>

                                      // {/* lesson sample questions */}
                                      // <div className="flex flex-col gap-y-1">
                                      //   <label className="text-start text-base font-bold">{translate("Sample Questions")}<span className="text-red-500 text-sm"> *</span></label>
                                      //   {
                                      //     lesson.sampleQuestions.map(({ type, questions }, questionsIdx) => (
                                      //       <div key={questionsIdx} className="flex flex-col w-full p-2 rounded border border-dashed bg-gray-100 border-gray-400 gap-y-1 text-sm">
                                      //         {/* sample questions header */}
                                      //         <div className="flex justify-between items-center">
                                      //           <label className="text-md font-bold">{translate("Sample Questions")} {questionsIdx + 1}</label>
                                      //           <button type="button" className="danger-icon-btn" onClick={() => deleteSampleQuestions(loIdx, moduleIdx, lessonIdx, questionsIdx)}>
                                      //             <MdDelete size={18} />
                                      //           </button>
                                      //         </div>

                                      //         {/* sample questions type & questions */}
                                      //         <div className="flex flex-col gap-y-1">
                                      //           {/* questions type */}
                                      //           <div className="flex flex-col w-full">
                                      //             <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`} className="text-start">{translate("Type")}<span className="text-red-500 text-sm"> *</span></label>
                                      //             <input
                                      //               id={`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`}
                                      //               type="text"
                                      //               className="input-bar"
                                      //               placeholder={translate("Add question type")}
                                      //               value={type || ""}
                                      //               onChange={(e) => handleSampleQuestionsChange(e, loIdx, moduleIdx, lessonIdx, questionsIdx)}
                                      //             />
                                      //             {
                                      //               errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`] &&
                                      //               <small className='text-red-700 flex items-center gap-1'>
                                      //                 <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-type`])}
                                      //               </small>
                                      //             }
                                      //           </div>

                                      //           {/* actual questions */}
                                      //           <div className="flex flex-col w-full">
                                      //             <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`} className="text-start">{translate("Questions")}<span className="text-red-500 text-sm"> *</span></label>

                                      //             <div className="flex gap-x-2">
                                      //               <textarea
                                      //                 id={`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`}
                                      //                 className="input-bar min-h-[100px] small-scroll"
                                      //                 placeholder={translate("Add questions")}
                                      //                 value={questions || ""}
                                      //                 onChange={(e) => handleSampleQuestionsChange(e, loIdx, moduleIdx, lessonIdx, questionsIdx)}
                                      //               />

                                      //               <div className="w-1 bg-gray-300 rounded-full" />

                                      //               <ShadowDOM.div className="text-lg font-semibold -my-2 w-full">
                                      //                 <Markdown>
                                      //                   {questions}
                                      //                 </Markdown>
                                      //               </ShadowDOM.div>
                                      //             </div>

                                      //             {
                                      //               errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`] &&
                                      //               <small className='text-red-700 flex items-center gap-1'>
                                      //                 <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions-${questionsIdx}-questions`])}
                                      //               </small>
                                      //             }
                                      //           </div>
                                      //         </div>
                                      //       </div>
                                      //     ))
                                      //   }
                                      //   <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewSampleQuestions(loIdx, moduleIdx, lessonIdx)}>
                                      //     <FaPlus /> {translate("Add Sample Questions")}
                                      //   </button>
                                      //   {
                                      //     errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`] &&
                                      //     <small className='text-red-700 flex items-center gap-1'>
                                      //       <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-sampleQuestions`])}
                                      //     </small>
                                      //   }
                                      // </div>

                                      // {/* lesson other info */}
                                      // <div className="flex flex-col w-full">
                                      //   <label htmlFor={`module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`} className="text-start">{translate("Other Info")}<span className="text-gray-500 text-sm italic capitalize"> ({translate("optional")})</span></label>

                                      //   <div className="flex gap-x-2">
                                      //     <textarea
                                      //       id={`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`}
                                      //       className="input-bar min-h-[100px] small-scroll"
                                      //       placeholder={translate("Add other info")}
                                      //       value={lesson.otherInfo || ""}
                                      //       onChange={(e) => handleLessonChange(e, loIdx, moduleIdx, lessonIdx)}
                                      //     />

                                      //     <div className="w-1 bg-gray-300 rounded-full" />

                                      //     <ShadowDOM.div className="text-lg font-semibold -my-2 w-full">
                                      //       <Markdown>
                                      //         {lesson.otherInfo}
                                      //       </Markdown>
                                      //     </ShadowDOM.div>
                                      //   </div>

                                      //   {
                                      //     errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`] &&
                                      //     <small className='text-red-700 flex items-center gap-1'>
                                      //       <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lesson-${lessonIdx}-otherInfo`])}
                                      //     </small>
                                      //   }
                                      // </div>
                                    }
                                  </motion.div>
                                </DraggableItem>
                              ))
                            }
                          </Reorder.Group>

                          <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => addNewLesson(loIdx, moduleIdx)}>
                            <FaPlus /> {translate("Add Lesson")}
                          </button>
                          {
                            errors[`lo-${loIdx}-module-${moduleIdx}-lessons`] &&
                            <small className='text-red-700 flex items-center gap-1'>
                              <MdErrorOutline /> {translate(errors[`lo-${loIdx}-module-${moduleIdx}-lessons`])}
                            </small>
                          }
                        </div>
                      </motion.div>
                    </DraggableItem>
                  ))
                }
              </Reorder.Group>

              <button
                type="button"
                className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors"
                onClick={() => {
                  addNewModule(loIdx)
                }}
              >
                <FaPlus /> {translate("Add Module")}
              </button>

              {
                errors[`lo-${loIdx}-modules`] &&
                <small className='text-red-700 flex items-center gap-1'>
                  <MdErrorOutline /> {translate(errors[`lo-${loIdx}-modules`])}
                </small>
              }
            </div>
          ))
      }

      {/* navigation buttons */}
      <div className='flex items-center justify-between gap-x-4'>
        <button type="button" className='submit-btn-outline' onClick={() => {
          setLastStep(`outcomes`)
        }}>
          <FaArrowLeft />
          <span className='max-md:hidden'>{translate(`Back to Outcomes`)}</span>
        </button>

        <button type="submit" className='submit-btn' disabled={Object.values(errors).some(Boolean)}>
          <span className='max-md:hidden'>{translate("Forward to Review")}</span>
          <FaArrowRight />
        </button>
      </div>
    </form>
  )
}

export default Modules