"use client"

import { BACKEND_ROOT } from "@/constants"
import { AxiosRequest , devConsoleLog } from "@/client/utils"
import { Fragment, useState } from "react"
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";

const ShowMoreCoursesButton = ({ children, initial }) => {
    const [courses, setCourses] = useState(initial)
    const [showMore, setShowMore] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)

    const onShowMoreClick = async () => {
        const query = { educationalSystem: id }
        query['startIndex'] = courses.length

        setMoreLoading(true)
        setShowMore(false)

        await AxiosRequest.get(`${BACKEND_ROOT}/course/filterCourses/academic`, { params: query })
            .then(res => {
                setCourses([...courses, ...res.data])
                if (res.data.length >= 10) setShowMore(true)
            }).catch(err => {
                devConsoleLog(err.message || err.code)
            })
        setMoreLoading(false)
    }

    return (
        <Fragment>
            {moreLoading && <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-1/6 max-w-[100px] mx-auto h-fit" />}

            {showMore && (
                <div className='flex justify-center'>
                    <button
                        onClick={onShowMoreClick}
                        className='hover-link font-semibold'
                    >
                        {children}
                    </button>
                </div>
            )}
        </Fragment>
    )
}

export default ShowMoreCoursesButton