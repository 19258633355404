"use client"

import { FaGripVertical } from "@react-icons/all-files/fa/FaGripVertical";
import { Reorder, useDragControls } from "framer-motion";

const DraggableItem = ({ children, value }) => {
    const controls = useDragControls()
    const handleDrag = (e) => controls.start(e)

    return (
        <Reorder.Item
            value={value}
            as="div" className="flex gap-x-1"
            dragListener={false}
            dragControls={controls}
        >
            <button type="button" className="w-[26px] h-[26px] hover:cursor-grab active:cursor-grabbing text-gray-500 hover:bg-gray-200 active:bg-gray-200 transition-colors duration-300 rounded-full" onPointerDown={handleDrag}>
                <FaGripVertical className="w-full h-full p-1" />
            </button>

            {children}
        </Reorder.Item>
    )
}

export default DraggableItem