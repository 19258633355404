"use client"
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
// import Select from 'react-select';
// import { useSelector } from 'react-redux';
import CustomBootstrap from '@/styles/CustomBootstrap.module.css';
import CalenderSingleDayComponent from './CalenderSingleDayComponent';
import { delta_day_names } from './CalenderComponent';
import { devConsoleLog } from "@/client/utils";

const CalenderSingleWeekComponent = (props) => {
    const myRef = useRef(null);
    const [width_CalenderSingleWeekComponent, setWidth_CalenderSingleWeekComponent] = useState(0);
    // const [height_CalenderSingleWeekComponent, setHeight_CalenderSingleWeekComponent] = useState(0);

    const updateDimensions = () => {
        if (myRef.current) {
            setWidth_CalenderSingleWeekComponent((myRef.current.offsetWidth) / 7);
            // setHeight_CalenderSingleWeekComponent(myRef.current.offsetHeight);
            // devConsoleLog("myRef.current.offsetHeight");
            // devConsoleLog(myRef.current.offsetHeight);
            // devConsoleLog("myRef.current.offsetWidth");
            // devConsoleLog(myRef.current.offsetWidth);
        }
    };

    useLayoutEffect(() => {
        updateDimensions();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);


    const currentlyViewingDate = props?.currentlyViewingDate;
    const setCurrentlyViewingDate = props?.setCurrentlyViewingDate ?? (() => { });
    const change_currentlyViewingType = props?.change_currentlyViewingType ?? (() => { });
    const calenderEvents = props?.calenderEvents ?? [];
    const isShowing24hoursPerDay = props?.isShowing24hoursPerDay ?? true;
    const courseId = (props?.courseId) ?? null;
    const currentlyViewingType_Day = (props?.currentlyViewingType_Day) ?? null;
    const week_days_arr = props?.week_days_arr ?? [];
    const currentlyViewingDate_name = currentlyViewingDate.toLocaleDateString('en-EG', { weekday: 'short' });
    let did_NOT_reach_currentlyViewingDate_flag = true;
    const date_iterator_weekly = new Date(currentlyViewingDate);

    return (
        <div
            ref={myRef}
            className={`  ${CustomBootstrap["d-flex"] ?? ""}     ${CustomBootstrap["align-items-stretch"] ?? ""}   `}
            style={{
                overflowX: "auto",
            }}
        >
            {
                week_days_arr?.map((day, i) => {
                    if (did_NOT_reach_currentlyViewingDate_flag) {
                        if (currentlyViewingDate_name === day?.day_name) {
                            // reached it
                            did_NOT_reach_currentlyViewingDate_flag = false;
                            // this_day_class = "btn-outline-success";
                        } else if (!i) {
                            date_iterator_weekly?.setDate(currentlyViewingDate?.getDate() + delta_day_names(currentlyViewingDate_name, day?.day_name));
                        }
                    }
                    day.tmp_date_iterator_weekly = new Date(date_iterator_weekly);
                    // if (isCurrentDay(date_iterator_weekly)) {
                    //     this_day_class = "btn-primary";
                    // }
                    date_iterator_weekly?.setDate(date_iterator_weekly?.getDate() + 1);

                    return (
                        <CalenderSingleDayComponent
                            key={day?.day_name}
                            currentlyViewingDate={day.tmp_date_iterator_weekly}
                            calenderEvents={calenderEvents}
                            courseId={courseId}
                            isShowing24hoursPerDay={isShowing24hoursPerDay}
                            header_fn={() => {
                                setCurrentlyViewingDate(x => new Date(day.tmp_date_iterator_weekly));
                                change_currentlyViewingType(currentlyViewingType_Day);
                            }}
                            width_CalenderSingleWeekComponent={width_CalenderSingleWeekComponent}
                        />
                    );
                })
            }
        </div>
    );
}

export default CalenderSingleWeekComponent